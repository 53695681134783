export const SET_FILTERS = "SET_FILTERS";
export const FETCH_LISTITEMS_FAIL = "FETCH_LISTITEMS_FAIL";
export const FETCH_LISTITEMS_START = "FETCH_LISTITEMS_START";
export const FETCH_LISTITEMS_SUCCESS = "FETCH_LISTITEMS_SUCCESS";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_SELECTEDFILTERS = "SET_SELECTEDFILTERS";
export const FILTER_FUNC = "FILTER_FUNC";
export const SET_PAGE_TYPE = "SET_PAGE_TYPE";
export const SET_SEARCHTERM = "SET_SEARCHTERM";

export const DECREMENT_QUANTITY = "DECREMENT_QUANTITY";
export const INCREMENT_QUANTITY = "INCREMENT_QUANTITY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE";

export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";
export const SET_STUDENT = "SET_STUDENT";

export const SET_TOKENS = "SET_TOKENS";
export const REMOVE_TOKENS = "REMOVE_TOKENS";

export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const TOGGLE_MODE = "TOGGLE_MODE";
export const CLEAR_CART = "CLEAR_CART";

export const SET_PROFILE = "SET_PROFILE";

export const SET_BRANCHES = "SET_BRANCHES";
export const SET_AGENCY = "SET_AGENCY";
export const SET_BRANCHES_STRING = "SET_BRANCHES_STRING";
export const CLEAR_BRANCHES = "CLEAR_BRANCHES";
