import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function BarFirst({ barType, response, setResponse }) {
  const [barDataRemaining, setBarDataRemaining] = React.useState(null);
  const [barDataReached, setBarDataReached] = React.useState(null);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  React.useEffect(() => {
    if (response && barType) {
      if (barType === "default") {
        setBarDataRemaining(response?.target_badges[0]?.bookings_target);
        setBarDataReached(response?.target_badges[0]?.partner_bookings);
      } else if (barType === "amount") {
        setBarDataRemaining(response?.target_badges[0]?.booking_amounts_target);
        setBarDataReached(response?.target_badges[0]?.partner_booking_amounts);
      } else if (barType === "weeks") {
        setBarDataRemaining(response?.target_badges[0]?.booking_weeks_target);
        setBarDataReached(response?.target_badges[0]?.partner_booking_weeks);
      }
    }
  }, [response, barType]);

  React.useEffect(() => {
    console.log(barDataRemaining, "remaining");
    console.log(barDataReached, "reached");
  }, [barDataRemaining, barDataReached]);

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  return response && barType ? (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #ededed",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: lightMode ? "#bf0000" : "#ffffff",
          }}
        >
          {barType === "amount"
            ? `Bookings Revenue Target`
            : barType === "default"
            ? `Bookings Target`
            : barType === "weeks"
            ? `Bookings Week Target`
            : null}
        </Typography>

        {((barDataReached / barDataRemaining) * 100)?.toFixed(2) >= 100 ? (
          <CheckCircleIcon
            sx={{
              color: "#007e00ab",
              marginRight: ".25rem",
              marginLeft: "auto",
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {barType === "amount" ? (
            <>
              <Typography>{paraFormat(barDataReached)}</Typography>
              <Typography>{paraFormat(barDataRemaining)}</Typography>
            </>
          ) : (
            <>
              <Typography>{barDataReached?.toFixed(2)}</Typography>
              <Typography>{barDataRemaining?.toFixed(2)}</Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "16px",
            backgroundColor: "#efefef",
            position: "relative",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              position: "absolute",
              borderTopRightRadius: "1rem",
              borderBottomRightRadius: "1rem",
              top: "0",
              left: "0",
              width:
                ((barDataReached / barDataRemaining) * 100)?.toFixed(2) > 100
                  ? "100%"
                  : `${((barDataReached / barDataRemaining) * 100)?.toFixed(
                      2
                    )}%`,
              height: "16px",
              backgroundColor: "#800000",
            }}
          >
            {((barDataReached / barDataRemaining) * 100)?.toFixed(2) > 5 ? (
              <Typography
                variant="caption"
                sx={{
                  color: "#ffffff",
                  fontWeight: "700",
                  marginRight: ".25rem",
                }}
              >
                {((barDataReached / barDataRemaining) * 100)?.toFixed(2) > 100
                  ? `100`
                  : ((barDataReached / barDataRemaining) * 100)?.toFixed(2)}
                %
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  ) : // <PieChart
  //   series={[
  //     {
  //       data: [
  //         {
  //           id: 0,
  //           value: barDataRemaining,
  //           label: "Remaining",
  //           color: "#efefef", // Remaining diliminin rengi
  //         },
  //         {
  //           id: 1,
  //           value: barDataReached,
  //           label: "Reached",
  //           color: "#800000", // Reached diliminin rengi
  //         },
  //       ],
  //     },
  //   ]}
  //   margin={{
  //     top: 40,
  //     left: 100,

  //     // top: isMobile ? 150 : 20,
  //     // bottom: 20,
  //     // left: 0,
  //   }}
  //   slotProps={{
  //     legend: {
  //       direction: "row",
  //       position: {
  //         vertical: "top",
  //         horizontal: "middle",
  //       },
  //       padding: 0,
  //     },
  //   }}

  //   width={400}
  //   height={240}
  // />
  null;
}
