import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SelectStudent({
  students,
  handleStudent,
  paySnack,
  selectedStudent,
}) {
  const studentsWithLabel = students.map((student) => ({
    ...student,
    label: student.student_name, // her öğrenci için label özelliğini student_name ile eşitliyoruz
  }));


  return (
    <Autocomplete
      disabled={!students.length || paySnack}
      disablePortal
      value={Object.keys(selectedStudent).length ? selectedStudent : null}
      onChange={handleStudent}
      id="combo-box-demo"
      options={studentsWithLabel}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label="Select Student" />}
    />
  );
}
