
import { HIDE_DIALOG, SHOW_DIALOG } from "./actionTypes";

export const showDialog = (message, severity, timer, pageType) => ({
  type: SHOW_DIALOG,
  payload: { message, severity, open: true, timer, pageType },
});

export const hideDialog = () => ({
  type: HIDE_DIALOG,
});
