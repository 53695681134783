import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  createTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgencyArray,
  setBranchesArray,
  setSelectedBranchesString,
} from "../../../redux/actions/branchActions";
import { filterFunc } from "../../../redux/actions/actions";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import TuneIcon from "@mui/icons-material/Tune";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function Branches({ open }) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const dispatch = useDispatch();

  const branchsTheme = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            "&.MuiMenu-paper": {
              minWidth: "unset !important",
              width: "auto !important",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#ffffff",
          },
          root: {
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff63 !important",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            zIndex: "9999999999999",
          },
          paper: {
            borderRadius: "1rem",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#ffffff",
            "&.Mui-focused": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#ffffff",
          },

          root: {
            borderRadius: "1rem",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff", // Burada istediğiniz renge ayarlayabilirsiniz
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff",
            },
            "legend span": {
              //   paddingRight: "41px",
            },
          },
          input: {
            color: "#ffffff",
          },
        },
      },
    },
  });

  const profile = useSelector((state) => state.profile.profile);

  console.log(profile, "prof 61");
  const branchesArray = useSelector((state) => state?.branches?.branches);

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const pageType = useSelector((state) => state.filters.pageType);
  const searchedTerm = useSelector((state) => state.searchTerm.searchTerm); // Arama terimini al

  const [branches, setBranches] = useState(branchesArray || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (branchesArray?.length) {
      setBranches(branchesArray);
    }
  }, [branchesArray]);
  const getBranches = async () => {
    setLoading(true);
    // let url = `${process.env.REACT_APP_BASE_URL}auth/branches`;
    let url2 = "new-multi-account-development.up.railway.app/auth/branches";

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}auth/branches`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );

      // setBranches(response.data.agency[0].branches);
      // if (response.data.agency[0].branches?.length) {
      //   dispatch(setBranchesArray(response.data.agency[0].branches));
      // } else {
      //   dispatch(setBranchesArray(response.data.agency[0].agent_id));
      // }
      // dispatch(setAgencyArray(response.data.agency));
      console.log(profile, "profile here");

      console.log(response.data, "branches here 616161");
      setBranches(response.data.results);
      if (response.data.results?.length) {
        dispatch(setBranchesArray(response.data.results));
        dispatch(setAgencyArray(response.data.results));
      } else {
        dispatch(setBranchesArray(profile?.agent_id));
        dispatch(setAgencyArray(profile?.agent_id));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (profile) {
      getBranches();
    }
  }, [profile]);

  // useEffect(() => {
  //   console.log(branches, "branches here 61");
  // }, [branches]);

  //   const handleBranchChange = (branch) => {
  //     const newSelectedBranches = selectedBranches?.includes(branch)
  //       ? selectedBranches?.filter((b) => b !== branch)
  //       : [...selectedBranches, branch];
  //     dispatch(setSelectedBranches(newSelectedBranches));
  //     console.log(branch, "here branch");

  //     // const newSelectedBranches = [...selectedBranches, branch];
  //     // console.log(newSelectedBranches, "newSelectedBranches");
  //     // dispatch(setSelectedBranches(newSelectedBranches));
  //   };

  const [branchIds, setBranchIds] = useState(selectedBranchesString || []);
  const selectedFilters = useSelector((state) => state.filters.selectedFilters); // Arama terimini al

  console.log(selectedFilters, "selected filers here 61");
  useEffect(() => {
    console.log(branchIds, "branch IDS");
    let newBranchIds = [];
    if (profile) {
      if (!branchIds?.includes(profile?.agent_id)) {
        // newBranchIds = [...branchIds, profile?.agent_id];
        newBranchIds = [...branchIds];
        // if (profile?.account_type === "main_branch") {
        //   newBranchIds = [...branchIds];
        // } else {
        //   newBranchIds = [profile?.agent_id];
        // }
      } else {
        newBranchIds = branchIds;
      }
    }
    // Bu useEffect yalnızca seçili branch'ları günceller
    console.log("burası deneme");
    dispatch(setSelectedBranchesString(newBranchIds));
  }, [branchIds, dispatch, profile]);

  useEffect(() => {
    console.log(selectedBranchesString, "selectedBranchesString");
  }, [selectedBranchesString]);
  useEffect(() => {
    if (Object.keys(selectedFilters).length) {
      dispatch(
        filterFunc(
          selectedFilters,
          pageType,
          searchedTerm,
          selectedBranchesString
        )
      );
    }
  }, [selectedBranchesString, selectedFilters, pageType, searchedTerm]);

  // useEffect(() => {
  //   console.log(selectedBranchesString, "selectedBranchesString 616161");
  // }, [selectedBranchesString]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    //     const newSelectedBranches = selectedBranches?.includes(branch)
    //     ? selectedBranches?.filter((b) => b !== branch)
    //     : [...selectedBranches, branch];
    //   dispatch(setSelectedBranches(newSelectedBranches));
    setBranchIds(typeof value === "string" ? value.split(",") : value);
  };
  return profile?.account_type === "main_branch" ? (
    <ThemeProvider theme={branchsTheme}>
      <Box
        sx={{
          padding: "1rem",
          borderBottom: "1px solid #ffffff",
          position: "relative",
        }}
      >
        {!open ? (
          <Box
            sx={{
              position: "absolute",
              top: "calc(50% - 16px)",
              left: "calc(50% - 16px)",
            }}
          >
            <TuneIcon sx={{ fontSize: "2rem" }} />
          </Box>
        ) : null}

        {branches?.length ? (
          branches?.length > 1 ? (
            <FormControl
              fullWidth
              sx={{
                opacity: open ? "1" : "0",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Branches
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={
                  selectedBranchesString?.length === 1 &&
                  selectedBranchesString[0] === profile?.agent_id
                    ? []
                    : selectedBranchesString
                }
                onChange={handleChange}
                input={<OutlinedInput label="Branches" />}
                renderValue={(selected) =>
                  branches
                    .filter((branch) => selected.includes(branch.agent_id))
                    .map((branch) => branch.agent_name)
                    .join(", ")
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      width: 250,
                    },
                  },
                }}
              >
                {branches?.map((branch) => (
                  <MenuItem key={branch.agent_id} value={branch.agent_id}>
                    <Checkbox
                      checked={selectedBranchesString.includes(branch.agent_id)}
                    />
                    <ListItemText primary={branch.agent_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : // <FormControl
          //   fullWidth
          //   sx={{
          //     opacity: open ? "1" : "0",
          //   }}
          // >
          //   <InputLabel id="demo-multiple-checkbox-label">
          //     Branches
          //   </InputLabel>
          //   <Select
          //     labelId="demo-multiple-checkbox-label"
          //     id="demo-multiple-checkbox"
          //     multiple
          //     value={profile ? [branches[0].agent_id] : []}
          //     onChange={handleChange}
          //     input={
          //       <OutlinedInput
          //         label={profile ? branches[0].agent_name : "Branchess"}
          //       />
          //     }
          //     renderValue={() => branches[0]?.agent_name}
          //     MenuProps={{
          //       PaperProps: {
          //         style: {
          //           maxHeight: 250,
          //           width: 250,
          //         },
          //       },
          //     }}
          //   >
          //     <MenuItem key={branches[0]?.agent_id} value={branches[0].agent_id}>
          //       <Checkbox disabled checked={true} />
          //       <ListItemText primary={branches[0]?.agent_name} />
          //     </MenuItem>
          //   </Select>
          // </FormControl>
          branches[0]?.agent_id === profile?.agent_id ? (
            <FormControl
              fullWidth
              sx={{
                opacity: open ? "1" : "0",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Branches
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={profile ? [profile?.agent_id] : []}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    label={profile ? profile?.agent_name : "Branches"}
                  />
                }
                renderValue={() => profile?.agent_name}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      width: 250,
                    },
                  },
                }}
              >
                <MenuItem key={profile?.agent_id} value={profile.agent_id}>
                  <Checkbox disabled checked={true} />
                  <ListItemText primary={profile?.agent_name} />
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl
              fullWidth
              sx={{
                opacity: open ? "1" : "0",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Branches
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={
                  selectedBranchesString?.length === 1 &&
                  selectedBranchesString[0] === profile?.agent_id
                    ? []
                    : selectedBranchesString
                }
                onChange={handleChange}
                input={<OutlinedInput label={"Branches"} />}
                renderValue={(selected) =>
                  branches
                    .filter((branch) => selected.includes(branch.agent_id))
                    .map((branch) => branch.agent_name)
                    .join(", ")
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      width: 250,
                    },
                  },
                }}
              >
                {branches?.map((branch) => (
                  <MenuItem key={branch.agent_id} value={branch.agent_id}>
                    <Checkbox
                      checked={selectedBranchesString.includes(branch.agent_id)}
                    />
                    <ListItemText primary={branch.agent_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : null}

        {/* <FormGroup
          sx={{
            maxHeight: "200px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            overflow: "auto",
          }}
        >
          {branches?.map((branch) => (
            <FormControlLabel
              key={branch.agent_id}
              control={
                <Checkbox
                  checked={selectedBranches?.includes(branch)}
                  onChange={() => handleBranchChange(branch)}
                />
              }
              label={branch?.agent_name}
            />
          ))}
        </FormGroup> */}
      </Box>
    </ThemeProvider>
  ) : null;
}
