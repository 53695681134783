// src/contexts/RoomFinderContext.js

import React, { createContext, useContext, useState } from "react";

const RoomFinderContext = createContext();

export const RoomFinderProvider = ({ children }) => {
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [loaderStart, setLoaderStart] = useState(false);
  const [availableRooms, setAvailableRooms] = React.useState(null);
  const [allRooms, setAllRooms] = React.useState([]);

  return (
    <RoomFinderContext.Provider
      value={{
        selectedRooms,
        setSelectedRooms,
        bookingData,
        setBookingData,
        loaderStart,
        setLoaderStart,
        availableRooms,
        setAvailableRooms,
        allRooms,
        setAllRooms,
      }}
    >
      {children}
    </RoomFinderContext.Provider>
  );
};

export const useRoomFinder = () => useContext(RoomFinderContext);
