import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./thumbsSwiper.css";

import { useState } from "react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

export default function ItemComponentThumbs({ dublicated, items, renderItem }) {
  let displayedItems = items;

  if (dublicated && dublicated[0]) {

    const halfLength = Math.ceil(items.length / 2);
    if (dublicated[1] === "first") {
      displayedItems = items.slice(0, halfLength);
    } else if (dublicated[1] === "second") {
      displayedItems = items.slice(halfLength);
    }
  }


  const isMobile = window.innerWidth <= 768; // Eğer ekran genişliği 768px'den küçükse, mobil olarak kabul ediyoruz.

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
          left: "0",
          padding: "0",
        }}
        loop={true}
        spaceBetween={isMobile ? 60 : 32}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumbsSwiper2"
      >
        {displayedItems.map((itemData, idx) => (
          <SwiperSlide
            style={{ height: "auto", maxHeight: { xs: "200px", md: "400px" } }}
            key={idx}
          >
            {renderItem(itemData)}
          </SwiperSlide>
        ))}
      </Swiper>
      {items.length > 1 ? (
        <Swiper
          style={{ left: "0" }}
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="thumbsSwiper"
        >
          {displayedItems.map((itemData, idx) => (
            <SwiperSlide style={{ height: "auto" }} key={idx}>
              {renderItem(itemData)}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </>
  );
}
