import { Box, Breadcrumbs, Button, Container, Grid, Paper, Toolbar, Typography } from "@mui/material";
import BreadCrumbs from "../components/Breadcrumbs";
import Link from '@mui/material/Link';


export default function Customers() {
    let routs = ["Dashboard 1", "Dasboard 2", "Customers"]
    return (
        <>
            <Button variant="contained" color="primary">
                Primary Buttonnn
            </Button>
            <Button variant="contained" color="secondary">
                Secondary Button
            </Button>
            <BreadCrumbs routs={routs} />
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                }}
            >
                <Box>MY DOCS</Box>
            </Paper>
        </>
    )
}