import { Box, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AirportServicesContext from "../../../../../contexts/AirportServices/AirportServices";

export default function SideBar() {
  const { bookingDetails, airports, selectedAirport, residences } = useContext(
    AirportServicesContext
  );

  const [serviceDetails, setServiceDetails] = useState(null);

  useEffect(() => {
    const foundAirport = airports.find(
      (airport) => airport?.service_id === bookingDetails?.service_id
    );
    const foundResidence = residences.find(
      (residence) => residence?.id === bookingDetails?.residence_id
    );
    setServiceDetails({
      service_name: foundAirport.service_name,
      residence_name: foundResidence.name,
    });
  }, [airports, residences, bookingDetails]);

  // useEffect(() => {
  //   console.log(serviceDetails, "service details");
  // }, [serviceDetails]);
  return (
    <Box
      sx={{
        position: "sticky",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
        borderRadius: "1rem",
        padding: "1.5rem",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #efefef",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h6" color={"primary"}>
          From
        </Typography>
        <Typography>
          {bookingDetails?.travel_type === "Arrival"
            ? serviceDetails?.service_name
            : serviceDetails?.residence_name}
        </Typography>
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #efefef",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h6" color={"primary"}>
          To
        </Typography>
        <Typography>
          {bookingDetails?.travel_type === "Arrival"
            ? serviceDetails?.residence_name
            : serviceDetails?.service_name}
        </Typography>{" "}
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #efefef",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h6" color={"primary"}>
          Person
        </Typography>
        <Typography>{bookingDetails?.people_count}</Typography>
      </Box>

      <Box>
        <Typography variant="h6" color={"primary"}>
          Travel Type
        </Typography>
        <Typography>{bookingDetails?.travel_type}</Typography>
      </Box>
    </Box>
  );
}
