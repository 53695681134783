import { CircularProgress, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QuoteDetilsComp from "../../components/Quotes/QuoteDetails/QuoteDetailsComp";
import { useSelector } from "react-redux";

export default function QuoteDetails() {
  const location = useLocation();
  const [students, setStudents] = useState();
  const [loader, setLoader] = useState(true);
  const [quoteDetails, setQuoteDetails] = useState();
  const { quote } = location.state || {};
  const { quoteId } = useParams(); // URL'den requestId parametresini al
  const navigate = useNavigate();

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const getQuoteDetails = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoader(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}quotes/${
        quote?.quote_id || quoteId
      }`;
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
          "Content-Type": "application/json",
        },
      });

      setQuoteDetails(response.data);
      console.log("güncellendi!");
      // State'i güncelle
      setLoader(false);
    } catch (error) {
      setLoader(false);
      navigate("/quotes");
      console.error("Error fetching data:", error);
    }
  };

  const getStudents = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/students`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            Accounts: selectedBranchesString,
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      setStudents(response?.data?.student);
    } catch (error) {
      console.error("Hata oluştu!", error);
      return [];
    }
  };

  useEffect(() => {
    getStudents();
  }, [quoteDetails]);

  useEffect(() => {
    console.log(students);
    // if (students?.length) {
    //   getQuoteDetails();
    // }
    if (!students?.length) {
      getQuoteDetails();
    }
  }, [students]);

  return (
    <>
      <Typography className="title" variant="h4">
        Quote Details
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Quotes", `Quote Details`]} />
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : (
        <QuoteDetilsComp
          quoteDetails={quoteDetails}
          loader={loader}
          getQuoteDetails={getQuoteDetails}
          students={students}
          setStudents={setStudents}
        />
      )}
    </>
  );
}
