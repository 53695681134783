import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../axiosInstance";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export const LineChartComp = () => {
  const [salesData, setSalesData] = useState(null);
  const [chartDataType, setChartDataType] = useState("daily"); // "daily" veya "monthly" değerlerini alabilir
  const [chartData, setChartData] = useState({ xAxisData: [], seriesData: [] });
  const [loading, setLoading] = useState(true);

  const getSalesData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/sales_chart`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setSalesData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalesData();
  }, []);

  useEffect(() => {
    if (salesData) {
      const processedData = processSalesData(salesData, chartDataType);
      setChartData(processedData);
    }
  }, [salesData, chartDataType]); // salesData veya chartDataType değiştiğinde bu useEffect tetiklenir

  const processSalesData = (data, type) => {
    let xAxisData, seriesData;

    if (type === "daily") {
      xAxisData = data.daily_sales.map((item) => item.day);
      seriesData = data.daily_sales.map((item) => item.total_sales);
    } else {
      xAxisData = data.monthly_sales.map((item) => item.month);
      seriesData = data.monthly_sales.map((item) => item.total_sales);
    }

    return { xAxisData, seriesData };
  };

  const handleChange = (event) => {
    const type = event.target.value;
    setChartDataType(type);
  };

  return (
    <Box
      sx={{
        minHeight: "300px",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl sx={{ minWidth: "125px", marginLeft: "auto" }}>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={chartDataType}
          label="Type"
          onChange={handleChange}
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
        </Select>
      </FormControl>

      {!loading ? (
        <Box
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
            overflow: "auto",
            display: "flex",
          }}
        >
          <LineChart
            xAxis={[{ data: chartData.xAxisData }]}
            series={[
              {
                data: chartData.seriesData,
                color: "#bf0000", // İsteğe bağlı olarak renk değiştirilebilir
              },
            ]}
            width={500}
            height={300}
          />
        </Box>
      ) : (
        <CircularProgress sx={{ margin: "auto" }} />
      )}
    </Box>
  );
};
