// selectors.js

export const selectTotalPrice = (state) => {
  return state.cart.products?.cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
};

export const selectTotalPriceServices = (state) => {
  return state.cart.services?.cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
};
