export const countryIdentificationControl = [
  "AR",
  "BO",
  "BR",
  "CL",
  "CN",
  "CO",
  "EC",
  "EG",
  "IN",
  "ID",
  "MX",
  "MA",
  "NG",
  "PY",
  "PE",
  "ZA",
  "TR",
  "US",
  "UY",
];
