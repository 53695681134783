import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";
import { useSelector } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import PersonIcon from "@mui/icons-material/Person";
import { Person } from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, EffectCube, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/effect-cards";
import "./style.css";
import Cube from "./Cube";
import Card from "./Card";
import EffectCreative from "./EffectCreative";
import EffectCreativeCards from "./EffectCreative";
import CoverflowCards from "./CoverflowCards";

function RecentActivityItem({ type, idx, data }) {
  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };
  return (
    <Box
      key={idx}
      sx={{
        padding: "1rem",
        boxSizing: "border-box",
        borderRadius: "2rem",
        display: "flex",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          //   position: "absolute",
          //   left: "-10px",
          //   top: "-10px",
        }}
      >
        {type === "recent_invoices" && (
          <ReceiptIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
        )}
        {type === "recent_quotes" && (
          <ReceiptLongIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
        )}

        {type === "recent_payments_overdue" && (
          <Box sx={{ position: "relative" }}>
            <NotificationsActiveIcon
              sx={{
                fontSize: "24px",
                position: "absolute",
                background: "#ffffff",
                color: "#36193e",
                padding: "3px",
                borderRadius: "2rem",
                top: "-6px",
                right: "-6px",
              }}
            />
            <PaymentIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
          </Box>
        )}

        {type === "recent_invoices_overdue" && (
          <Box sx={{ position: "relative" }}>
            <NotificationsActiveIcon
              sx={{
                fontSize: "24px",
                position: "absolute",
                background: "#ffffff",
                color: "#9e4c20",
                padding: "3px",
                borderRadius: "2rem",
                top: "-6px",
                right: "-6px",
              }}
            />
            <ReceiptIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
          </Box>
        )}
      </Box>
      <Box sx={{ paddingLeft: "1rem", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: ".25rem",
            borderBottom: "1px solid #dbc6c6",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#ffffff", fontWeight: "700" }}>
              {type === "recent_invoices_overdue" && `Overdue Invoice`}
              {type === "recent_payments_overdue" && `Overdue Payment`}
              {type === "recent_quotes" && `Quote`}
              {type === "recent_invoices" && `Invoice`}
            </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "700" }}>
                {data?.student_name}
              </Typography>
            </Box> */}
          {type === "recent_invoices" && (
            <Typography variant="caption">{data?.created_at_date}</Typography>
          )}
          {type === "recent_quotes" && (
            <Typography variant="caption">{data?.created_at}</Typography>
          )}
          {type === "recent_payments_overdue" && (
            <Typography variant="caption">{data?.due_date}</Typography>
          )}
          {type === "recent_invoices_overdue" && (
            <Typography variant="caption">{data?.due_date}</Typography>
          )}
        </Box>
        <Box
          sx={{
            marginTop: ".5rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PersonIcon sx={{ color: "#ffffff" }} />
              {data?.student_name}
            </Typography>
            {type === "recent_invoices" && (
              <Typography>{paraFormat(data?.price)}</Typography>
            )}
            {type === "recent_payments_overdue" && (
              <Typography>{paraFormat(data?.outstanding_amount)}</Typography>
            )}
            {type === "recent_invoices_overdue" && (
              <Typography>{paraFormat(data?.outstanding)}</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: ".5rem",
              marginTop: ".5rem",
              borderTop: "1px solid #dbc6c6",
            }}
          >
            {type === "recent_invoices" && (
              <Typography variant="caption">
                Invoide ID: {data?.invoice_id}
              </Typography>
            )}
            {type === "recent_quotes" && (
              <Typography variant="caption">
                Quote ID: {data?.quote_id}
              </Typography>
            )}
            {type === "recent_invoices_overdue" && (
              <Typography variant="caption">
                Invoice ID: {data?.invoice_id}
              </Typography>
            )}
            {type === "recent_payments_overdue" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="caption">
                  Payment ID: {data?.payment_id}
                </Typography>
                <Typography variant="caption">
                  Invoice ID: {data?.invoice_id}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const RecentActivities = () => {
  const [loading, setLoading] = useState(true);
  const [recentActivities, setRecentActivities] = useState(null);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const getRecentActivities = async (id) => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}dashboard/recent_activities?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
        },
      });

      setRecentActivities(response.data);
      setLoading(false);
      console.log(response.data, "recent activities");
    } catch (error) {
      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };

  useEffect(() => {
    getRecentActivities();
  }, [startDate, endDate, selectedBranchesString]);

  const [age, setAge] = React.useState("cube");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return !loading ? (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{ width: "100%", textAlign: "start", marginBottom: "1.5rem" }}
          variant="h6"
          color="primary"
        >
          Recent Activities
        </Typography>

        {/* <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"cube"}>Cube</MenuItem>
              <MenuItem value={"card"}>Card</MenuItem>
              <MenuItem value={"effect-creative"}>Effect Creative</MenuItem>
              <MenuItem value={"coverflow"}>Coverflow</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        <Cube recentActivities={recentActivities} />
        {/* {age === "cube" && <Cube recentActivities={recentActivities} />}
        {age === "card" && <Card recentActivities={recentActivities} />}
        {age === "coverflow" && <CoverflowCards recentActivities={recentActivities} />}
        {age === "effect-creative" && (
          <EffectCreativeCards recentActivities={recentActivities} />
        )} */}
      </Box>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default RecentActivities;
