export const Validation = (
  formValues,
  checkInError,
  setCheckInError,
  checkOutError,
  setCheckOutError
) => {
  let isError = false;

  if (!formValues.checkIn) {
    setCheckInError(true);
    isError = true;
  } else {
    setCheckInError(false);
  }

  if (!formValues.checkOut) {
    setCheckOutError(true);
    isError = true;
  } else {
    setCheckOutError(false);
  }

  if (isError) {
    return; // Eğer hata varsa, fonksiyonu burada durdur.
  }
};
