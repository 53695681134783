import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import MenuIcon from "@mui/icons-material/Menu";

import ButtonsDesktop from "../../../components/RoomFinder/RequestOperations/ButtonsDesktop";
import { useFetcher } from "react-router-dom";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const SettingsHandle = styled(MenuIcon)(({ theme }) => ({
  color: "#ffffff",
  position: "absolute",
  backgroundColor: "#b51110",
  borderRadius: "100%",
  padding: ".5rem",
  fontSize: "48px",
  top: "calc(50% - 36px)",
  lineHeight: "48px",
  left: "calc(50% - 24px)", // Ikonun boyutuna bağlı olarak bu değeri ayarlamalısınız
}));

function ButtonsMobile(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleRef = React.useRef();

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [settingsHandlerHeight, setSettingsHandlerHeight] = React.useState();
  React.useEffect(() => {
    setSettingsHandlerHeight(
      toggleRef.current.clientHeight || toggleRef.current.offsetHeight
    );
  }, [props.selectedRooms]);
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `auto`,
            overflow: "visible",
          },
        }}
      />

      <Box
        ref={toggleRef}
        sx={{
          position: "fixed",
          bottom: open ? `-${settingsHandlerHeight}px` : "0px",
          height: "auto",
          width: "100vw",
          left: "0",
          transition: "bottom .4s",
          boxShadow: "0 -55px 5px #000000",
        }}
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <SettingsHandle onClick={handleOpen} />
          <Typography sx={{ color: "transparent", p: 2 }}>``</Typography>
        </StyledBox>

        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box height="100%">
            <ButtonsDesktop
              mobileCon={true}
              setSelectedRooms={props.setSelectedRooms}
              selectedRooms={props.selectedRooms}
              bookingData={props.bookingData}
              setBookingData={props.setBookingData}
              setLoaderStart={props.setLoaderStart}
            />
          </Box>
        </StyledBox>
      </Box>
    </Root>
  );
}

ButtonsMobile.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ButtonsMobile;
