import { Box, Typography } from "@mui/material";
import SliderDeals from "./SliderDeals";

export default function BestDeals() {
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <SliderDeals />
    </Box>
  );
}
