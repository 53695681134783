import { Box } from "@mui/material";
import SelectArea from "./SelectArea/SelectArea";
import AirPortTransferImg from "../../../../../assets/images/additional-services/airport_1.jpg";

export default function StepFirst({handleNext}) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${AirPortTransferImg})`,
        height: "70vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          marginTop: "auto",
          maxWidth: { xs: "calc(100% - 32px)", md: "90%" },
          width: "100%",
          height: "auto",
          padding: "1rem",
          background: "#80808080",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: { xs: "16px", md: "5%" },
          borderRadius: ".5rem",
          color: "#ffffff",
        }}
      >
        <SelectArea />
      </Box>
    </Box>
  );
}
