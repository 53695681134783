import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PaymentSuccessModal from "../../../../../Modal/PaymentSuccessModal/PaymentSuccessModal";
import { openModal } from "../../../../../../redux/actions/actions";
import { getTheme } from "../../../../../../styles/theme";
import PhoneCountryInput from "../../../../../PhoneCountryInput";
import AirportServicesContext from "../../../../../../contexts/AirportServices/AirportServices";
import axiosInstance from "../../../../../../axiosInstance";
import PersonIcon from "@mui/icons-material/Person";
import ErrorModal from "../../../../../Modal/ErrorModal/ErrorModal";

export default function FormArea() {
  const { bookingDetails, setBookingDetails, airports, residences } =
    useContext(AirportServicesContext);

  const initialState = {
    name: bookingDetails?.name || "",
    surname: bookingDetails?.surname || "",
    flight_number: bookingDetails?.flight_number || "",
    email: bookingDetails?.email || "",
    phone: bookingDetails?.phone || "",
    transportation_date_time:
      bookingDetails.transportation_date_time ||
      dayjs().format("YYYY-MM-DD HH:mm"),
    billing_information: bookingDetails?.billing_information || "",
    car_id: bookingDetails?.car_id,
    people_count: bookingDetails?.people_count,
    residence_id: bookingDetails?.residence_id,
    travel_type: bookingDetails?.travel_type,
    service_id: bookingDetails?.service_id,
  };

  const [formValues, setFormValues] = useState(initialState);
  const [students, setStudents] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const { startRequest: rawStartRequest } = location.state || {};
  const startRequest = Array.isArray(rawStartRequest)
    ? rawStartRequest
    : [rawStartRequest].filter(Boolean);

  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email is not valid";
    }

    if (!formValues?.phoneIsValid) {
      errors.phone = "Phone is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // const lettersRegex = /^[A-Za-z]+$/;
    const lettersRegex = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/;


    if (name === "name" || name === "surname") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (formValues.phone && formValues.email) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  useEffect(() => {
    // formValues her değiştiğinde bookingDetails'i güncelle
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      ...formValues,
    }));
  }, [formValues, setBookingDetails]);

  const handleDateChange = (name, value) => {
    // Kullanıcıya gösterim için yerel zaman dilimine çevirin
    let newDateLocal = value.format("YYYY-MM-DD HH:mm");

    setFormValues((prev) => ({
      ...prev,
      [name]: newDateLocal,
    }));
  };

  const handleSubmit = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}payment/service_checkout`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    const appendForm = {
      student_id: selectedStudent?.student_id,
      services: [bookingDetails],
    };

    setLoader(true);
    try {
      // Axios ile POST isteği yapılıyor
      const response = await axiosInstance.post(url, appendForm, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      const foundAirport = airports.find(
        (airport) => airport?.service_id === bookingDetails?.service_id
      );
      const foundResidence = residences.find(
        (residence) => residence?.id === bookingDetails?.residence_id
      );

      navigate(`/payment/airport-transfer/${response?.data?.invoice_id}`, {
        state: {
          postPaymentAirportTransfer: {
            student_id: selectedStudent?.student_id,
            payment: response?.data,
            details: {
              ...bookingDetails,
              service_name: foundAirport.service_name,
              residence_name: foundResidence.name,
            },
          },
        },
      });
      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setLoader(false);
    } catch (error) {
      // Hata durumunda hata mesajını göster
      setLoader(false);
      // dispatch(
      //   openModal(<ErrorModal data={error.response.data} />, "selectStudent")
      // );
      console.error("Error during the request:", error.message);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(bookingDetails).length) {
      navigate("/additional-services");
    }
  }, [startRequest]);

  const getStudents = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/students`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      setStudents(response?.data?.student);
    } catch (error) {
      console.error("Hata oluştu!", error);
      return [];
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  const handleChangeStudent = (_event, newValue) => {
    setSelectedStudent(newValue);
  };

  useEffect(() => {
    if (selectedStudent) {
      const parts = selectedStudent?.student_name?.split(" ");
      // Dizinin ilk elemanı adı (name), ikinci elemanı soyadı (surname) temsil eder
      const name = parts[0];
      const surname = parts.slice(1).join(" "); // Eğer soyadı birden fazla kelime içeriyorsa bunları birleştir

      setFormValues((prev) => ({
        ...prev,
        name: name,
        surname: surname,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        name: "",
        surname: "",
      }));
    }
  }, [selectedStudent]);
  if (!Object.keys(bookingDetails).length) {
    return null;
  }
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          position: "relative",
          width: { xs: "100%", md: "70vw", lg: "54vw" },
          padding: "0 !important",
        }}
      >
        <Box
          sx={{
            opacity: loader ? ".4" : "1",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "27px",
              fontWeight: "700",
              marginTop: "3rem",
              textAlign: "center",
            }}
            color={getTheme().palette.secondary.main}
          >
            Reserved Passenger’s Details
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  value={selectedStudent}
                  onChange={handleChangeStudent}
                  disablePortal
                  id="combo-box-demo"
                  options={students || []}
                  disabled={!students?.length}
                  getOptionLabel={(option) => option.student_name} // 'name' özelliğini label olarak kullan
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Student"
                      placeholder="Select Student"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                disabled={!selectedStudent}
                value={formValues.name}
                onChange={handleChange}
                name="name"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.flight_number}
                onChange={handleChange}
                name="flight_number"
                id="outlined-basic"
                label="Flight Number"
                variant="outlined"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                disabled={!selectedStudent}
                value={formValues.surname}
                onChange={handleChange}
                name="surname"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                type="text"
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PhoneCountryInput
                formValues={formValues}
                setFormValues={setFormValues}
                error={!!formErrors.phone}
                helperText={formErrors.phone || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.email}
                onChange={handleChange}
                name="email"
                id="outlined-basic"
                label="E-Mail"
                variant="outlined"
                type="email"
                required
                error={!!formErrors.email}
                helperText={formErrors.email || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DatePicker
                  sx={{
                    width: "100%",
                  }}
                  label={"Date"}
                  value={dayjs(formValues.transportation_date_time)}
                  onChange={(newValue) =>
                    handleDateChange("transportation_date_time", newValue)
                  }
                  minDate={dayjs() || null}
                /> */}
                <DateTimePicker
                  sx={{
                    width: "100%",
                  }}
                  label={"Date"}
                  value={dayjs(formValues.transportation_date_time)}
                  onChange={(newValue) =>
                    handleDateChange("transportation_date_time", newValue)
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.billing_information}
                onChange={handleChange}
                name="billing_information"
                id="billing_information"
                label="Billing Information"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={
                  !formValues?.phone ||
                  !formValues?.email ||
                  formErrors?.phone ||
                  formErrors?.email ||
                  !formValues?.name ||
                  !formValues?.surname ||
                  !formValues?.flight_number ||
                  !formValues?.billing_information ||
                  !selectedStudent
                }
                onClick={() =>
                  formValues.phone && formValues.email && handleSubmit()
                }
                variant="contained"
                color="primary"
                sx={{
                  height: "100%",
                  display: "block",
                  minHeight: "56px",
                  width: "100%",
                }}
              >
                Pay Now
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loader ? (
          <CircularProgress
            sx={{ position: "absolute", top: "calc(50%)", left: "calc(50%)" }}
          />
        ) : null}
      </Container>
    </>
  );
}
