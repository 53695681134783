export const TableHeaders = [
  {
    commissions: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      {
        id: "price",
        label: "Amount",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "commission_amount",
        label: "Commission Amount",
      },
      {
        id: "commission_type",
        label: "Type",
      },
      {
        id: "issue_date",
        label: "Issue Date",
      },
      // {
      //   id: "sm_accommodation_commission_rate",
      //   label: "Commission Rate",
      // },
      {
        id: "due_date",
        label: "Last Payment Date",
      },
      // {
      //   id: "invoice_dual_occupancy",
      //   label: "Occupancy",
      // },
      {
        id: "status",
        label: "Invoice Status",
      },
      {
        id: "commission_paid_status",
        label: "Commission Status",
      },
      {
        id: "agent_name",
        label: "Created By",
      },
    ],
  },
  {
    bookings: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      // {
      //   id: "invoice_course_id",
      //   label: "Course ID",
      // },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "residence_name",
        label: "Residence Name",
      },
      {
        id: "created_at",
        label: "Created At",
      },
      {
        id: "home_country",
        label: "Home Country",
      },
      // {
      //   id: "preferred_language",
      //   label: "Preferred Language",
      // },
      {
        id: "invoice_status",
        label: "Invoice Status",
      },

      {
        id: "duration",
        label: "Duration / Weekly",
      },
      {
        id: "check_in",
        label: "Check In",
      },
      {
        id: "check_out",
        label: "Check Out",
      },
      {
        id: "invoice_course_price",
        label: "Amount",
      },
      {
        id: "overdue",
        label: "Overdue",
      },
      {
        id: "agent_name",
        label: "Created By",
      },
    ],
  },
  {
    invoices: [
      {
        id: "invoice_id",
        label: "Invoice Number",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "invoice_type",
        label: "Type",
      },
      {
        id: "issue_date",
        label: "Issue Date",
      },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "amount",
        label: "Total Amount",
      },
      {
        id: "paid",
        label: "Total Paid",
      },
      {
        id: "outstanding",
        label: "Total Out Standing",
      },
      {
        id: "agent_name",
        label: "Created By",
      },
    ],
  },
  {
    partner_requests: [
      {
        id: "student_id",
        label: "Student ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "student_phone",
        label: "Student Phone",
      },
      {
        id: "student_school",
        label: "Student School",
      },
      {
        id: "created_at",
        label: "Date Added",
      },
      {
        id: "agent_name",
        label: "Created By",
      },
    ],
  },
  {
    quotes: [
      {
        id: "quote_id",
        label: "Quote ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "quote_status",
        label: "Status",
      },
      {
        id: "accepted_at",
        label: "Accepted At",
      },
      {
        id: "issue_date",
        label: "Issue Date",
      },
      {
        id: "agent_name",
        label: "Created By",
      },
    ],
  },
];

export const TableHeadersSm = [
  {
    commissions: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
  },
  {
    partner_requests: [
      {
        id: "student_id",
        label: "Student ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
    ],
  },
  {
    bookings: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
    ],
  },
  {
    invoices: [
      {
        id: "invoice_id",
        label: "Invoice Number",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
  },
  {
    quotes: [
      {
        id: "quote_id",
        label: "Quote ID",
      },

      {
        id: "quote_status",
        label: "Status",
      },
    ],
  },
];

export const TableHeadersMd = [
  {
    commissions: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
  },
  {
    bookings: [
      {
        id: "invoice_id",
        label: "Invoice ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "check_in",
        label: "Check In",
      },
      {
        id: "check_out",
        label: "Check Out",
      },
      {
        id: "invoice_course_price",
        label: "Amount",
      },
    ],
  },
  {
    partner_requests: [
      {
        id: "student_id",
        label: "Student ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "student_phone",
        label: "Student Name",
      },
    ],
  },
  {
    invoices: [
      {
        id: "invoice_id",
        label: "Invoice Number",
      },

      {
        id: "amount",
        label: "Total Amount",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
  },
  {
    quotes: [
      {
        id: "quote_id",
        label: "Quote ID",
      },
      {
        id: "student_name",
        label: "Student Name",
      },
      {
        id: "quote_status",
        label: "Status",
      },
    ],
  },
];
