import { Grid, TextField } from "@mui/material";
import PhoneCountryInput from "../../components/PhoneCountryInput";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function ProfileItems({ formValues, setFormValues, type }) {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.username}
          id="outlined-basic"
          label="User E-Mail"
          name="username"
          variant="outlined"
        />
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <PhoneCountryInput
          formValues={formValues}
          setFormValues={setFormValues}
          disabled={type !== "modal" && true}
        />
      </Grid> */}

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.tax_address}
          id="outlined-basic"
          label="Tax Address"
          name="tax_address"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.tax_office}
          id="outlined-basic"
          label="Tax Office"
          name="tax_office"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.agent_name}
          id="outlined-basic"
          label="Agent Name"
          name="agent_name"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.address}
          id="outlined-basic"
          label="Address"
          name="address"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.tax_name}
          id="outlined-basic"
          label="Tax Name"
          name="tax_name"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={type !== "modal" && 6}
        lg={type !== "modal" && 4}
        xl={type !== "modal" && 3}
      >
        <TextField
          disabled={type !== "modal"}
          fullWidth
          value={formValues?.tax_number}
          id="outlined-basic"
          label="Tax Number"
          name="tax_number"
          variant="outlined"
        />
      </Grid>

      {type !== "modal" && (
        <Grid
          item
          xs={12}
          sm={type !== "modal" && 6}
          lg={type !== "modal" && 4}
          xl={type !== "modal" && 3}
        >
          <TextField
            disabled={type !== "modal"}
            fullWidth
            value={formValues?.id}
            id="outlined-basic"
            label="ID"
            name="id"
            variant="outlined"
          />
        </Grid>
      )}
    </>
  );
}
