// Bildirim gösterme aksiyonu
export const showNotification = (message) => ({
    type: 'SHOW_NOTIFICATION',
    payload: message,
  });
  
  // Bildirim gizleme aksiyonu
  export const hideNotification = () => ({
    type: 'HIDE_NOTIFICATION',
  });
  