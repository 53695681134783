import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { redirect, useNavigate } from "react-router-dom";
import { error, ErrorContext } from "../contexts/handleError";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import loginImage from "../assets/images/login.jpg";
import AuthForm from "../components/Authform";
import { setTokens } from "../redux/actions/tokenActions";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../axiosInstance";
import { getTheme } from "../styles/theme";
import { setProfile } from "../redux/actions/cartActions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright(props) {
  return (
    <Typography variant="h2" color="text.secondary" align="center" {...props}>
      @All Right Received to{" "}
      <Link sx={{ fontWeight: "700" }} color="inherit" href="#">
        Londonist DMC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignIn({ error }) {
  const [agentId, setAgentId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(true);
  const [handleError, setHandleError] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [handleErrorText, setHandleErrorText] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);

  // React.useEffect(() => {
  //   // Eğer accessToken ve refreshToken varsa, kullanıcı zaten giriş yapmış demektir
  //   if (token.accessToken && token.refreshToken) {
  //     navigate("/"); // Kullanıcıyı dashboard'a yönlendir
  //   }
  // }, [token, navigate]);

  const errorHandler = React.useContext(ErrorContext);

  // const loader = async () => {
  //   const user = await getUser();
  //   if (!user) {
  //     return redirect("/login");
  //   }
  //   return null;
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getProfile = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      // GET isteği yapılacak URL
      let url;
      url = `${process.env.REACT_APP_BASE_URL}auth/profile`;

      // Axios ile GET isteği yapılıyor
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token
          XRefreshToken: `${token}`, // XRefreshToken
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      // Yanıtın içeriğini konsola yazdır
      dispatch(setProfile(response.data.profile));
      localStorage.setItem("profile", JSON.stringify(response.data.profile));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    // });

    await axiosInstance
      // .post("/auth/login", {
      .post(`${process.env.REACT_APP_BASE_URL}auth/login`, {
        username: agentId,
        password: password,
      })
      .then(function (response) {
        dispatch(setTokens(response.data.access, response.data.refresh));
        localStorage.setItem("accessToken", response.data.access); // localStorage'a accessToken kaydet
        localStorage.setItem("loginUser", response.data.refresh); // localStorage'a refreshToken kaydet
        getProfile();
        setLoader(false);
        navigate("/");
      })
      .catch(function (error) {
        setLoader(false);
        setHandleError(true);
        setHandleErrorText(error.response.data.message);
        console.log(error, "error here");
        // setHandleError(true);
        // setHandleErrorText(error.response.data.message);
        setTimeout(() => {
          setHandleError(false);
          setHandleErrorText("");
        }, 5000);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const re = /^[0-9@#$&*!-^]*$/; // Bu regex sadece sayılar ve belirtilen özel karakterleri kabul eder.
    if (value === "" || re.test(value)) {
      setAgentId(value);
    }
  };

  return (
    <AuthForm
      title="Log in to your account"
      onSubmit={handleSubmit} // Bu satırı ekledik
      imageSrc={loginImage}
      handleError={handleError}
      handleErrorText={handleErrorText}
    >
      {/* Form içeriğiniz burada olacak, örneğin: */}
      <TextField
        sx={{
          marginBottom: "1.5rem",

          // ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
          //   borderColor: { xs: "#ffffff", md: getTheme().palette.customRed.main },
          // },
          ".css-oxhcol-MuiFormLabel-root-MuiInputLabel-root": {
            color: { xs: "#ffffff", md: getTheme().palette.customRed.main },
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            color: { xs: "#ffffff", md: "#000000" },
          },
        }}
        margin="normal"
        required
        fullWidth
        id="agentId"
        label="Agent ID"
        name="agentId"
        autoComplete="agentId"
        autoFocus
        onChange={handleInputChange}
        value={agentId} // Kontrol edilen değer bu şekilde ayarlanır.
      />
      <TextField
        sx={{
          margin: "0",
          // ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
          //   borderColor: {
          //     xs: "#ffffff",
          //     md: getTheme().palette.customRed.main,
          //   },
          // },
          ".css-oxhcol-MuiFormLabel-root-MuiInputLabel-root": {
            color: { xs: "#ffffff", md: getTheme().palette.customRed.main },
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            color: { xs: "#ffffff", md: "#000000" },
          },
        }}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={!showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          mt: 3,
          marginBottom: "1rem",
          color: "#ffffff !important",
          backgroundColor: "#bf0000 !important",
        }}
      >
        LOG IN
      </Button>

      {handleError && (
        <Typography
          color={"error"}
          sx={{
            position: "absolute",
            bottom: "-36px",
            width: "100%",
            height: "40px",
          }}
        >
          {handleErrorText}
        </Typography>
      )}

      {/* <Grid container>
        <Grid item xs>
          <Link
            sx={{
              color: { xs: "#ffffff !important", md: "#bf0000 !important" },
            }}
            href="/reset-password"
            variant="body2"
          >
            Forgot password?
          </Link>
        </Grid>
        // <Grid item>
        //   <Link href="/register" variant="body2">
        //     {"Don't have an account? Sign Up"}
        //   </Link>
        // </Grid>
      </Grid> */}

      {loader ? (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "#ffffff8f",
          }}
        >
          <CircularProgress
            sx={{
              position: "absolute",
              left: "calc(50% - 20px)",
              top: "calc(50% - 20px)",
            }}
          />
        </Box>
      ) : null}
      {/* Diğer inputlarınız ve butonlarınız... */}
    </AuthForm>
  );
}
