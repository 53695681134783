// actions.js
import axios from "axios";
import {
  FILTER_FUNC,
  SET_FILTERS,
  SET_PAGE_TYPE,
  SET_SEARCHTERM,
  SET_SELECTEDFILTERS,
  SET_STUDENT,
  SET_WINDOW_WIDTH,
} from "./actionTypes";
import {
  FETCH_LISTITEMS_FAIL,
  FETCH_LISTITEMS_START,
  FETCH_LISTITEMS_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
} from "./actionTypes";
import axiosInstance from "../../axiosInstance";

export const filterFunc =
  (selectedFilters, pageType, searchTerm, selectedBranchesString) =>
  async (dispatch) => {
    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }

    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    dispatch({ type: FETCH_LISTITEMS_START });

    if (pageType !== "room_finder") {
      try {
        // GET isteği yapılacak URL
        const baseUrl =
          pageType === "invoices"
            ? `${process.env.REACT_APP_BASE_URL}${pageType}`
            : `${process.env.REACT_APP_BASE_URL}${pageType}`;
        let queryParams = "";

        const convertValue = (value) => {
          if (typeof value === "boolean") {
            return value ? "1" : "0";
          } else if (
            value &&
            typeof value === "object" &&
            "$d" in value &&
            "$y" in value
          ) {
            // Moment.js benzeri bir tarih nesnesi kontrolü
            const date = new Date(value.$d);
            if (!isNaN(date.getTime())) {
              let day = date.getDate().toString().padStart(2, "0");
              let month = (date.getMonth() + 1).toString().padStart(2, "0");
              let year = date.getFullYear();
              return `${year}-${month}-${day}`;
            }
          }
          return value;
        };

        if (Object.keys(selectedFilters).length > 0) {
          queryParams = Object.entries(selectedFilters)
            .filter(([key, _]) => key !== "range_types") // range_type anahtarını ve değerini çıkar
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  convertValue(value)
                )}`
            )
            .join("&");
        }

        const url =
          baseUrl +
          (queryParams
            ? searchTerm.searchTerm
              ? "?" +
                queryParams +
                `&${searchTerm.searchTermType}=` +
                searchTerm.searchTerm
              : "?" + queryParams
            : searchTerm.searchTerm
            ? "?" + `${searchTerm.searchTermType}=` + searchTerm.searchTerm
            : "");

        let accessToken = localStorage.getItem("accessToken");
        let token = localStorage.getItem("loginUser");

        const response = await axiosInstance.get(url, {
          cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            Accounts: selectedBranchesString,
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        });
        // Axios ile GET isteği yapılıyor

        //const response = await axios.get(url, {});
        // Yanıtın içeriğini konsola yazdır

        if (response.data[pageType]) {
          dispatch({ type: FETCH_LISTITEMS_SUCCESS });
          dispatch({
            type: FILTER_FUNC,
            payload: response.data[pageType],
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled:", error.message);
        } else {
          console.error("Error fetching data:", error);
          dispatch({ type: FETCH_LISTITEMS_FAIL });
          dispatch({
            type: FILTER_FUNC,
            payload: [],
          });
          console.error("Error fetching data:", error);
        }
        // Hata durumunda hata mesajını konsola yazdır
      }
    }
  };

//// OLD VERSION
// export const fetchListItems =
//   (listType, token, accessToken, selectedBranchesString) =>
//   async (dispatch) => {
//     dispatch({ type: FETCH_LISTITEMS_START });

//     console.log('loading true');
//     try {
//       // GET isteği yapılacak URL
//       const url =
//         listType === "invoices"
//           ? `${process.env.REACT_APP_BASE_URL}${listType}`
//           : `${process.env.REACT_APP_BASE_URL}${listType}`;
//       // Axios ile GET isteği yapılıyor

//       const response = await axiosInstance.get(url, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
//           XRefreshToken: `${token}`, // Bearer token burada ekleniyor
//           Accounts: selectedBranchesString,
//           "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
//         },
//       });
//       // Yanıtın içeriğini konsola yazdır

//       dispatch({
//         type: FETCH_LISTITEMS_SUCCESS,
//         payload: response.data[listType],
//       });
//     console.log('loading false');

//     } catch (error) {
//       // Hata durumunda hata mesajını konsola yazdır
//       console.error("Error fetching data:", error);
//       dispatch({ type: FETCH_LISTITEMS_FAIL });
//     }
//   };

//NEW VERSION
export const fetchListItems =
  (listType, token, accessToken, selectedBranchesString) =>
  async (dispatch) => {
    // Önceki istekleri iptal etmek için kullanılacak Cancel Token
    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }

    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    dispatch({ type: FETCH_LISTITEMS_START });

    console.log("loading true");
    try {
      const url = `${process.env.REACT_APP_BASE_URL}${listType}`;

      const response = await axios.get(url, {
        cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${token}`,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json",
        },
      });

      dispatch({
        type: FETCH_LISTITEMS_SUCCESS,
        payload: response.data[listType],
      });
      console.log("loading false");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        dispatch({ type: FETCH_LISTITEMS_FAIL });
      }
    }
  };

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const setSearchedTerm = (searhTerm) => ({
  type: SET_SEARCHTERM,
  payload: searhTerm,
});

export const setFilterItems = (selectedFilters) => ({
  type: SET_SELECTEDFILTERS,
  payload: selectedFilters,
});

export const openModal = (content, contentType) => ({
  type: OPEN_MODAL,
  payload: { content, contentType },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const setPageType = (pageType) => {
  return {
    type: SET_PAGE_TYPE,
    payload: pageType,
  };
};

// actions.js
export const setWindowWidth = (width) => ({
  type: SET_WINDOW_WIDTH,
  payload: width,
});

// Bu fonksiyon pencere boyutu değiştiğinde dispatch edilecek
export const updateWindowWidth = () => (dispatch) => {
  const windowWidth = window.innerWidth;
  dispatch(setWindowWidth(windowWidth));
};

export const setStudent = (student) => {
  return {
    type: SET_STUDENT,
    payload: student,
  };
};

export const toggleMode = () => ({
  type: "TOGGLE_MODE",
});
