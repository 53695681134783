import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import ProfileItems from "../../../pages/Profile/ProfileItems";
import { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import dayjs from "dayjs";
import { closeModal } from "../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../redux/actions/cartActions";
import Swal from "sweetalert2";

export default function UpdateProfileModal({
  formValues,
  setFormValues,
  getProfile,
}) {
  const [formValuesModal, setFormValuesModal] = useState();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const { id, username, ...rest } = formValues;

    // `formValuesModal` state'ini güncelleyin
    setFormValuesModal(rest);
  }, [formValues]); // formValues içindeki değerlerin değişimini izlemek için

  // useEffect(() => {
  //   console.log(formValuesModal, "form values modal changed 123");
  // }, [formValuesModal]); // formValues içindeki değerlerin değişimini izlemek için

  const handleUpdateProfile = async () => {
    setLoader(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/profile`,
        formValuesModal,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      setLoader(false);
      getProfile();
      localStorage.setItem("profile", JSON.stringify(response?.data?.profile));
      dispatch(setProfile(response?.data?.profile));
      Swal.fire({
        icon: "success",
        title: "Profile updated successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(closeModal());
    } catch (error) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        title: "Profile could not be updated!",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(error);
    }
  };

  // const handleChange = ({ target: { name, value } }) => {
  //   // Diğer input alanlarının değerlerini güncelle (tarihler dahil)
  //   const updatedValue =
  //     name === "checkIn" || name === "checkOut"
  //       ? dayjs(value).format("YYYY-MM-DD")
  //       : value;

  //   setFormValuesModal((prev) => ({
  //     ...prev,
  //     [name]: updatedValue,
  //   }));
  // };

  const handleChange = ({ target: { name, value, type } }) => {
    let isText = ["tax_office", "agent_name", "tax_name"].includes(name);
    let updatedValue = value;

    // For text fields, ensure that the input does not contain numbers
    if (type === "text" && isText) {
      // This regex checks for the presence of any digit
      if (/\d/.test(value)) return; // If there's a digit, don't update the value
    } else if (name === "checkIn" || name === "checkOut") {
      // Date fields require special formatting
      updatedValue = dayjs(value).format("YYYY-MM-DD");
    }

    setFormValuesModal((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };
  return (
    <>
      <Grid container spacing={3} sx={{ opacity: loader ? ".4" : "1" }}>
        {/* <Grid item xs={12}>
        <TextField
          onChange={handleChange}
          fullWidth
          value={formValuesModal?.username}
          id="outlined-basic"
          label="User E-Mail"
          name="username"
          variant="outlined"
        />
      </Grid> */}
        {/* <Grid
        item
        xs={12}
    
      >
        <PhoneCountryInput
          formValues={formValues}
          setFormValues={setFormValues}
          disabled={type !== "modal" && true}
        />
      </Grid> */}

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.tax_address}
            id="outlined-basic"
            label="Tax Address"
            name="tax_address"
            variant="outlined"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.tax_office}
            id="outlined-basic"
            label="Tax Office"
            name="tax_office"
            variant="outlined"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.agent_name}
            id="outlined-basic"
            label="Agent Name"
            name="agent_name"
            variant="outlined"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.address}
            id="outlined-basic"
            label="Address"
            name="address"
            variant="outlined"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.tax_name}
            id="outlined-basic"
            label="Tax Name"
            name="tax_name"
            variant="outlined"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            disabled={loader}
            fullWidth
            value={formValuesModal?.tax_number}
            id="outlined-basic"
            label="Tax Number"
            name="tax_number"
            variant="outlined"
            type="number"
          />
        </Grid>

        {/* <Grid item xs={12}>
        <TextField
          onChange={handleChange}
          fullWidth
          value={formValuesModal?.id}
          id="outlined-basic"
          label="ID"
          name="id"
          variant="outlined"
        />
      </Grid> */}

        <Grid item xs={12}>
          <Button fullWidth onClick={handleUpdateProfile} variant="contained">
            Update
          </Button>
        </Grid>
        {loader ? (
          <CircularProgress
            sx={{
              position: "absolute",
              left: "calc(50% - 20px)",
              top: "calc(50% - 20px)",
            }}
          />
        ) : null}
      </Grid>
    </>
  );
}
