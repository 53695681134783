import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import axiosInstance from "../../../axiosInstance";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { light } from "@mui/material/styles/createPalette";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";

// API'den gelen veriyi simüle eden bir örnek, gerçek uygulamada bu veriler bir API isteği ile alınır.
// const apiData = {
//   daily_sales: [
//     { day: 1, total_sales: 100 },
//     { day: 2, total_sales: 120 },
//     { day: 3, total_sales: 90 },
//     // Ve böyle devam eder...
//   ],
//   monthly_sales: [
//     { month: "January", total_sales: 3000 },
//     { month: "February", total_sales: 2200 },
//     // Ve böyle devam eder...
//   ],
// };

export default function ApexChart() {
  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const [chartDataType, setChartDataType] = useState("daily");
  const [salesData, setSalesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);
  const theme = useTheme();
  const isMobileSm = useMediaQuery(theme.breakpoints.down("md"));
  const getSalesData = async () => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/sales_chart?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            Accounts: selectedBranchesString,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setSalesData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (selectedBranchesString?.length) {
    //   getSalesData();
    // }
    getSalesData();
  }, [startDate, endDate, selectedBranchesString]);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      colors: "#bf0000",
      chart: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        height: 350,
        type: "bar",
        zoom: {
          enabled: true,
          type: "x",
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          selection: {
            background: "#90CAF9",
            border: "#0D47A1",
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: isMobileSm ? true : false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return paraFormat(val);
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return paraFormat(val);
          },
        },
      },
      title: {
        text: "Daily Sales",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        align: "center",
      },
    },
  });

  useEffect(() => {
    // API'den veri çekme işlemi burada simüle ediliyor.
    // Gerçekte, axios gibi bir kütüphane kullanarak bir API'den veri çekebilirsiniz.
    if (salesData) {
      updateChartData(salesData);
    }
  }, [salesData]);

  useEffect(() => {
    updateChartData(salesData);
  }, [chartDataType]);

  const updateChartData = (salesData) => {
    let labels = [];
    let data = [];

    if (chartDataType === "daily") {
      labels = salesData?.daily_sales?.map((sale) => sale.day.toString());
      data = salesData?.daily_sales?.map(
        (sale) => Number(sale.total_sales),
        "£"
      );
    } else if (chartDataType === "monthly") {
      labels = salesData?.monthly_sales?.map(
        (sale) => sale.month.substring(0, 3) + "/" + sale.year
      );
      data = salesData?.monthly_sales?.map(
        (sale) => Number(sale.total_sales),
        "£"
      );
    } else if (chartDataType === "yearly") {
      labels = salesData?.yearly_sales?.map((sale) => sale.year.toString());
      data = salesData?.yearly_sales?.map(
        (sale) => Number(sale.total_sales),
        "£"
      );
    }

    if (data && labels) {
      setChartData({
        ...chartData,
        series: [
          {
            name:
              chartDataType === "daily"
                ? "Daily Sales"
                : chartDataType === "monthly"
                ? "Monthly Sales"
                : "Yearly Sales",
            data: data,
          },
        ],
        options: {
          ...chartData.options,
          xaxis: {
            ...chartData.options.xaxis,
            categories: labels,
          },
          title: {
            text:
              chartDataType === "daily"
                ? "Daily Sales"
                : chartDataType === "monthly"
                ? "Monthly Sales"
                : "Yearly Sales",
            align: "center",
          },
        },
      });
    }
  };

  const handleChange = (event) => {
    setChartDataType(event.target.value);
  };

  useEffect(() => {
    // Mobil cihazlar ve lightMode için chart konfigürasyonunu güncelle
    setChartData((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: isMobileSm ? true : false,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },

        chart: {
          ...prev.options.chart,
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
        },
        xaxis: {
          ...prev.options.xaxis,
          labels: {
            style: {
              colors: lightMode ? "#444444" : "#ffffff", // xaxis etiket renkleri
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: lightMode ? "#444444" : "#ffffff", // yaxis etiket renkleri
            },
          },
        },
        dataLabels: {
          enabled: isMobileSm ? false : true,

          offsetY: isMobileSm ? 0 : -20,
          formatter: function (val) {
            return paraFormat(val);
          },
          style: {
            colors: [lightMode ? "#444444" : "#ffffff"], // Veri etiketleri rengi
          },
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return '£' + val + "%";
        //   },
        //   offsetY: lightMode ? -20 : -30,
        //   style: {
        //     fontSize: '72px',
        //     colors: ["#304758"]
        //   }
        // },
        legend: {
          ...prev.options.legend,
          labels: {
            colors: lightMode ? "#444444" : "#ffffff", // Efsane etiket renkleri
          },
        },
        title: {
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            color: lightMode ? "#444444" : "#ffffff",
          },
        },
      },
    }));
  }, [lightMode, isMobileSm]);

  return !loading ? (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{ width: "100%", textAlign: "start", marginBottom: "1.5rem" }}
          variant="h6"
          color="primary"
        >
          Sales
        </Typography>
        <FormControl sx={{ minWidth: "125px", marginLeft: "auto" }}>
          <InputLabel id="chart-type-select-label">Type</InputLabel>
          <Select
            labelId="chart-type-select-label"
            id="chart-type-select"
            value={chartDataType}
            label="Type"
            onChange={handleChange}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box id="chart" sx={{ mt: 3, overflow: isMobileSm ? "auto" : "unset" }}>
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="bar"
          height={isMobileSm ? (chartDataType === "daily" ? 650 : 450) : 350}
          width={isMobileSm ? "700px" : "100%"}
        />
      </Box>
    </Box>
  ) : (
    <CircularProgress />
  );
}
