import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Pagination,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import OptimizedAccordion from "./OptimizedAccordion";
import NoDataFound from "../NoDataFound";

export default function PaginationAccordion({
  tableHeader,
  allTableHeader,
  isMobileSm,
  isMobileMd,
}) {
  const loading = useSelector((state) => state.listItems?.loading); // Redux store'dan loading durumunu al
  const filteredDatas = useSelector((state) => state.filters.filteredListItems); // Filtrelenmiş ürünleri al
  const [expanded, setExpanded] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // URL'den invoiceID'yi çek
    const queryParams = new URLSearchParams(location.search);
    const invoiceID = queryParams.get("invoice_id");
    const status = queryParams.get("status");

    if (invoiceID && status === "success") {
      const foundIndex = filteredDatas.findIndex(
        (data) => data.invoice_id.toString() === invoiceID
      );
      if (foundIndex !== -1) {
        // Bulunan faturanın index'ine göre sayfa numarasını ve genişletilmiş accordion'u ayarla
        const page = Math.floor(foundIndex / rowsPerPage);
        setPage(page);
        setExpanded(`${invoiceID}-${foundIndex % rowsPerPage}`);
        // Eğer ilgili sayfaya scroll yapılması gerekiyorsa (opsiyonel)
      }
    }
  }, [location.search, filteredDatas, navigate]);

  const [open, setOpen] = useState(false);
  const handleOpen = (data) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const handleChange = useCallback(
    (invoiceId) => {
      // Eğer tıklanan accordion zaten açıksa, kapat
      // Değilse, tıklanan accordion'un ID'sini açık olarak ayarla
      setExpanded(expanded === invoiceId ? null : invoiceId);
    },
    [expanded]
  );

  // COE düğmesine tıklandığında çalışacak fonksiyon
  const handleDownloadPDF = (invoiceId) => {
    // Dinamik URL oluşturma
    const apiUrl = `${process.env.REACT_APP_BASE_URL}invoices/${invoiceId}?invoice_type=gross`;

    // API isteğini gönderme
    axios
      .get(apiUrl)
      .then((response) => {
        const pdfUrl = response.data?.result.file;
        // PDF dosyasını indirme
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("PDF indirilirken hata oluştu!", error);
      });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    // Filtreleme sonrası toplam sayfa sayısını hesapla
    const totalPageCount = Math.ceil(filteredDatas?.length / rowsPerPage);

    // Eğer mevcut sayfa numarası hesaplanan toplam sayfa sayısından büyükse,
    // veya filtrelenmiş veri sayısı sıfırsa (yani hiç veri kalmamışsa),
    // sayfa numarasını otomatik olarak en uygun değere ayarla.
    if (page >= totalPageCount || filteredDatas?.length === 0) {
      setPage(totalPageCount > 0 ? totalPageCount - 1 : 0); // Sayfalar 0'dan başladığı için -1 yapıyoruz.
    }
  }, [filteredDatas?.length, rowsPerPage, page]);

  const memoizedAccordionData = useMemo(() => filteredDatas, [filteredDatas]);

  return !loading ? (
    <>
      {memoizedAccordionData?.length && allTableHeader?.length ? (
        memoizedAccordionData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((data, idx) => {
            return (
              <OptimizedAccordion
                key={`${data.invoice_id}-${idx}`}
                data={data}
                idx={idx}
                tableHeader={tableHeader}
                isMobileSm={isMobileSm}
                isMobileMd={isMobileMd}
                allTableHeader={allTableHeader}
                expanded={expanded === `${data.invoice_id}-${idx}`} // Karşılaştırmayı burada yapıyoruz
                onChange={() => handleChange(`${data.invoice_id}-${idx}`)} // İlgili accordion için onChange handler'ını ayarlıyoruz
              />
            );
          })
      ) : (
        <NoDataFound />
      )}
      {memoizedAccordionData?.length && allTableHeader?.length ? (
        <Pagination
          count={Math.ceil(memoizedAccordionData?.length / rowsPerPage)}
          page={page + 1} // MUI'nin Pagination bileşeni 1 tabanlıdır, bu nedenle +1 eklenir.
          onChange={(event, newPage) => handleChangePage(event, newPage - 1)} // Sayfa değişikliğini işleyin, -1 eklemeyi unutmayın!
          variant="outlined"
          shape="rounded"
          sx={{ mt: 10, display: "flex", justifyContent: "center" }} // Üstten biraz boşluk eklemek için
        />
      ) : null}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        maxWidth: "fit-content",
        position: "relative",
        left: "calc(50% - 20px)",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
