// AirportServicesContext.js

import dayjs from "dayjs";
import React, { createContext, useState } from "react";

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, "year"));
    const [endDate, setEndDate] = useState(dayjs());
  // Context'e sağlanacak değerler
  const contextValue = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };

  return (
    <DateRangeContext.Provider value={contextValue}>
      {children}
    </DateRangeContext.Provider>
  );
};

export default DateRangeContext;
