import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import { useState } from "react";
import {getTheme} from "../../styles/theme";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import StyledTitleDot from "../../components/common/StyledTitleDot";

export default function MaintenanceRequest() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const initialState = {
    roomNumber: "",
    invoiceNo: "",
    clarifyRequest: "",
    reason: "",
    notes: "",
    evidence: "",
  };

  const [formValues, setFormValues] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <Typography className="title" variant="h4">
        Maintenance Request
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Maintenance Request"]} />
      <Container
        maxWidth={false}
        sx={{
          width: { xs: "100%", md: "70vw", lg: "65vw" },
          padding: "0 !important",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "27px",
            fontWeight: "700",
            marginTop: "3rem",
            textAlign: "center",
          }}
          color={getTheme().palette.secondary.main}
        >
          Place Your Request
        </Typography>
        <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
              }}
              value={formValues.roomNumber}
              onChange={handleChange}
              name="roomNumber"
              id="outlined-basic"
              label="Current Residence / Room Number"
              variant="outlined"
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
              }}
              value={formValues.invoiceNo}
              onChange={handleChange}
              name="invoiceNo"
              id="outlined-basic"
              label="Invoice No"
              variant="outlined"
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  value={formValues.clarifyRequest}
                  onChange={handleChange}
                  name="clarifyRequest"
                  id="outlined-basic"
                  label="Please Clarify Your Request"
                  variant="outlined"
                  type="text"
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="body1">
                    If Any Evidence/Proof Please Attach
                  </Typography>
                  <Button
                    sx={{
                      height: "100%",
                    }}
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                    <input type="file" hidden />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
                height: "100%",
              }}
              value={formValues.notes}
              onChange={handleChange}
              name="notes"
              id="outlined-basic"
              label="Notes"
              variant="outlined"
              type="text"
              multiline // This enables the textarea feature
              InputProps={{
                inputProps: {
                  style: {
                    height: "100%",
                    boxSizing: "border-box", // Padding ve border dahil olmak üzere yüksekliğin hesaplanmasını sağlar
                  },
                },
                style: {
                  // Bu, dış div'e stil verir
                  height: "100%",
                  overflow: "hidden", // Eğer içerik kutudan taşarsa gizler
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Consent For Maintenance Team Access Student’s Room"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "100%",
                display: "block",
                minHeight: "56px",
                width: "100%",
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
