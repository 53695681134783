import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';

//axios.defaults.withCredentials = true

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp({ loginControl }) {
    const navigate = useNavigate();
    const [verification, setVerification] = React.useState(localStorage.getItem("new-password") ? true : false);
    const [formTitle, setFormTitle] = React.useState("Verify your email");
    const [userName, setUserName] = React.useState("");

    const [handleError, setHandleError] = React.useState(false);
    const [handleErrorText, setHandleErrorText] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const sampleData = {
                username: userName,
                new_password: data.get('new-password'),
                otp: data.get('verification'),
            };

            const response = await axios.post('https://7cbe-213-155-98-201.ngrok-free.app/auth/reset_password/verify', sampleData, {
                withCredentials: true,
                credentials: true
            });

            localStorage.removeItem("new-password");
            navigate("/login")
            loginControl.setLoginControl(true);
            setResponseMessage(response.data.message);

        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    const [responseMessage, setResponseMessage] = React.useState();

    const handleVerification = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const sampleData = {
                username: data.get('email'),
            };

            const response = await axios.post('https://7cbe-213-155-98-201.ngrok-free.app/auth/reset_password', sampleData, {
                withCredentials: true,
                credentials: true
            });
            setVerification(true);
            setUserName(data.get('email'))
            // localStorage.removeItem("verification")
            // navigate("/login")
            // loginControl.setLoginControl(true);
            // setResponseMessage(response.data.message);
        } catch (error) {
            setHandleError(true)
            setHandleErrorText(error.response.data.message)
            setTimeout(() => {
                setHandleError(false)
                setHandleErrorText("")
            }, 3000);
            console.error('Error sending data:', error);
        }
    };

    // React.useEffect(() => {
    //     console.log(responseMessage, 'response message!');
    // }, [responseMessage])


    React.useEffect(() => {
        if (verification) {
            setFormTitle("Set New Password")
            localStorage.setItem("new-password", true)
        }
    }, [verification])

    return (
        <ThemeProvider theme={defaultTheme}>
            {handleError && (
                <Alert
                    sx={{
                        position: 'absolute', right: '0', top: 'calc(100vh - 150px)',
                        borderTopLeftRadius: '3rem',
                        borderBottomLeftRadius: '3rem'
                    }}
                    severity="error">{handleErrorText}</Alert>
            )}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {formTitle}
                    </Typography>
                    {!verification ? (
                        <Box component="form" noValidate onSubmit={handleVerification} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        type='email'
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Send verification mail
                            </Button>
                        </Box>
                    ) : (
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        type='text'
                                        id="verification"
                                        label="Verification Code"
                                        name="verification"
                                        autoComplete="verification"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        type='password'
                                        id="new-password"
                                        label="New Password"
                                        name="new-password"
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Change Password
                            </Button>
                        </Box>
                    )}
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider >
    );
}