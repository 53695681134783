import { HIDE_DIALOG, SHOW_DIALOG } from "../actions/actionTypes";

const initialState = {
  open: false,
  message: "",
  severity: "info",
  timer: null,
  pageType: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
        timer: action.payload.timer,
        pageType: action.payload.pageType,
      };
    case HIDE_DIALOG:
      return {
        ...state,
        open: false,
        message: "",
        severity: "info",
        timer: null,
        pageType: null,
      };
    default:
      return state;
  }
};

export default dialogReducer;
