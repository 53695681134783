// store.js
// import { applyMiddleware, createStore } from 'redux'
// import { thunk } from 'redux-thunk'
// import rootReducer from '../reducers/rootReducer'

// const store = createStore(rootReducer, applyMiddleware(thunk))

// export default store

import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk"; // Redux thunk import düzeltildi
import rootReducer from "../reducers/rootReducer";

// Başlangıç state'ini yüklerken "products-cart" ve "services-cart" için ayrı ayrı kontrol et
const initialState = {
  cart: {
    products: {
      cart: localStorage.getItem("products-cart")
        ? JSON.parse(localStorage.getItem("products-cart"))
        : [],
      totalPrice: 0,
    },
    services: {
      cart: localStorage.getItem("services-cart")
        ? JSON.parse(localStorage.getItem("services-cart"))
        : [],
      totalPrice: 0,
    },
  },
  branches: {
    branches: localStorage.getItem("branches")
      ? JSON.parse(localStorage.getItem("branches"))
      : [],
    branchesString: localStorage.getItem("branches-string")
      ? JSON.parse(localStorage.getItem("branches-string"))
      : "",
    agency: localStorage.getItem("agency")
      ? JSON.parse(localStorage.getItem("agency"))
      : [],
  },
};

export const store = createStore(
  rootReducer,
  initialState, // Başlangıç state'i olarak yukarıda tanımlanan initialState kullanılır
  applyMiddleware(thunk)
);

// Her state güncellemesinde, "products-cart" ve "services-cart" için localStorage'ı güncelle
store.subscribe(() => {
  const { products, services } = store.getState().cart;
  const { branchesString, branches, agency } = store.getState().branches;

  localStorage.setItem("products-cart", JSON.stringify(products.cart));
  localStorage.setItem("services-cart", JSON.stringify(services.cart));

  localStorage.setItem("branches", JSON.stringify(branches));
  localStorage.setItem("agency", JSON.stringify(agency));
  localStorage.setItem("branches-string", JSON.stringify(branchesString));
});

// // Cart'ı localStorage'dan yükleyin
// const persistedCart = localStorage.getItem("cart")
//   ? JSON.parse(localStorage.getItem("cart"))
//   : [];

// // Custom middleware oluşturun
// const localStorageMiddleware = (store) => (next) => (action) => {
//   const result = next(action); // Aksiyonu sonraki middleware'e veya reducera gönder
//   // Cart state'ini her değişiklikte localStorage'a kaydedin
//   localStorage.setItem("cart", JSON.stringify(store.getState().cart));
//   return result;
// };

// export const store = configureStore({
//   reducer: rootReducer,
//   preloadedState: { cart: { cart: persistedCart } }, // Başlangıç state'i olarak yüklenen cart'ı kullanın
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(thunk, localStorageMiddleware), // Middleware'leri ekleyin
// });

// export default store;
