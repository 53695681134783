import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { closeModal } from "../../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function AddBranchModal() {
  const [formValues, setFormValues] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const lettersRegex = /^[A-Za-z]+$/;

    if (name === "branch") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues?.agent_email)) {
      errors.agent_email = "Email is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };
  useEffect(() => {

    if (formValues?.agent_email) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <TextField
        sx={{
          width: "100%",
          marginBottom: "1rem",
        }}
        value={formValues?.agent_name || ""}
        onChange={handleChange}
        name="agent_name"
        id="outlined-basic"
        label="Branch Agent Name"
        variant="outlined"
        type="text"
        required
      />
      <TextField
        sx={{
          width: "100%",
          marginBottom: "1rem",
        }}
        value={formValues?.branch_name || ""}
        onChange={handleChange}
        name="branch_name"
        id="outlined-basic"
        label="Branch Name"
        variant="outlined"
        type="text"
        required
      />
      <TextField
        sx={{
          width: "100%",
          marginBottom: "1rem",
        }}
        value={formValues?.agent_email || ""}
        onChange={handleChange}
        name="agent_email"
        id="outlined-basic"
        label="Agent Email"
        variant="outlined"
        type="email"
        required
        error={!!formErrors.agent_email}
        helperText={formErrors.agent_email || ""}
      />
      <TextField
        sx={{
          width: "100%",
          marginBottom: "1rem",
        }}
        required
        fullWidth
        name="password"
        label="Password"
        type={!showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        sx={{ marginTop: "1rem", height: "56px" }}
        onClick={handleClose}
        disabled={
          !formValues?.agent_name ||
          !formValues?.branch_name ||
          (!formValues?.agent_email || formErrors.agent_email) ||
          !password
        }
        fullWidth
        variant="contained"
        color="primary"
      >
        Add Branch
      </Button>
    </Box>
  );
}
