import { Box, Button, Grid, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageSingle from "./ImageSingle";
import ReactPlayer from "react-player";

function ShowItems({ data, type }) {
  const [loading, setLoading] = useState(true);
  const [openImageSingle, setOpenImageSingle] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleOpenImageSingle = (data) => {
    setOpenImageSingle(true);
    setSelectedImage(data);
  };

  return (
    <>
      <ImageSingle
        openImageSingle={openImageSingle}
        setOpenImageSingle={setOpenImageSingle}
        // data={selectedImage}
        data={data}
        selectedImage={selectedImage} //new
        typeOfModal={type}
      />
      <Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
            {type}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {type === "images" &&
            data?.images?.length &&
            data?.images?.map((image, idx) => {
              return (
                <Grid key={idx} item xs={12} md={4}>
                  <Box
                    onClick={() => handleOpenImageSingle(image)}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "2rem",
                      overflow: "hidden",
                      // height: "calc(100vh)",
                      boxShadow: "0 0 3px #cecece",
                      transition: "all .4s",
                      height: "calc((100vh - 200px) / 3)",
                      minHeight: "300px !important",
                      "&:hover": {
                        img: {
                          transform: "scale(1.1)",
                          transition: "all .4s",
                          overflow: "hidden",
                        },
                      },
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        transition: "all .4s",
                      }}
                      src={image.image_file}
                      alt=""
                    />
                  </Box>
                </Grid>
              );
            })}
          {type === "videos" &&
            data?.campus_videos?.length &&
            data?.campus_videos?.map((video, idx) => {
              return (
                <Grid key={idx} item xs={12} md={4}>
                  <Box
                    onClick={() => handleOpenImageSingle(video)}
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      borderRadius: "2rem",
                      overflow: "hidden",
                      // height: "200px",
                      boxShadow: "0 0 3px #cecece",
                      transition: "all .4s",

                      height: "calc((100vh - 290px) / 3)",
                      minHeight: "260px !important",

                      "&:hover": {
                        img: {
                          transform: "scale(1.1)",
                          transition: "all .4s",
                          overflow: "hidden",
                        },
                      },
                    }}
                  >
                    {loading && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    )}
                    <Box
                      className="player-wrapper"
                      sx={{
                        position: "absolute",
                        zIndex: -1,
                        width: "100%",
                        top: "0",
                        left: "0",
                        height: "100%",
                        display: loading ? "none" : "block",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          opacity: 0.4,
                          background:
                            "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(128,0,0,1) 100%)",
                        },
                      }}
                    >
                      <ReactPlayer
                        className="react-player"
                        url={video}
                        width="100%"
                        height="100%"
                        controls={true}
                        onReady={() => setLoading(false)}
                      />
                    </Box>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </>
  );
}
const ImageGallery = ({
  data,
  openImageGallery,
  setOpenImageGallery,
  selectedGalleryType,
  setSelectedGalleryType,
}) => {
  const [style, setStyle] = useState(null);
  const [selected, setSelected] = useState(null);
  const [tabMenuItems, setTabMenuItems] = useState([
    { id: "images", name: "Images" },
    { id: "videos", name: "Videos" },
  ]);
  const initialStyle = {
    overflow: "auto !important",
    position: "absolute",
    outline: "none",
    border: "none",
    top: "150%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    height: "100vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    transition: "all .4s",
  };

  const handleCloseImageGallery = () => {
    setOpenImageGallery(false);
    setSelected(null);
    setSelectedGalleryType(null);
  };
  useEffect(() => {
    if (openImageGallery) {
      setSelected(selectedGalleryType);
      setStyle((prev) => ({
        ...prev,
        top: "50%",
      }));
    } else {
      setStyle(initialStyle);
    }
  }, [openImageGallery]);

  return (
    style && (
      <Modal
        open={openImageGallery}
        onClose={handleCloseImageGallery}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleCloseImageGallery}
            sx={{
              fontSize: "36px",
              filter: "drop-shadow(0 0 3px #800000)",
              color: "#ffffff",
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer",
              transition: "all .4s",
              "&:hover": {
                color: "#efefef",
                transition: "all .4s",
              },
            }}
          />
          <Box
            sx={{
              paddingBottom: ".5rem",
              marginBottom: "1rem",
              borderBottom: "1px solid #efefef",
            }}
          >
            <Typography variant="h6">Gallery</Typography>
          </Box>
          <Box sx={{ display: "flex", marginBottom: "2rem" }}>
            {tabMenuItems?.map((tabMenu, idx) => {
              return (
                <Button
                  disabled={
                    (!data?.images?.length && tabMenu?.id === "images") ||
                    (!data?.campus_videos?.length && tabMenu?.id === "videos")
                    // tabMenu?.id === "videos"
                  }
                  onClick={() => setSelected(tabMenu.id)}
                  sx={{
                    background:
                      tabMenu?.id === selected ? "#800000" : "#efefef",
                    color: tabMenu?.id === selected ? "#ffffff" : "#800000",
                    boxShadow: "0 0 3px #cecece",
                    cursor: "pointer",
                    borderRadius: "2rem",
                    marginRight: idx + 1 !== tabMenuItems?.length,
                    padding: ".5rem 1rem",
                    transition: "all .2s",

                    "&:hover": {
                      background:
                        tabMenu?.id === selected ? "#5e0101" : "#800000",
                      color: "#ffffff",
                      transition: "all .2s",
                    },
                  }}
                  key={idx}
                >
                  <Typography>{tabMenu?.name}</Typography>
                </Button>
              );
            })}
          </Box>
          {selected === "images" && <ShowItems data={data} type="images" />}
          {selected === "videos" && <ShowItems data={data} type="videos" />}
        </Box>
      </Modal>
    )
  );
};

export default ImageGallery;
