import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import ServiceItems from "../../Shop/ServiceItems";
import { setPageType } from "../../../redux/actions/actions";
import ComingSoonModal from "../../Modal/ComingSoonModal/ComingSoonModal";
import StyledTitleDot from "../../common/StyledTitleDot";
import BreadCrumbs from "../../Breadcrumbs";
import NoDataFound from "../../NoDataFound";

export default function Shop() {
  const itemsPerPage = 10; // Her sayfada kaç öğe gösterileceğini belirleyin
  const [currentPage, setCurrentPage] = useState(); // Şu anki sayfayı saklamak için bir değişken
  const [selectedDate, setSelectedDate] = useState(null); // Şu anki sayfayı saklamak için bir değişken
  const [selectedSize, setSelectedSize] = useState(null);

  const [serviceItemsData, setServiceItemsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const cart = useSelector((state) => state.cart.products?.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // URL bilgisine erişim sağlar

  useEffect(() => {
    dispatch(setPageType("additional_services"));
    if (currentPage) {
      fetchServiceItems(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageFromURL = parseInt(queryParams.get("page") || "1", 10);

    setCurrentPage(pageFromURL);
  }, [location.search]);

  const fetchServiceItems = (page) => {
    setLoader(true);
    const accessToken = localStorage.getItem("accessToken");
    const token = localStorage.getItem("loginUser");

    let url = `${process.env.REACT_APP_BASE_URL}services?category_id=3&page=${page}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setServiceItemsData(response.data);
        const totalItems = response.data.count;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      })
      .catch((error) => {
        console.error("There was an error fetching the service items:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Burada ek olarak, URL'de sayfa numarasını güncelleyebilirsiniz.

    if (value !== 1) {
      navigate(`?page=${value}`);
    } else {
      navigate(``);
    }
  };

  return (
    <>
      <Typography className="title" variant="h4">
        Services
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Services", "Cleaning Services"]} />
      <>
        <Grid container spacing={3}>
          {!loader ? (
            serviceItemsData?.results?.length ? (
              <>
                {
                  serviceItemsData?.results.map((item, idx) => (
                    <ServiceItems
                      item={item}
                      key={item.service_id}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      selectedSize={selectedSize}
                      setSelectedSize={setSelectedSize}
                      serviceType={"services"}
                    />
                  ))
                }
                <Grid item xs={12}>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    sx={{ mt: 10, display: "flex", justifyContent: "center" }}
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Grid>
              </>
            ) : (
              <NoDataFound />
            )
          ) : (
            <CircularProgress
              sx={{ position: "fixed", left: "50%", top: "50%" }}
            />
          )}
        </Grid>
      </>
    </>
  );
}
