import React, { useEffect, useRef, useState } from "react";
import Map from "./Map";
import Locations from "./Locations";
import axiosInstance from "../../../../axiosInstance";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function Residences() {
  const [locationDatas, setLocationDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const mapRef = useRef(null);

  const moveToLocation = (lng, lat) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 14,
      });
    }
  };

  const getLocations = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoading(true);
    try {
      const response = await axiosInstance.get("dashboard/residences", {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      setLocationDatas(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error sending data:", error.response);
    }
  };

  useEffect(() => {
    // if (selectedBranchesString?.length) {
    //   getLocations();
    // }
    getLocations();
  }, [selectedBranchesString]);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        maxWidth: "fit-content",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        sx={{ width: "100%", textAlign: "start", marginBottom: "1rem" }}
        variant="h6"
        color="primary"
      >
        Residence Locations
      </Typography>

      <Map mapRef={mapRef} locationDatas={locationDatas} />
      <Locations
        moveToLocation={moveToLocation}
        locationDatas={locationDatas}
      />
    </>
  );
}
