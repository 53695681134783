import logo from "./logo.svg";
import "./index.scss";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Home from "./components/Home";
import MyDocs from "./pages/MyDocs";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import { LoginContext } from "./contexts/loginContext";
import { useEffect, useState } from "react";
import Customers from "./pages/Customers";
import RoomFinder from "./pages/RoomFinder/RoomFinder";
import RoomFinderSingle from "./pages/RoomFinder/RoomFinderSingle";
import StartRequest from "./pages/MyRequests/StartRequests";
import MyRequests from "./pages/MyRequests/MyRequests";
import MyBookings from "./pages/MyBookings/MyBookings";
import MyCommissions from "./pages/MyCommissions/MyCommissions";
import MyInvoices from "./pages/MyInvoices/MyInvoices";
import UserGuide from "./pages/UserGuide/UserGuide";
import InstantBooking from "./pages/InstantBooking/InstantBooking";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/store/store";
import ResidenceChange from "./pages/ResidenceChange/ResidenceChange";
import MaintenanceRequest from "./pages/MaintenanceRequest/MaintenanceRequest";
import RoomChangeRequest from "./pages/RoomChangeRequest/RoomChangeRequest";
import Quotes from "./pages/Quotes/Quotes";
import GeneralModalTwo from "./components/Modal/GeneralModalTwo";
import Payment from "./pages/Payment/Payment";
import { updateWindowWidth } from "./redux/actions/actions";
import { RequireAuth } from "./components/RequireAuth";
import Alert from "./components/Alert/Dialog";
import Dialog from "./components/Alert/Dialog";
import DialogComp from "./components/Alert/Dialog";
import Notification from "./components/Notification";
import DashboardMain from "./components/DashboardMain/DashboardMain";
import { ThemeProvider } from "@emotion/react";
import { getTheme } from "./styles/theme";
import "./style.scss";
import Profile from "./pages/Profile/Profile";
import { setProfile } from "./redux/actions/cartActions";
import axiosInstance from "./axiosInstance";
import Shop from "./pages/Shop/Shop";
import AdditionalServices from "./pages/AdditionalServices/AdditionalServices";
import AirportServices from "./components/AdditionalServices/AirportServices/AirportServices";
import CleaningServices from "./components/AdditionalServices/CleaningServices/CleaningServices";
import QuoteDetails from "./pages/Quotes/QuoteDetails";
import RequestDetails from "./pages/MyRequests/RequestDetails";
import { DateRangeProvider } from "./contexts/Dashboard/DateRange";
import Settings from "./pages/Settings/Settings";
import AddUser from "./pages/Settings/AddUser";
import AddBranch from "./pages/Settings/AddBranch";
import StartQuote from "./pages/Quotes/StartQuote";
import Tickets from "./pages/Tickets";
import TicketsSingle from "./pages/Tickets/TicketsSingle";
import FramePage from "./pages/FramePage";
import { RoomFinderProvider } from "./contexts/RoomFinderContext";
import ResidenceDetails from "./pages/ResidenceDetails/ResidenceDetails";

function App() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const profile = useSelector((state) => state.profile); // Redux state'inden lightMode değerini çekin

  // const getProfile = async () => {
  //   let accessToken = localStorage.getItem("accessToken");
  //   let token = localStorage.getItem("loginUser");
  //   try {
  //     setLoader(true);
  //     // GET isteği yapılacak URL
  //     let url;
  //     url = `https://testapi.partners.londonist.co.uk/auth/profile`;

  //     // Axios ile GET isteği yapılıyor
  //     const response = await axiosInstance.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`, // Bearer token
  //         XRefreshToken: `${token}`, // XRefreshToken
  //         "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
  //       },
  //     });
  //     // Yanıtın içeriğini konsola yazdır
  //     dispatch(setProfile(response.data.profile));
  //     localStorage.setItem("profile", JSON.stringify(response.data.profile));
  //     setLoader(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   getProfile();
  // }, [profile]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(updateWindowWidth());
    };

    window.addEventListener("resize", handleResize);

    // İlk yükleme için pencere boyutunu güncelle
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const theme = getTheme(lightMode); // lightMode değerine göre temayı alın

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RoomFinderProvider>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            >
              <Route
                path="/"
                index
                element={
                  <DateRangeProvider>
                    <DashboardMain />
                  </DateRangeProvider>
                }
              />
              <Route path="/my-docs" element={<MyDocs />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/room-finder" element={<RoomFinder />} />
              <Route
                path="/room-finder-results"
                element={<RoomFinderSingle />}
              />
              <Route path="/start-request" element={<StartRequest />} />
              <Route path="/myrequests" element={<MyRequests />} />
              <Route path="/residence-change" element={<ResidenceChange />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/tickets/:id" element={<TicketsSingle />} />
              <Route path="/residences/:id" element={<ResidenceDetails />} />
              <Route path="/frame/:url" element={<FramePage />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/payment" element={<Payment />} />
              <Route
                path="/payment/invoices/:invoiceId"
                element={<Payment paymentType={"invoice"} />}
              />
              <Route
                path="/payment/airport-transfer/:invoiceId"
                element={<Payment paymentType={"invoice"} />}
              />
              <Route
                path="/payment/services"
                element={<Payment paymentType={"services"} />}
              />
              <Route
                path="/payment/bookings/:bookingId"
                element={<Payment paymentType={"booking"} />}
              />
              <Route
                path="/payment/shop"
                element={<Payment paymentType={"shop"} />}
              />

              <Route
                path="/maintenance-request"
                element={<MaintenanceRequest />}
              />
              <Route
                path="/room-change-request"
                element={<RoomChangeRequest />}
              />
              <Route path="/mybookings" element={<MyBookings />} />
              <Route path="/mycommissions" element={<MyCommissions />} />
              <Route path="/myinvoices" element={<MyInvoices />} />
              <Route path="/user-guide" element={<UserGuide />} />
              <Route path="/instant-booking" element={<InstantBooking />} />
              <Route
                path="/additional-services"
                element={<AdditionalServices />}
              />

              <Route
                path="/additional-services/airport"
                element={<AirportServices />}
              />
              <Route
                path="/additional-services/cleaning-services"
                element={<CleaningServices />}
              />
              <Route path="/quotes" element={<Quotes />} />
              <Route
                path="/quotes/:quoteId/details"
                element={<QuoteDetails />}
              />
              <Route path="/quotes/start-quote" element={<StartQuote />} />
              <Route
                path="/myrequests/:requestId/details"
                element={<RequestDetails />}
              />
              <Route path="/shop" element={<Shop />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/add-user" element={<AddUser />} />
              <Route path="/add-branch" element={<AddBranch />} />
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </RoomFinderProvider>
        <DialogComp />
        <Notification />
        <GeneralModalTwo />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
