import { useDispatch, useSelector } from "react-redux";
import FilterPagesGeneral from "../../components/FilterPagesGeneral";
import { fetchListItems, setPageType } from "../../redux/actions/actions";
import { useEffect } from "react";

export default function MyRequests() {
  const filterMethods = ["range_types"];
  const buttons = ["export_report", "start_request"];

  return (
    <FilterPagesGeneral
      pageName={"partner_requests"}
      filterMethods={filterMethods}
      buttonTypes={buttons}
    />
  );
}
