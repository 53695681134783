import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import CheckIcon from "@mui/icons-material/Check";
import PaymentIcon from "@mui/icons-material/Payment";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import StepFirst from "../../components/InstantBookings/StepFirst";
import StepSecond from "../../components/InstantBookings/StepSecond";
import StepThird from "../../components/InstantBookings/StepThird";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import axiosInstance from "../../axiosInstance";
import { openModal } from "../../redux/actions/actions";
import DocumentsRequiredModal from "../../components/Modal/DocumentsRequiredModal/DocumentsRequiredModal";
import ErrorModal from "../../components/Modal/ErrorModal/ErrorModal";

export function NextButton({
  activeStep,
  steps,
  handleNext,
  formValues,
  formErrors,
}) {
  const validationControl = () => {
    if (activeStep + 1 === 1) {
      if (
        !formValues.firstname ||
        !formValues.lastname ||
        !formValues.email ||
        !formValues.phone ||
        !formValues.nationality ||
        formErrors.phone ||
        formErrors.email
      ) {
        return true;
      } else {
        return false;
      }
    } else if (activeStep + 1 === 2) {
      if (!formValues.checkIn || !formValues.checkOut || !formValues.question) {
        return true;
      } else {
        return false;
      }
    }
  };
  React.useEffect(() => {
    validationControl();
  }, [formValues]);
  return (
    <Button
      disabled={validationControl()}
      variant="contained"
      color="primary"
      onClick={handleNext}
      sx={{ width: "100%", height: "56px" }}
    >
      {activeStep === steps.length - 1
        ? "Pay and Complete"
        : activeStep === steps.length
        ? "Pay and Complete"
        : "Submit"}
    </Button>
  );
}

export function BackButton({ activeStep, handleBack, buttonType }) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    activeStep !== 0 &&
    (buttonType === "button" ? (
      <Button
        sx={{ width: "100%", height: "56px" }}
        variant="contained"
        color="inherit"
        onClick={handleBack}
      >
        Back
      </Button>
    ) : (
      <Button onClick={handleBack}>
        <ArrowBackIcon sx={{ color: lightMode ? "#bf0000" : "#ffffff" }} /> Back
      </Button>
    ))
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SendTimeExtensionIcon />,
    2: <ThumbUpAltIcon />,
    3: <PaymentIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Place your request", "Confirm your reservation"];

export default function InstantBooking() {
  const initialState = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    question: "",
  };
  const [countries, setCountries] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [formValues, setFormValues] = React.useState(initialState);
  const [postPaymentDetails, setPostPaymentDetails] = React.useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();
  const { instantBooking } = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!instantBooking) {
      navigate("/room-finder");
    }
  }, [instantBooking]);

  const [formErrors, setFormErrors] = React.useState({});

  // React.useEffect(() => {
  //   // Eğer "email" için bir hata mesajı varsa, 5 saniye sonra sıfırla
  //   if (formErrors.email) {
  //     const timer = setTimeout(() => {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         email: "", // Hata mesajını sıfırla
  //       }));
  //     }, 5000); // 5000 milisaniye = 5 saniye

  //     // Komponent unmount olduğunda zamanlayıcıyı temizle
  //     return () => clearTimeout(timer);
  //   }
  // }, [formErrors.email]); // formErrors.email değiştiğinde useEffect tetiklenir

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    if (!formValues.firstname.trim()) {
      errors.firstname = "First name is required";
    }

    if (!formValues.lastname.trim()) {
      errors.lastname = "Last name is required";
    }

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email is not valid";
    }

    if (!formValues?.phoneIsValid) {
      errors.phone = "Phone is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };

  React.useEffect(() => {
    if (formValues.email && formValues.phone) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        console.log("Form errors:", errors);
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  React.useEffect(() => {
    console.log(formErrors, "form errors here");
  }, [formErrors]);

  const handleNext = async () => {
    if (steps.length - 1 !== activeStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep + 1 === steps.length) {
      let loaderPay = false;
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      try {
        setLoader(true);
        const formattedCheckIn = dayjs(instantBooking?.checkIn).format(
          "YYYY-MM-DD"
        );
        const formattedCheckOut = dayjs(instantBooking?.checkOut).format(
          "YYYY-MM-DD"
        );

        // formValues içindeki question'ı student objesi altında form içinde message olarak ekliyoruz
        const modifiedFormValues = {
          ...formValues,
          form: {
            // form objesini ekliyoruz
            source: "unknown",
            message: formValues.question, // question'ı message olarak ekliyoruz
          },
        };
        // question'ı ana objeden kaldırıyoruz, eğer API'de bu şekilde gönderilmesi gerekiyorsa
        delete modifiedFormValues.question; // formValues'ten question'ı çıkar
        delete modifiedFormValues.checkIn; // formValues'ten question'ı çıkar
        delete modifiedFormValues.checkOut; // formValues'ten question'ı çıkar
        delete modifiedFormValues.phoneIsValid; // formValues'ten question'ı çıkar

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BASE_URL}booking/checkout`,
          {
            student: modifiedFormValues, // Düzenlenmiş formValues'ı kullanıyoruz
            invoice: {
              course_id: `${instantBooking?.course_id}`,
              checkin: formattedCheckIn, // Formatlanmış checkIn değeri
              checkout: formattedCheckOut, // Formatlanmış checkOut değeri
              campus_id: `${instantBooking?.campus_id}`,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              XRefreshToken: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setLoader(false);
        setPostPaymentDetails(response.data);
      } catch (error) {
        setLoader(false);
        console.log(error);
        if (error.response.data.message === "documents_required") {
          dispatch(
            openModal(
              <DocumentsRequiredModal data={error.response.data} />,
              "documentsRequired"
            )
          );
        }
        //  else {
        //   dispatch(
        //     openModal(
        //       <ErrorModal data={error.response.data} />,
        //       "instantBooking"
        //     )
        //   );
        // }
        if (error.response) {
          console.log("Error response:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else {
          console.log("Error message:", error.message);
        }
      }
      if (!loaderPay) {
        console.log("navigated");
      }
      //navigate(`/payment/bookings/123`, {});
    }
  };

  React.useEffect(() => {
    if (postPaymentDetails) {
      navigate(`/payment/bookings/${postPaymentDetails.book_id}`, {
        state: { postPaymentBookingDetails: postPaymentDetails },
      });
    }
  }, [postPaymentDetails]);

  // React.useEffect(() => {
  //   console.log(activeStep, "ac st");
  // }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = ({ target: { name, value } }) => {
    // Harf kontrolü için regex
    // const lettersRegex = /^[A-Za-z]+$/;
    const lettersRegex = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/;

    // Ülke seçimi durumu
    if (name === "nationality") {
      const selectedCountry = countries.find((country) => country.id === value);

      if (selectedCountry) {
        setFormValues((prev) => ({
          ...prev,
          nationality: selectedCountry.name,
          home_country: `${selectedCountry.id}`,
        }));
      }
    } else if (name === "firstname" || name === "lastname") {
      // firstname veya lastname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      // Diğer tüm input alanlarını güncelle
      const updatedValue =
        name === "checkIn" || name === "checkOut"
          ? dayjs(value).format("YYYY-MM-DD")
          : value;

      setFormValues((prev) => ({
        ...prev,
        [name]: updatedValue,
      }));
    }
  };

  // const handleChange = ({ target: { name, value } }) => {

  //   // Ülke seçimi durumu
  //   if (name === "nationality") {
  //     const selectedCountry = countries.find((country) => country.id === value);

  //     // Eğer bir ülke seçildiyse, formValues içindeki ilgili alanları güncelle
  //     if (selectedCountry) {
  //       setFormValues((prev) => ({
  //         ...prev,
  //         nationality: selectedCountry.name,
  //         home_country: `${selectedCountry.id}`,
  //       }));
  //     }
  //   }
  //   // else if (name === "phone") {
  //   //   console.log("phone seçildi");
  //   //   setFormValues((prev) => ({
  //   //     ...prev,
  //   //     phone: value,
  //   //   }));
  //   // }
  //   else {
  //     // Diğer input alanlarının değerlerini güncelle (tarihler dahil)
  //     const updatedValue =
  //       name === "checkIn" || name === "checkOut"
  //         ? dayjs(value).format("YYYY-MM-DD")
  //         : value;

  //     setFormValues((prev) => ({
  //       ...prev,
  //       [name]: updatedValue,
  //     }));
  //   }
  // };

  // React.useEffect(() => {
  //   console.log(formValues, "form values here");
  // }, [formValues]);

  const stepContents = [
    <StepFirst
      activeStep={activeStep}
      steps={steps}
      handleNext={handleNext}
      formValues={formValues}
      setFormValues={setFormValues}
      handleChange={handleChange}
      countries={countries}
      setCountries={setCountries}
      formErrors={formErrors}
      error={!!formErrors.phone}
      helperText={formErrors.phone || ""}
    />,
    <StepSecond
      activeStep={activeStep}
      steps={steps}
      handleNext={handleNext}
      handleBack={handleBack}
      formValues={formValues}
      setFormValues={setFormValues}
      handleChange={handleChange}
      instantBooking={instantBooking}
    />,
    // <StepThird
    //   activeStep={activeStep}
    //   steps={steps}
    //   handleNext={handleNext}
    //   handleBack={handleBack}
    //   formValues={formValues}
    //   setFormValues={setFormValues}
    //   handleChange={handleChange}
    // />,
  ];

  if (!instantBooking) return null;
  return loader ? (
    <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
  ) : (
    <>
      <Typography className="title" variant="h4">
        Instant Booking
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Instant Booking"]} />
      <Container
        sx={{
          width: { xs: "100%", md: "70vw", lg: "54vw" },
          padding: "0 !important",
        }}
        maxWidth={false}
      >
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, idx) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* <div>
          <Button
            disabled={activeStep === steps.length}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1
              ? "Finish"
              : activeStep === steps.length
              ? "Finish"
              : "Next"}
          </Button>
        </div> */}
        </Stack>
      </Container>
      {stepContents[activeStep]}
    </>
  );
}
