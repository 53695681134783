import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "../contexts/loginContext";
import { useSelector } from "react-redux";

export const RequireAuth = ({ children }) => {
  const token = useSelector((state) => state.token);

  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (token.accessToken && token.refreshToken) {
      setIsChecking(false);
    } else {
      setIsChecking(false);
    }
  }, [token]);

  if (isChecking) {
    return null // veya bir yükleme spinner'ı göster
  }

  if (!token.accessToken || !token.refreshToken) {
    // Kullanıcı giriş yapmamışsa, giriş sayfasına yönlendir
    return <Navigate to="/login" />;
  }

  // Kullanıcı giriş yapmışsa, çocuk bileşenleri (korunan sayfa) render et
  return children;
};
