const initialState = {
  lightMode: localStorage.getItem("lightMode") ? JSON.parse(localStorage.getItem("lightMode")) : true,
};

function modeReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_MODE":
      return {
        ...state,
        lightMode: !state.lightMode,
      };
    default:
      return state;
  }
}

export default modeReducer;
