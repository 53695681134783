const initialState = {
    message: '',
    show: false,
  };
  
  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SHOW_NOTIFICATION':
        return {
          ...state,
          message: action.payload,
          show: true,
        };
      case 'HIDE_NOTIFICATION':
        return {
          ...state,
          show: false,
        };
      default:
        return state;
    }
  };
  
  export default notificationReducer;
  