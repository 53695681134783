import { useDispatch, useSelector } from "react-redux";
import FilterPagesGeneral from "../../components/FilterPagesGeneral";
import { fetchListItems, setPageType } from "../../redux/actions/actions";
import { useEffect } from "react";

export default function Quotes() {
  const filterMethods = ["student_name", "status"];

  return (
    <FilterPagesGeneral pageName={"quotes"} filterMethods={filterMethods} />
  );
}
