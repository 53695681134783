import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import { useState } from "react";
import { getTheme } from "../../styles/theme";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import CloseIcon from "@mui/icons-material/Close";

export default function RoomChangeRequest() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const initialState = {
    roomNumber: "",
    invoiceNo: "",
    changeRoom: "",
    reason: "",
    notes: "",
    evidence: "",
  };

  const [formValues, setFormValues] = useState(initialState);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Seçilen dosya
    if (file) {
      setSelectedFileName(file.name); // Seçilen dosyanın ismini state'e kaydet
    } else {
      setSelectedFileName(""); // Dosya seçimi iptal edilirse, ismi temizle
    }
  };

  const handleCancelFileSelection = () => {
    setSelectedFileName("");
    // Form değerlerindeki 'evidence' alanını da temizleyin, eğer varsa
    setFormValues((prev) => ({ ...prev, evidence: "" }));
  };

  return (
    <>
      <Typography className="title" variant="h4">
        Room Change Request
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Room Change Request"]} />
      <Container
        maxWidth={false}
        sx={{
          width: { xs: "100%", md: "70vw", lg: "54vw" },
          padding: "0 !important",
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          sx={{
            fontSize: "27px",
            fontWeight: "700",
            marginTop: "3rem",
            textAlign: "center",
          }}
        >
          Place Your Request
        </Typography>
        <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
              }}
              value={formValues.roomNumber}
              onChange={handleChange}
              name="roomNumber"
              id="outlined-basic"
              label="Current Residence / Room Number"
              variant="outlined"
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
              }}
              value={formValues.invoiceNo}
              onChange={handleChange}
              name="invoiceNo"
              id="outlined-basic"
              label="Invoice No"
              variant="outlined"
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{ maxWidth: "calc(100% - 40px)" }}
                    id="changeRoom"
                  >
                    Do you want to change the room for same category or
                    different category in the same building?
                  </InputLabel>
                  <Select
                    name="changeRoom"
                    labelId="changeRoom"
                    id="demo-simple-select"
                    value={formValues.changeRoom}
                    label="Do you want to change the room for same category or different category in the same building?"
                    onChange={handleChange}
                  >
                    <MenuItem value={"1"}>Lorem Ipsum</MenuItem>
                    <MenuItem value={"2"}>Lorem Ipsum</MenuItem>
                    <MenuItem value={"3"}>Lorem Ipsum</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{ maxWidth: "calc(100% - 40px)" }}
                    id="reason"
                  >
                    Reason Of The Room Change*
                  </InputLabel>
                  <Select
                    name="reason"
                    labelId="reason"
                    id="reason"
                    value={formValues.reason}
                    label="Reason Of The Room Change*"
                    onChange={handleChange}
                  >
                    <MenuItem value={"cleaningIssues"}>
                      Cleaning Issues
                    </MenuItem>
                    <MenuItem value={"wifIsSlow"}>Wifi Is Slow</MenuItem>
                    <MenuItem value={"HeaterIsNotWorking"}>
                      Heater Is Not Working
                    </MenuItem>
                    <MenuItem value={"fridgeIsBroken"}>
                      Fridge Is Broken
                    </MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: "100%",
                height: "100%",
              }}
              value={formValues.notes}
              onChange={handleChange}
              name="notes"
              id="outlined-basic"
              label="Notes"
              variant="outlined"
              type="text"
              multiline // This enables the textarea feature
              InputProps={{
                inputProps: {
                  style: {
                    height: "100%",
                    boxSizing: "border-box", // Padding ve border dahil olmak üzere yüksekliğin hesaplanmasını sağlar
                  },
                },
                style: {
                  // Bu, dış div'e stil verir
                  height: "100%",
                  overflow: "hidden", // Eğer içerik kutudan taşarsa gizler
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {selectedFileName ? (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ position: "relative" }}
                >
                  <Typography variant="body1" component="span">
                    Selected file: {selectedFileName}
                  </Typography>
                  <CloseIcon
                    onClick={handleCancelFileSelection}
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      fontSize: "16px",
                      top: "-7px",
                      right: "-15px",
                    }}
                  />{" "}
                  {/* Bu simgeye tıklanınca dosya seçimini iptal edecek bir işlev eklemek isteyebilirsiniz */}
                </Box>
              ) : (
                <Typography variant="body1">
                  If Any Evidence/Proof Please Attach
                </Typography>
              )}

              <Button
                sx={{
                  height: "100%",
                  marginLeft: "1rem",
                }}
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon sx={{ color: "#ffffff" }} />}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  hidden
                />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "100%",
                display: "block",
                minHeight: "56px",
                width: "100%",
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
