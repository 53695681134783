// rootReducer.js
import { combineReducers } from "redux";
import filtersReducer from "./filterReducer";
import listItemsReducer from "./listItemsReducer";
import modalReducer from "./modalReducer";
import searchTermReducer from "./searchTermReducer";
import windowWidthControlReducer from "./windowWidthControlReducer";
import studentReducer from "./studentReducer";
import tokenReducer from "./tokenReducer";
import dialogReducer from "./dialogReducer";
import notificationReducer from "./notificationReducer";
import cartReducer from "./cartReducer";
import modeReducer from "./modeReducer";
import profileReducer from "./profileReducer";
import branchesReducer from "./branchesReducer";

const rootReducer = combineReducers({
  filters: filtersReducer,
  listItems: listItemsReducer,
  modal: modalReducer,
  searchTerm: searchTermReducer,
  cart: cartReducer,
  windowWidth: windowWidthControlReducer,
  student: studentReducer,
  token: tokenReducer,
  dialog: dialogReducer,
  notification: notificationReducer, // Ve bu satırı ekleyin
  mode: modeReducer,
  profile: profileReducer,
  branches: branchesReducer,
});

export default rootReducer;
