import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BadgeIcon from "@mui/icons-material/Badge";
import KeyIcon from "@mui/icons-material/Key";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import FlagIcon from "@mui/icons-material/Flag";
import LanguageIcon from "@mui/icons-material/Language";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useDispatch, useSelector } from "react-redux";
import { fetchListItems, openModal } from "../../redux/actions/actions";
import TableDetailsModal from "../Modal/TableDetailsModal";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import NoDataFound from "../NoDataFound";

export default function DashboardTable({
  tableHeader,
  allTableHeader,
  isMobileSm,
  isMobileMd,
  tableType,
  tableCardRefControl,
}) {
  const [filteredDatas, setFilteredDatas] = useState();
  const [rawData, setRawData] = useState();
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const [loader, setLoader] = useState(false);
  const loginControlDef = localStorage.getItem("loginUser");
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const [bookingsTotal, setBookingsTotal] = useState();
  const [commissionsTotal, setCommissionsTotal] = useState();

  const getTableDatas = async () => {
    setLoader(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}${tableType}`;
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${loginControlDef}`,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json",
        },
      });

      // Verileri created_at veya issue_date'e göre azalan sırada sırala
      const sortedData = response.data[tableType].sort((a, b) => {
        const dateA = new Date(a.created_at || a.issue_date);
        const dateB = new Date(b.created_at || b.issue_date);
        return dateB - dateA; // En yeni tarihten en eskiye sırala
      });

      // Sıralanmış verilerin ilk 10 öğesini al
      const latestTenItems = sortedData.slice(0, 10);

      // State'i güncelle
      setFilteredDatas(latestTenItems);
      setRawData(latestTenItems);
      // setFilteredDatas([]);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (filteredDatas?.length) {
      let totalBookingSales = 0;
      filteredDatas?.forEach((item) => {
        totalBookingSales += item?.invoice_course_price;
      });
      setBookingsTotal(totalBookingSales);
      let totalCommissionsSales = 0;
      filteredDatas?.forEach((item) => {
        totalCommissionsSales += item?.commission_amount;
      });
      setCommissionsTotal(totalCommissionsSales);
    } else {
      setBookingsTotal(0);
      setCommissionsTotal(0);
    }
  }, [filteredDatas]);

  useEffect(() => {
    if (rawData) {
      if (tableType === "bookings") {
        console.log(rawData, "rawdata here");
        const uniqueInvoices = rawData.reduce((acc, current) => {
          const x = acc.find((item) => item.invoice_id === current.invoice_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            x.details.check_in += `, ${current.details.check_in}`;
            x.details.check_out += `, ${current.details.check_out}`;
            return acc;
          }
        }, []);
        setFilteredDatas(uniqueInvoices);
      }
    }
  }, [rawData, tableType]); // filteredDatas değiştiğinde bu useEffect tetiklenir.

  useEffect(() => {
    // if (selectedBranchesString?.length) {
    //   getTableDatas();
    // }
    getTableDatas();
  }, [selectedBranchesString]);

  const handleOpenModal = (row) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(
      openModal(<TableDetailsModal data={row} columns={allTableHeader} />)
    );
  };

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  return loader ? (
    <Box
      sx={{
        display: "flex",
        maxWidth: "fit-content",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        sx={{
          width: "100%",
          textAlign: "start",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "99",
          background: lightMode ? "#ffffff" : "#343434",
          paddingBottom: "1rem",
        }}
        variant="h6"
        color="primary"
      >
        Latest {tableType === "bookings" ? "Bookings" : "Commissions"}
      </Typography>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {filteredDatas?.length ? (
          <TableContainer
            sx={{
              height: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.4em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "5rem",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            <Table
              sx={{ height: "100%" }}
              stickyHeader
              aria-label="sticky table"
            >
              <caption
                style={{
                  backgroundColor: lightMode ? "#f7f7f7" : "#303030",
                  color: lightMode ? "#000000" : "#ffffff",
                  height: "80px",
                  alignContent: "center",
                  textAlign: isMobileMd ? "center" : "end",
                  bottom: "0",
                  left: "0",
                  zIndex: "99",
                  filter: "drop-shadow(2px 2px 3px #aeaeae)",
                  fontSize: isMobileMd ? "18px" : "24px",
                }}
              >
                <span style={{ color: "#bf0000" }}>
                  <b>Total :</b>
                </span>{" "}
                {tableType === "bookings"
                  ? paraFormat(bookingsTotal)
                  : paraFormat(commissionsTotal)}
              </caption>
              <TableHead>
                <TableRow>
                  {tableHeader?.map((column, idx) => (
                    <TableCell
                      key={`${column?.label} - ${idx}`}
                      align={"center"}
                      sx={{
                        minWidth: { xs: "110px", md: "fit-content" },
                        fontSize: { xs: "14px", md: "14px" },
                        padding: ".5rem",
                        height: tableType !== "bookings" ? "50px" : "70px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {isMobileSm || isMobileMd || tableCardRefControl?.bool ? (
                    <TableCell
                      align={"center"}
                      sx={{
                        minWidth: { xs: "25%", md: "fit-content" },
                        padding: ".5rem",
                      }}
                    ></TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDatas?.length && tableHeader.length ? (
                  <>
                    {filteredDatas.map((row, idx) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={`${row.code} - ${idx}`}
                        >
                          {tableHeader.map((column) => {
                            let value = row[column.id];
                            if (
                              typeof value === "boolean" &&
                              column.id !== "commission_paid_status"
                            ) {
                              value = value ? "Yes" : "No";
                            }
                            return (
                              <TableCell
                                sx={{
                                  padding: ".6rem",
                                  fontWeight:
                                    column.id === "status" ||
                                    column.id === "commission_paid_status"
                                      ? "700"
                                      : "400",
                                  color:
                                    value === "fullypaid" ||
                                    (column.id === "commission_paid_status" &&
                                      value)
                                      ? "green"
                                      : value === "partiallypaid"
                                      ? "orange"
                                      : value === "unpaid" ||
                                        (column.id ===
                                          "commission_paid_status" &&
                                          !value)
                                      ? "#bf0000"
                                      : value === "overpaid"
                                      ? "blue"
                                      : value === "depositpaid"
                                      ? "orange"
                                      : lightMode
                                      ? "black"
                                      : "#ffffff",
                                  height:
                                    tableType !== "bookings" ? "50px" : "70px",
                                }}
                                key={column.id}
                                align={"center"}
                              >
                                {column?.format && typeof value === "number"
                                  ? column?.format(value)
                                  : column.id === "check_in"
                                  ? row?.details?.check_in
                                  : column.id === "student_name"
                                  ? row?.student_name
                                      ?.split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(" ")
                                  : column.id === "commission_type"
                                  ? row?.commission_type
                                      ?.split("_")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")
                                  : column.id === "quote_status"
                                  ? row?.quote_status
                                      ?.split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(" ")
                                  : column.id === "commission_paid_status" &&
                                    value
                                  ? "Paid"
                                  : column.id === "commission_paid_status" &&
                                    !value
                                  ? "Unpaid"
                                  : column?.id === "agent_name"
                                  ? row?.created_by_agent_name
                                    ? (value = row?.created_by_agent_name)
                                    : (value = "")
                                  : column.id === "check_out"
                                  ? row?.details?.check_out
                                  : column.id === "gross_price" ||
                                    column.id === "invoice_course_price" ||
                                    column.id === "commission_amount"
                                  ? paraFormat(value)
                                  : value === "fullypaid"
                                  ? "Fully Paid"
                                  : value === "depositpaid"
                                  ? "Deposit Paid"
                                  : value === "partiallypaid"
                                  ? "Partially Paid"
                                  : value === "overpaid"
                                  ? "Over Paid"
                                  : value === "unpaid"
                                  ? "Unpaid"
                                  : value}
                              </TableCell>
                            );
                          })}
                          {isMobileSm ||
                          isMobileMd ||
                          tableCardRefControl?.bool ? (
                            <TableCell sx={{ padding: ".6rem" }} align="center">
                              <Button
                                onClick={() => {
                                  handleOpenModal(row);
                                }}
                                variant="contained"
                                sx={{
                                  textTransform: "none",
                                  padding: ".25rem",
                                }} // Düğmenin metninin büyük harf olmasını engelliyoruz
                              >
                                Details
                              </Button>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}

                    {/* <TableRow>
                    <TableCell colSpan={"100%"} />
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">£100.000</TableCell>
                  </TableRow> */}
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : tableType === "bookings" ? (
          <Box
            sx={{
              width: "100%",
              height: "calc(100% - 0px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">No Bookings Found!</Typography>
          </Box>
        ) : tableType === "commissions" ? (
          <Box
            sx={{
              width: "100%",
              height: "calc(100% - 0px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">No Commissions Found!</Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
