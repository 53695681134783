export const stateCodes = [
    {
        id: 1,
        state_name: "Australian Capital Territory",
        country_name: "Australia",
        code: "AU-ACT"
    },
    {
        id: 2,
        state_name: "New South Wales",
        country_name: "Australia",
        code: "AU-NSW"
    },
    {
        id: 3,
        state_name: "Northern Territory",
        country_name: "Australia",
        code: "AU-NT"
    },
    {
        id: 4,
        state_name: "Queensland",
        country_name: "Australia",
        code: "AU-QLD"
    },
    {
        id: 5,
        state_name: "South Australia",
        country_name: "Australia",
        code: "AU-SA"
    },
    {
        id: 6,
        state_name: "Tasmania",
        country_name: "Australia",
        code: "AU-TAS"
    },
    {
        id: 7,
        state_name: "Victoria",
        country_name: "Australia",
        code: "AU-VIC"
    },
    {
        id: 8,
        state_name: "Western Australia",
        country_name: "Australia",
        code: "AU-WA"
    },
    {
        id: 9,
        state_name: "Alberta",
        country_name: "Canada",
        code: "CA-AB"
    },
    {
        id: 10,
        state_name: "British Columbia",
        country_name: "Canada",
        code: "CA-BC"
    },
    {
        id: 11,
        state_name: "Manitoba",
        country_name: "Canada",
        code: "CA-MB"
    },
    {
        id: 12,
        state_name: "New Brunswick",
        country_name: "Canada",
        code: "CA-NB"
    },
    {
        id: 13,
        state_name: "Newfoundland and Labrador",
        country_name: "Canada",
        code: "CA-NL"
    },
    {
        id: 14,
        state_name: "Northwest Territories",
        country_name: "Canada",
        code: "CA-NT"
    },
    {
        id: 15,
        state_name: "Nova Scotia",
        country_name: "Canada",
        code: "CA-NS"
    },
    {
        id: 16,
        state_name: "Nunavut",
        country_name: "Canada",
        code: "CA-NU"
    },
    {
        id: 17,
        state_name: "Ontario",
        country_name: "Canada",
        code: "CA-ON"
    },
    {
        id: 18,
        state_name: "Prince Edward Island",
        country_name: "Canada",
        code: "CA-PE"
    },
    {
        id: 19,
        state_name: "Quebec",
        country_name: "Canada",
        code: "CA-QC"
    },
    {
        id: 20,
        state_name: "Saskatchewan",
        country_name: "Canada",
        code: "CA-SK"
    },
    {
        id: 21,
        state_name: "Yukon Territory",
        country_name: "Canada",
        code: "CA-YT"
    },
    {
        id: 22,
        state_name: "Alabama",
        country_name: "United States Of America",
        code: "US-AL"
    },
    {
        id: 23,
        state_name: "Alaska",
        country_name: "United States Of America",
        code: "US-AK"
    },
    {
        id: 24,
        state_name: "Arizona",
        country_name: "United States Of America",
        code: "US-AZ"
    },
    {
        id: 25,
        state_name: "Arkansas",
        country_name: "United States Of America",
        code: "US-AR"
    },
    {
        id: 26,
        state_name: "California",
        country_name: "United States Of America",
        code: "US-CA"
    },
    {
        id: 27,
        state_name: "Colorado",
        country_name: "United States Of America",
        code: "US-CO"
    },
    {
        id: 28,
        state_name: "Connecticut",
        country_name: "United States Of America",
        code: "US-CT"
    },
    {
        id: 29,
        state_name: "Delaware",
        country_name: "United States Of America",
        code: "US-DE"
    },
    {
        id: 30,
        state_name: "District of Columbia",
        country_name: "United States Of America",
        code: "US-DC"
    },
    {
        id: 31,
        state_name: "Florida",
        country_name: "United States Of America",
        code: "US-FL"
    },
    {
        id: 32,
        state_name: "Georgia",
        country_name: "United States Of America",
        code: "US-GA"
    },
    {
        id: 33,
        state_name: "Hawaii",
        country_name: "United States Of America",
        code: "US-HI"
    },
    {
        id: 34,
        state_name: "Idaho",
        country_name: "United States Of America",
        code: "US-ID"
    },
    {
        id: 35,
        state_name: "Illinois",
        country_name: "United States Of America",
        code: "US-IL"
    },
    {
        id: 36,
        state_name: "Indiana",
        country_name: "United States Of America",
        code: "US-IN"
    },
    {
        id: 37,
        state_name: "Iowa",
        country_name: "United States Of America",
        code: "US-IA"
    },
    {
        id: 38,
        state_name: "Kansas",
        country_name: "United States Of America",
        code: "US-KS"
    },
    {
        id: 39,
        state_name: "Kentucky",
        country_name: "United States Of America",
        code: "US-KY"
    },
    {
        id: 40,
        state_name: "Louisiana",
        country_name: "United States Of America",
        code: "US-LA"
    },
    {
        id: 41,
        state_name: "Maine",
        country_name: "United States Of America",
        code: "US-ME"
    },
    {
        id: 42,
        state_name: "Maryland",
        country_name: "United States Of America",
        code: "US-MD"
    },
    {
        id: 43,
        state_name: "Massachusetts",
        country_name: "United States Of America",
        code: "US-MA"
    },
    {
        id: 44,
        state_name: "Michigan",
        country_name: "United States Of America",
        code: "US-MI"
    },
    {
        id: 45,
        state_name: "Minnesota",
        country_name: "United States Of America",
        code: "US-MN"
    },
    {
        id: 46,
        state_name: "Mississippi",
        country_name: "United States Of America",
        code: "US-MS"
    },
    {
        id: 47,
        state_name: "Missouri",
        country_name: "United States Of America",
        code: "US-MO"
    },
    {
        id: 48,
        state_name: "Montana",
        country_name: "United States Of America",
        code: "US-MT"
    },
    {
        id: 49,
        state_name: "Nebraska",
        country_name: "United States Of America",
        code: "US-NE"
    },
    {
        id: 50,
        state_name: "Nevada",
        country_name: "United States Of America",
        code: "US-NV"
    },
    {
        id: 51,
        state_name: "New Hampshire",
        country_name: "United States Of America",
        code: "US-NH"
    },
    {
        id: 52,
        state_name: "New Jersey",
        country_name: "United States Of America",
        code: "US-NJ"
    },
    {
        id: 53,
        state_name: "New Mexico",
        country_name: "United States Of America",
        code: "US-NM"
    },
    {
        id: 54,
        state_name: "New York",
        country_name: "United States Of America",
        code: "US-NY"
    },
    {
        id: 55,
        state_name: "North Carolina",
        country_name: "United States Of America",
        code: "US-NC"
    },
    {
        id: 56,
        state_name: "North Dakota",
        country_name: "United States Of America",
        code: "US-ND"
    },
    {
        id: 57,
        state_name: "Ohio",
        country_name: "United States Of America",
        code: "US-OH"
    },
    {
        id: 58,
        state_name: "Oklahoma",
        country_name: "United States Of America",
        code: "US-OK"
    },
    {
        id: 59,
        state_name: "Oregon",
        country_name: "United States Of America",
        code: "US-OR"
    },
    {
        id: 60,
        state_name: "Pennsylvania",
        country_name: "United States Of America",
        code: "US-PA"
    },
    {
        id: 61,
        state_name: "Rhode Island",
        country_name: "United States Of America",
        code: "US-RI"
    },
    {
        id: 62,
        state_name: "South Carolina",
        country_name: "United States Of America",
        code: "US-SC"
    },
    {
        id: 63,
        state_name: "South Dakota",
        country_name: "United States Of America",
        code: "US-SD"
    },
    {
        id: 64,
        state_name: "Tennessee",
        country_name: "United States Of America",
        code: "US-TN"
    },
    {
        id: 65,
        state_name: "Texas",
        country_name: "United States Of America",
        code: "US-TX"
    },
    {
        id: 66,
        state_name: "Utah",
        country_name: "United States Of America",
        code: "US-UT"
    },
    {
        id: 67,
        state_name: "Vermont",
        country_name: "United States Of America",
        code: "US-VT"
    },
    {
        id: 68,
        state_name: "Virginia",
        country_name: "United States Of America",
        code: "US-VA"
    },
    {
        id: 69,
        state_name: "Washington",
        country_name: "United States Of America",
        code: "US-WA"
    },
    {
        id: 70,
        state_name: "West Virginia",
        country_name: "United States Of America",
        code: "US-WV"
    },
    {
        id: 71,
        state_name: "Wisconsin",
        country_name: "United States Of America",
        code: "US-WI"
    },
    {
        id: 72,
        state_name: "Wyoming",
        country_name: "United States Of America",
        code: "US-WY"
    }
]