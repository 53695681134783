import { Box, Container, Grid, Paper, Toolbar } from "@mui/material";

export default function MyDocs() {
    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
            }}
        >
            <Box>MY DOCS</Box>
        </Paper>
    )
}