import easytransferImg from "../../../assets/images/easytransfer.svg";
import flywireImg from "../../../assets/images/flywire.svg";

import easytransferLightImg from "../../../assets/images/easytransfer_light.svg";
import flywireLightImg from "../../../assets/images/flywire_light.svg";
import transferMateImg from "../../../assets/images/transfermate.png";

export const paymentMethods = [
  {
    name: "transfermate",
    img: transferMateImg,
    imgLight: transferMateImg,
  },
  {
    name: "easytransfer",
    img: easytransferImg,
    imgLight: easytransferLightImg,
  },
  {
    name: "flywire",
    img: flywireImg,
    imgLight: flywireLightImg,
  },
];
