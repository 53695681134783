import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import dayjs from "dayjs";
import PhoneCountryInput from "../../components/PhoneCountryInput";
import UpdateProfileModal from "../../components/Modal/UpdateProfileModal/UpdateProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/actions/actions";
import ProfileItems from "./ProfileItems";
import ProgressBar from "../../components/DashboardMain/ProgressBar/ProgressBar";
import ProgressBarProfile from "../../components/DashboardMain/ProgressBar/ProgressBarProfile";
import BarFirst from "../../components/Profile/Bars/BarFirst";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Profile() {
  const initialState = {
    phone: "",
    username: "",

    tax_address: "",
    tax_office: "",
    agent_name: "",
    address: "",
    tax_number: "",
    tax_name: "",
    id: null,
  };
  const [loader, setLoader] = useState(true);
  const [formValues, setFormValues] = useState(initialState);
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);

  const [barDataRemaining, setBarDataRemaining] = useState(null);
  const [barDataReached, setBarDataReached] = useState(null);

  const getProfile = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      setLoader(true);
      // GET isteği yapılacak URL
      let url;
      url = `${process.env.REACT_APP_BASE_URL}auth/profile`;

      // Axios ile GET isteği yapılıyor
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token
          XRefreshToken: `${token}`, // XRefreshToken
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      // Yanıtın içeriğini konsola yazdır
      setFormValues((prev) => ({
        ...prev,
        phone: response?.data?.profile?.phone,
        username: response?.data?.profile?.username,

        tax_address: response?.data?.profile?.tax_address,
        tax_office: response?.data?.profile?.tax_office,
        agent_name: response?.data?.profile?.agent_name,
        address: response?.data?.profile?.address,
        tax_number: response?.data?.profile?.tax_number,
        tax_name: response?.data?.profile?.tax_name,
        id: response?.data?.profile?.id,
      }));
      setLoader(false);
    } catch (error) {
      console.error("Error fetching dataaaa:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  // useEffect(() => {
  //   console.log(formValues, "here form values");
  // }, [formValues]);

  const handleOpenModal = (formValues, setFormValues) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(
      openModal(
        <UpdateProfileModal
          formValues={formValues}
          setFormValues={setFormValues}
          getProfile={getProfile}
        />,
        "updateProfile"
      )
    );
  };

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <>
      {loader ? (
        <CircularProgress
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <Box>
          <Typography className="title" variant="h4">
            Profile
            <StyledTitleDot />
          </Typography>
          <BreadCrumbs routs={["Profile"]} />
          <Box sx={{ marginTop: "3rem" }}>
            <Grid container spacing={3}>
              <ProgressBarProfile
                barType="profile"
                response={response}
                setResponse={setResponse}
              />
            </Grid>
            {response ? (
              <Grid container spacing={3} sx={{ marginBottom: "3rem" }}>
                <Grid item xs={12} md={12} lg={4}>
                  <Card
                    sx={{
                      borderRadius: "1rem",
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BarFirst
                      barType={"default"}
                      response={response}
                      setResponse={setResponse}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Card
                    sx={{
                      borderRadius: "1rem",
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BarFirst
                      barType={"amount"}
                      response={response}
                      setResponse={setResponse}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Card
                    sx={{
                      borderRadius: "1rem",
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BarFirst
                      barType={"weeks"}
                      response={response}
                      setResponse={setResponse}
                    />
                  </Card>
                </Grid>
              </Grid>
            ) : null}
            <Grid container spacing={3}>
              <ProfileItems
                formValues={formValues}
                setFormValues={setFormValues}
              />

              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Button
                  fullWidth
                  sx={{ height: "100%" }}
                  onClick={() => handleOpenModal(formValues, setFormValues)}
                  variant="contained"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}
