import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "100%",
  padding: "16px",
  overflow: "auto",
  border: "1px solid #cecece",
  boxShadow: "5px 5px 5px rgba(0, 0, 0, .3)",
  borderRadius: "1rem",
};

export default function GeneralModal({
  children,
  open,
  handleClose,
  modalType,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // React.useEffect(() => {
  //   console.log(open, "this is open control..");
  // }, [open]);
  return (
    <div>
      <Modal
        sx={{ zIndex: "999999", borderRadius: "1rem" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            maxWidth: isMobile
              ? "90%"
              : modalType == "addToCart"
              ? "1400px"
              : modalType == "cancelRequest"
              ? "550px"
              : "400px",
            maxHeight: isMobile ? "90%" : "90%",
            padding: isMobile
              ? modalType === "cancelRequest"
                ? "1rem"
                : "1rem"
              : modalType == "addToCart"
              ? "0"
              : modalType == "cancelRequest"
              ? "3rem"
              : "1rem",
            position: "relative",
            overflowX: "hidden",
            paddingBottom: isMobile
              ? modalType === "addToCart"
                ? "0"
                : "1rem"
              : "0rem",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              textAlign: "right",
              top: 0,
              right: 0,
              cursor: "pointer",
              zIndex: 9,
              padding: "1rem",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </Box>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
