import { CircularProgress, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RequestDetailsComp from "../../components/MyRequests/RequestDetails/RequestDetailsComp";

export default function RequestDetails() {
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [requestDetails, setRequestDetails] = useState([]);
  const { request } = location.state || {};
  const { requestId } = useParams(); // URL'den requestId parametresini al

  const navigate = useNavigate();
  
  const getRequestDetails = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoader(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}partner_requests/${
        request?.id || requestId
      }`;
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json",
        },
      });

      // State'i güncelle
      setLoader(false);
      setRequestDetails(response?.data);
    } catch (error) {
      setLoader(false);
      navigate("/myrequests");
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getRequestDetails();
  }, []);
  return (
    <>
      <Typography className="title" variant="h4">
        Request Details
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Partner Requests", `Request Details`]} />
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : (
        <RequestDetailsComp
          request={request}
          loader={loader}
          requestDetails={requestDetails}
        />
      )}
    </>
  );
}
