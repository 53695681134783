import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";
import { useSelector } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import PersonIcon from "@mui/icons-material/Person";
import { Person } from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCards,
  EffectCube,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/effect-cards";
import "./style.css";

function RecentActivityItem({ type, idx, data }) {
  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const stringTransformation = (value) => {
    // QUOTE_STATUS_CHOICES = [
    //   (0, "Draft"),
    //   (1, "Issued"),
    //   (2, "Sent"),
    //   (3, "accepted"),
    //   (4, "declined"),
    //   (5, "expired"),
    //   (6, "deposit_pending"),
    //   (7, "extension_payment_pending"),
    // ];

    // NVOICE_STATUS_CHOICES = [
    //   (0, "unpaid"),
    //   (1, "depositpaid"),
    //   (2, "partiallypaid"),
    //   (3, "fullypaid"),
    //   (4, "overdue"),
    //   (5, "overpaid"),
    //   (6, "canceled"),
    // ];

    if (value === "fullypaid") {
      return "Fully Paid";
    } else if (value === "deposit_pending") {
      return "Deposit Pending";
    } else if (value === "Draft") {
      return "Draft";
    } else if (value === "Issued") {
      return "Issued";
    } else if (value === "Sent") {
      return "Sent";
    } else if (value === "accepted") {
      return "Accepted";
    } else if (value === "declined") {
      return "Declined";
    } else if (value === "expired") {
      return "Expired";
    } else if (value === "extension_payment_pending") {
      return "Extension Payment Pending";
    } else if (value === "unpaid") {
      return "Unpaid";
    } else if (value === "depositpaid") {
      return "Deposit Paid";
    } else if (value === "partiallypaid") {
      return "Partially Paid";
    } else if (value === "overdue") {
      return "overdue";
    } else if (value === "overpaid") {
      return "Overpaid";
    } else if (value === "canceled") {
      return "Cancelled";
    }
  };
  return (
    <Box
      key={idx}
      sx={{
        padding: "1rem",
        boxSizing: "border-box",
        borderRadius: "2rem",
        display: "flex",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          //   position: "absolute",
          //   left: "-10px",
          //   top: "-10px",
        }}
      >
        {type === "recent_invoices" && (
          <ReceiptIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
        )}
        {type === "recent_quotes" && (
          <ReceiptLongIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
        )}

        {type === "recent_payments_overdue" && (
          <Box sx={{ position: "relative" }}>
            <NotificationsActiveIcon
              sx={{
                fontSize: "24px",
                position: "absolute",
                background: "#ffffff",
                color: "#36193e",
                padding: "3px",
                borderRadius: "2rem",
                top: "-6px",
                right: "-6px",
              }}
            />
            <PaymentIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
          </Box>
        )}

        {type === "recent_invoices_overdue" && (
          <Box sx={{ position: "relative" }}>
            <NotificationsActiveIcon
              sx={{
                fontSize: "24px",
                position: "absolute",
                background: "#ffffff",
                color: "#9e4c20",
                padding: "3px",
                borderRadius: "2rem",
                top: "-6px",
                right: "-6px",
              }}
            />
            <ReceiptIcon sx={{ fontSize: "40px", color: "#ffffff" }} />
          </Box>
        )}
      </Box>
      <Box sx={{ paddingLeft: "1rem", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: ".25rem",
            borderBottom: "1px solid #dbc6c6",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#ffffff", fontWeight: "700" }}>
              {type === "recent_invoices_overdue" && `Overdue Invoice`}
              {type === "recent_payments_overdue" && `Overdue Payment`}
              {type === "recent_quotes" && `Quote`}
              {type === "recent_invoices" && `Invoice`}
            </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontWeight: "700" }}>
                  {data?.student_name}
                </Typography>
              </Box> */}
          {type === "recent_invoices" && (
            <Typography variant="caption">{data?.created_at_date}</Typography>
          )}
          {type === "recent_quotes" && (
            <Typography variant="caption">{data?.created_at}</Typography>
          )}
          {type === "recent_payments_overdue" && (
            <Typography variant="caption">{data?.due_date}</Typography>
          )}
          {type === "recent_invoices_overdue" && (
            <Typography variant="caption">{data?.due_date}</Typography>
          )}
        </Box>
        <Box
          sx={{
            marginTop: ".5rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PersonIcon sx={{ color: "#ffffff" }} />
              {data?.student_name}
            </Typography>
            {type === "recent_invoices" && (
              <Typography>{paraFormat(data?.price)}</Typography>
            )}
            {type === "recent_payments_overdue" && (
              <Typography>{paraFormat(data?.outstanding_amount)}</Typography>
            )}
            {type === "recent_invoices_overdue" && (
              <Typography>{paraFormat(data?.outstanding)}</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: ".5rem",
              marginTop: ".5rem",
              borderTop: "1px solid #dbc6c6",
            }}
          >
            {type === "recent_invoices" && (
              <Box
                sx={{
                  marginTop: ".5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="caption">
                  Invoide ID: {data?.invoice_id}
                </Typography>
                {data?.status ? (
                  <Typography sx={{ fontWeight: "700" }} variant="caption">
                    {stringTransformation(data?.status)}
                  </Typography>
                ) : null}
              </Box>
            )}
            {type === "recent_quotes" && (
              <Box
                sx={{
                  marginTop: ".5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="caption">
                  Quote ID: {data?.quote_id}
                </Typography>
                {data?.quote_status ? (
                  <Typography sx={{ fontWeight: "700" }} variant="caption">
                    {stringTransformation(data?.quote_status)}
                  </Typography>
                ) : null}
              </Box>
            )}
            {type === "recent_invoices_overdue" && (
              <Box
                sx={{
                  marginTop: ".5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="caption">
                  Invoice ID: {data?.invoice_id}
                </Typography>
                {data?.overdue_days ? (
                  <Typography sx={{ fontWeight: "700" }} variant="caption">
                    {data?.overdue_days}{" "}
                    {data?.overdue_days > 1 ? "Days" : "Day"}
                  </Typography>
                ) : null}
              </Box>
            )}
            {type === "recent_payments_overdue" && (
              <Box
                sx={{
                  marginTop: ".5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="caption">
                    Payment ID: {data?.payment_id}
                  </Typography>
                  <Typography variant="caption">
                    Invoice ID: {data?.invoice_id}
                  </Typography>
                </Box>
                {data?.overdue_days ? (
                  <Typography sx={{ fontWeight: "700" }} variant="caption">
                    {data?.overdue_days}{" "}
                    {data?.overdue_days > 1 ? "Days" : "Day"}
                  </Typography>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Cube = ({ recentActivities }) => {
  return (
    <>
      {Object.keys(recentActivities)?.length ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            {recentActivities?.recent_invoices &&
            recentActivities?.recent_invoices?.length ? (
              // <Swiper
              //   style={{ minHeight: "250px", padding: "2rem" }}
              //   effect={"cards"}
              //   grabCursor={true}
              //   modules={[EffectCards]}
              //   className="recent-activities"
              // >
              //   {recentActivities?.recent_invoices?.map(
              //     (recent_invoices, idx) => {
              //       return (
              //         <SwiperSlide
              //           style={{
              //             display: "flex",
              //             background: "f5e7e7",
              //             alignItems: "center",
              //             justifyContent: "center",
              //   borderRadius: "2rem",
              //             boxShadow: "0 0 5px #090909",
              //             fontSize: "22px",
              //             fontWeight: "bold",
              //             color: "#fff",
              //             background:
              //               "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(193,0,0,1) 100%)",
              //           }}
              //         >
              //           <RecentActivityItem
              //             type={"recent_invoices"}
              //             idx={idx}
              //             data={recent_invoices}
              //           />
              //         </SwiperSlide>
              //       );
              //     }
              //   )}
              // </Swiper>
              <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={true}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[EffectCube, Pagination, Autoplay, Navigation]}
                className="recent-activities"
                style={{ minHeight: "250px", padding: "2rem" }}
              >
                {recentActivities?.recent_invoices?.map(
                  (recent_invoices, idx) => {
                    return (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          // "#bf0000",
                          // "#d32f2f",
                          // "#f44336",
                          // "#e57373",
                          // "#ef9a9a",
                          // "#ffcdd2",
                          // "#ffebee",
                          background: "#800000",
                          alignItems: "center",
                          justifyContent: "center",
                          //   borderRadius: "2rem",
                          boxShadow: "0 0 5px #090909",
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: "#fff",
                          // "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(193,0,0,1) 100%)",
                        }}
                      >
                        <RecentActivityItem
                          type={"recent_invoices"}
                          idx={idx}
                          data={recent_invoices}
                        />
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            {recentActivities?.recent_quotes &&
            recentActivities?.recent_quotes?.length ? (
              // <Swiper
              //   style={{ minHeight: "250px", padding: "2rem" }}
              //   effect={"cards"}
              //   grabCursor={true}
              //   modules={[EffectCards]}
              //   className="recent-activities"
              // >
              //   {recentActivities?.recent_quotes?.map(
              //     (recent_quotes, idx) => {
              //       return (
              //         <SwiperSlide
              //           style={{
              //             display: "flex",
              //             background: "f5e7e7",
              //             alignItems: "center",
              //             justifyContent: "center",
              //   borderRadius: "2rem",
              //             boxShadow: "0 0 5px #090909",
              //             fontSize: "22px",
              //             fontWeight: "bold",
              //             color: "#fff",
              //             background:
              //               "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(0,181,225,1) 100%)",
              //           }}
              //         >
              //           {" "}
              //           <RecentActivityItem
              //             type={"recent_quotes"}
              //             idx={idx}
              //             data={recent_quotes}
              //           />
              //         </SwiperSlide>
              //       );
              //     }
              //   )}
              // </Swiper>
              <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={true}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[EffectCube, Pagination, Autoplay, Navigation]}
                className="recent-activities"
                style={{ minHeight: "250px", padding: "2rem" }}
              >
                {recentActivities?.recent_quotes?.map((recent_quotes, idx) => {
                  return (
                    <SwiperSlide
                      style={{
                        display: "flex",
                        background: "#bf0000",
                        alignItems: "center",
                        justifyContent: "center",
                        // borderRadius: "2rem",
                        boxShadow: "0 0 5px #090909",
                        fontSize: "22px",
                        fontWeight: "bold",
                        color: "#fff",
                        //   "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(0,181,225,1) 100%)",
                      }}
                    >
                      {" "}
                      <RecentActivityItem
                        type={"recent_quotes"}
                        idx={idx}
                        data={recent_quotes}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            {recentActivities?.recent_payments_overdue &&
            recentActivities?.recent_payments_overdue?.length ? (
              // <Swiper
              //   style={{ minHeight: "250px", padding: "2rem" }}
              //   effect={"cards"}
              //   grabCursor={true}
              //   modules={[EffectCards]}
              //   className="recent-activities"
              // >
              //   {recentActivities?.recent_payments_overdue?.map(
              //     (recent_payments_overdue, idx) => {
              //       return (
              //         <SwiperSlide
              //           style={{
              //             display: "flex",
              //             background: "f5e7e7",
              //             alignItems: "center",
              //             justifyContent: "center",
              //   borderRadius: "2rem",
              //             boxShadow: "0 0 5px #090909",
              //             fontSize: "22px",
              //             fontWeight: "bold",
              //             color: "#fff",
              //             background:
              //               "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(81,49,120,1) 100%)",
              //           }}
              //         >
              //           {" "}
              //           <RecentActivityItem
              //             type={"recent_payments_overdue"}
              //             idx={idx}
              //             data={recent_payments_overdue}
              //           />
              //         </SwiperSlide>
              //       );
              //     }
              //   )}
              // </Swiper>
              <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={true}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[EffectCube, Pagination, Autoplay, Navigation]}
                className="recent-activities"
                style={{ minHeight: "250px", padding: "2rem" }}
              >
                {recentActivities?.recent_payments_overdue?.map(
                  (recent_payments_overdue, idx) => {
                    return (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          background: "#d32f2f",
                          alignItems: "center",
                          justifyContent: "center",
                          //   borderRadius: "2rem",
                          boxShadow: "0 0 5px #090909",
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: "#fff",
                          // "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(81,49,120,1) 100%)",
                        }}
                      >
                        {" "}
                        <RecentActivityItem
                          type={"recent_payments_overdue"}
                          idx={idx}
                          data={recent_payments_overdue}
                        />
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            {recentActivities?.recent_invoices_overdue &&
            recentActivities?.recent_invoices_overdue?.length ? (
              // <Swiper
              //   style={{ minHeight: "250px", padding: "2rem" }}
              //   effect={"cards"}
              //   grabCursor={true}
              //   modules={[EffectCards]}
              //   className="recent-activities"
              // >
              //   {recentActivities?.recent_invoices_overdue?.map(
              //     (recent_invoices_overdue, idx) => {
              //       return (
              //         <SwiperSlide
              //           style={{
              //             display: "flex",
              //             background: "f5e7e7",
              //             alignItems: "center",
              //             justifyContent: "center",
              //   borderRadius: "2rem",
              //             boxShadow: "0 0 5px #090909",
              //             fontSize: "22px",
              //             fontWeight: "bold",
              //             color: "#fff",
              //             background:
              //               "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(245,126,54,1) 100%)",
              //           }}
              //         >
              //           {" "}
              //           <RecentActivityItem
              //             type={"recent_invoices_overdue"}
              //             idx={idx}
              //             data={recent_invoices_overdue}
              //           />
              //         </SwiperSlide>
              //       );
              //     }
              //   )}
              // </Swiper>
              <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={true}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[EffectCube, Pagination, Autoplay, Navigation]}
                className="recent-activities"
                style={{ minHeight: "250px", padding: "2rem" }}
              >
                {recentActivities?.recent_invoices_overdue?.map(
                  (recent_invoices_overdue, idx) => {
                    return (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          background: "#f44336",
                          alignItems: "center",
                          justifyContent: "center",
                          //   borderRadius: "2rem",
                          boxShadow: "0 0 5px #090909",
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: "#fff",
                          // "linear-gradient(90deg, rgba(25,0,0,1) 0%, rgba(245,126,54,1) 100%)",
                        }}
                      >
                        {" "}
                        <RecentActivityItem
                          type={"recent_invoices_overdue"}
                          idx={idx}
                          data={recent_invoices_overdue}
                        />
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Typography>No Data Found</Typography>
      )}
    </>
  );
};

export default Cube;
