import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import CommonForm from "./CommonForm";
import dayjs from "dayjs";
import Rooms from "../../components/RoomFinder/RoomFinderSearch/Rooms";
import RequestOperations from "../../components/RoomFinder/RequestOperations/RequestOperations";
import BreadCrumbs from "../../components/Breadcrumbs";
import InstantBookingComponent from "../../components/RoomFinder/InstantBookingButton";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import axiosInstance from "../../axiosInstance";

export default function RoomFinderSingle() {
  const [data, setData] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkIn = queryParams.get("checkIn");
  const checkOut = queryParams.get("checkOut");
  let host = "https://6031dbc8-08f5-4c4e-82ab-2bde2480c202.mock.pstmn.io";

  useEffect(() => {
    const fetchRooms = async () => {
      const url = `${host}/available_rooms?checkin=${checkIn}&checkout=${checkOut}`;
      try {
        const response = await axiosInstance.get(url);
        setData(response.data);
      } catch (error) {
        console.error("API isteği sırasında hata oluştu:", error);
      }
    };

    if (checkIn && checkOut) {
      fetchRooms();
    }
  }, [checkIn, checkOut]);

  const initialFormValues = {
    provider: queryParams.get("provider"),
    residence: queryParams.get("residence"),
    occupancy: queryParams.get("occupancy"),
    type: queryParams.get("type"),
    currency: queryParams.get("currency"),
    numberOfStudents: queryParams.get("numberOfStudents"),
    additionalNote: queryParams.get("additionalNote"),
    checkIn: checkIn ? dayjs(checkIn) : null,
    checkOut: checkOut ? dayjs(checkOut) : null,
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Typography className="title" variant="h4">
        Room Finder
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs
        routs={["Room Finder", " Room Finder Details"]}
        component={<InstantBookingComponent />}
      />
      {/* Datalarınızı burada görüntüleyebilirsiniz. Örnek olarak: */}
      <CommonForm formValues={formValues} handleChange={handleChange} />
      <Box sx={{ paddingBottom: "6rem" }}>
        {data && data.map((room, idx) => <Rooms room={room} idx={idx} />)}
      </Box>
      <RequestOperations />
    </>
  );
}
