import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Button, CircularProgress, Container } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import StepFirst from "./StepFirst/StepFirst";
import StepSecond from "./StepSecond/StepSecond";
import AirportServicesContext from "../../../../contexts/AirportServices/AirportServices";
import StepThird from "./StepThird/StepThird";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import CarRentalIcon from "@mui/icons-material/CarRental";
import PersonIcon from '@mui/icons-material/Person';

export function NextButton({ activeStep, steps, handleNext }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleNext}
      sx={{ width: "100%", height: "56px" }}
    >
      {activeStep === steps.length - 1
        ? "Pay and Complete"
        : activeStep === steps.length
        ? "Pay and Complete"
        : "Submit"}
    </Button>
  );
}

export function BackButton({ activeStep, handleBack, buttonType }) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    activeStep !== 0 &&
    (buttonType === "button" ? (
      <Button
        sx={{ width: "100%", height: "56px" }}
        variant="contained"
        color="inherit"
        onClick={handleBack}
      >
        Back
      </Button>
    ) : (
      <Button onClick={handleBack}>
        <ArrowBackIcon sx={{ color: lightMode ? "#bf0000" : "#ffffff" }} /> Back
      </Button>
    ))
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #bf0000 0%, #800000 50%, #600000 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LocalAirportIcon />,
    2: <CarRentalIcon />,
    3: <PersonIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Choose Destination", "Choose Car", "Passenger Details"];

export default function InstantBooking() {
  const [loader, setLoader] = React.useState(false);
  const { activeStep, setActiveStep, bookingDetails } = React.useContext(
    AirportServicesContext
  );

  const stepContents = [<StepFirst />, <StepSecond />, <StepThird />];

  return loader ? (
    <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
  ) : (
    <>
      <Container
        sx={{
          width: { xs: "100%", md: "70vw", lg: "54vw" },
          padding: "0 !important",
        }}
        maxWidth={false}
      >
        {activeStep !== 0 ? (
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, idx) => (
                <Step
                  key={label}
                  onClick={() => {
                    if (
                      label === "Passenger Details" &&
                      bookingDetails?.car_id
                    ) {
                      setActiveStep(idx);
                    } else if (
                      label === "Choose Car" &&
                      bookingDetails?.service_id
                    ) {
                      setActiveStep(idx);
                    } else if (label === "Choose Destination") {
                      setActiveStep(idx);
                    }
                  }}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        ) : null}
      </Container>
      {stepContents[activeStep]}
    </>
  );
}
