import { Box } from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from "react";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function DealMap({ deal }) {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);

  const [lng, setLng] = useState(deal?.campus_coordinates?.lng);
  const [lat, setLat] = useState(deal?.campus_coordinates?.lat);
  const [zoom, setZoom] = useState(17);
  const markersRef = useRef({}); // Marker'lar için bir ref objesi oluştur

  useEffect(() => {
    if (deal) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/outdoors-v12",
        center: [lng, lat],
        zoom: zoom,
        minZoom: 7,
      });

      mapRef.current.dragPan.enable();
      mapRef.current.touchZoomRotate.enable();

      mapRef.current.on("move", () => {
        setLng(mapRef.current.getCenter().lng?.toFixed(4));
        setLat(mapRef.current.getCenter().lat?.toFixed(4));
        setZoom(mapRef.current.getZoom().toFixed(4));
      });

      const marker = new mapboxgl.Marker({ color: "#bf0000" })
        .setLngLat([parseFloat(lng), parseFloat(lat)])
        .addTo(mapRef.current);
      markersRef.current["selected"] = marker;

      const handleResizeAndOrientationChange = () => {
        setTimeout(() => {
          mapRef.current.resize();
        }, 200); // 200ms gecikme ile resize metodunu çağırıyoruz.
      };

      window.addEventListener("resize", handleResizeAndOrientationChange);
      window.addEventListener(
        "orientationchange",
        handleResizeAndOrientationChange
      );

      // Component temizlendiğinde (unmount) olay dinleyicilerini kaldır
      return () => {
        window.removeEventListener("resize", handleResizeAndOrientationChange);
        window.removeEventListener(
          "orientationchange",
          handleResizeAndOrientationChange
        );
      };
    }
  }, [deal]);

  return (
    <Box
      ref={mapContainer}
      className="map-container"
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: ".25rem",
        borderBottomLeftRadius: { xs: "0", md: ".25rem" },
        borderBottomRightRadius: { xs: "0", md: ".25rem" },
        overflow: "hidden",
        position: "relative",
      }}
    ></Box>
  );
}

export default DealMap;
