import { SET_SEARCHTERM } from "../actions/actionTypes";

const initialState = {
  searchTerm: {},
};

const searchTermReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCHTERM:
      // URL'den filtreler yüklenmediyse, filtreleme işlemini yap
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};

export default searchTermReducer;
