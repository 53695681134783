import { Box, Typography } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";

export default function ComingSoonModal() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <CampaignIcon sx={{ fontSize: "72px", color: '#bf0000' }} />
      </Box>
      <Typography variant="h5">Coming Soon!</Typography>
    </Box>
  );
}
