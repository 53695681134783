import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../Breadcrumbs";
import StyledTitleDot from "../../common/StyledTitleDot";
import RadioButtons from "./Steps/StepFirst/SelectArea/RadioButtons";
import SelectMenus from "./Steps/StepFirst/SelectArea/SelectMenus";
import AirportServicesContext, {
  AirportServicesProvider,
} from "../../../contexts/AirportServices/AirportServices";
import { useContext } from "react";
import SelectArea from "./Steps/StepFirst/SelectArea/SelectArea";
import StepFirst from "./Steps/StepFirst/StepFirst";
import Steps from "./Steps/Steps";

export default function AirportServices() {
  return (
    <AirportServicesProvider>
      <Typography className="title" variant="h4">
        Services
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Services", "Airport Transfer", "Details"]} />

      <Steps />
    </AirportServicesProvider>
  );
}
