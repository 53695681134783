// import axios from "axios";
// import { showNotification } from "./redux/actions/notificationAction";
// import { store } from "./redux/store/store";
// import Swal from "sweetalert2";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });

// // Response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => {
//     // İstek başarılı olduğunda çalışır
//     return response;
//   },
//   (error) => {
//     // İstek hata ile sonuçlandığında çalışır

//     console.log(error, "instance errro here");
//     if (error.response.status === 401) {
//       const message = "Please Log In Again";
//       Swal.fire({
//         icon: "error",
//         title: message,
//         showConfirmButton: false,
//         showCloseButton: true,
//         willClose: () => {
//           localStorage.removeItem("profile");
//           localStorage.removeItem("agency");
//           localStorage.removeItem("branches-string");
//           localStorage.removeItem("branches");
//           localStorage.removeItem("accessToken");
//           localStorage.removeItem("loginUser");
//           window.location.reload();
//         },
//       });
//     }
//     else if (
//       error.response &&
//       error.response.data &&
//       error.response.data.notification
//     ) {
//       const message = error.response.data.message || "";
//       // store.dispatch(showNotification(message)); // Hata bildirimi göster
//       Swal.fire({
//         icon: "error",
//         title: message,
//         showConfirmButton: false,
//         showCloseButton: true,
//       });
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import { showNotification } from "./redux/actions/notificationAction";
import { store } from "./redux/store/store";
import Swal from "sweetalert2";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  if (config.url && config.url.includes("overpass-api.de")) {
    config.baseURL = ""; // baseURL'yi boş yaparak dahil etmemek
  }
  return config;
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // İstek başarılı olduğunda çalışır
    return response;
  },
  (error) => {
    // İstek hata ile sonuçlandığında çalışır
    console.log(error, "instance error here");
    if (error.response.status === 401) {
      const message = "Please Log In Again";
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        showCloseButton: true,
        willClose: () => {
          localStorage.removeItem("profile");
          localStorage.removeItem("agency");
          localStorage.removeItem("branches-string");
          localStorage.removeItem("branches");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("loginUser");
          window.location.reload();
        },
      });
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.notification
    ) {
      const message = error.response.data.message || "";
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
