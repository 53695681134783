import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axiosInstance from "../../../axiosInstance";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function StartQuoteComp({ quoteDetails }) {
  const [formValues, setFormValues] = useState(quoteDetails);
  const [coursesControl, setCoursesControl] = useState(false);
  const [loader, setLoader] = useState(false);
  const lightMode = useSelector((state) => state.mode.lightMode);
  const navigate = useNavigate();

  console.log(quoteDetails, "quote wdetails");
  const handleChange = (eventOrValue, name) => {
    if (dayjs.isDayjs(eventOrValue)) {
      // Eğer eventOrValue bir dayjs nesnesi ise (DatePicker'dan gelme durumu)
      setFormValues((prev) => ({
        ...prev,
        [name]: dayjs(eventOrValue).format("YYYY-MM-DD"), // newValue direkt olarak kullanılıyor ve formatlanıyor
      }));
    } else {
      const { name, value } = eventOrValue.target;
      // Diğer inputlar için standart işleme
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [students, setStudents] = useState();
  const [selectedStudent, setSelectedStudent] = useState();

  const handleChangeStudent = (_event, newValue) => {
    setSelectedStudent(newValue);
  };

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const handleChangeCourseControl = () => {
    setCoursesControl(!coursesControl);
  };

  const getStudents = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/students`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      setStudents(response?.data?.student);
    } catch (error) {
      console.error("Hata oluştu!", error);
      return [];
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    console.log(formValues, "form values here 6161");
    if (!formValues?.expiry_date) {
      setFormValues((prev) => ({
        ...prev,
        expiry_date: dayjs().format("YYYY-MM-DD"), // newValue direkt olarak kullanılıyor ve formatlanıyor
      }));
    }
  }, [formValues]);

  useEffect(() => {
    if (selectedStudent) {
      setFormValues((prev) => ({
        ...prev,
        student_id: selectedStudent?.student_id,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        student_id: "",
      }));
    }
  }, [selectedStudent]);

  const handleAddQuote = async () => {
    setLoader(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    let url = `${process.env.REACT_APP_BASE_URL}quotes`;

    // Önce formValues'dan 'price' değerlerini kaldır
    const modifiedFormValues = {
      ...formValues,
      options: formValues.options.map((option) => ({
        ...option,
        accommodations: option.accommodations.map((accommodation) => ({
          ...accommodation,
          courses: accommodation.courses.map((course) => {
            const { price, campus_address, ...restOfCourse } = course; // 'price' değerini çıkart
            return restOfCourse;
          }),
        })),
      })),
    };

    try {
      const response = await axiosInstance.post(url, modifiedFormValues, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      console.log(response.data, "Quote Eklendi!");
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Quote Added Successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      navigate(`/quotes/${response?.data?.quote_id}/details`);
    } catch (error) {
      setLoader(false);
      console.error("Quote Eklenirken Hata oluştu!", error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.detail,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return [];
    }
  };
  return (
    <>
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null}
      <Box sx={{ marginTop: { xs: "2rem" } }}>
        <Box>
          {/* <Box
            sx={{
              marginBottom: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button variant="contained">
              {" "}
              <PreviewIcon sx={{ color: "#ffffff" }} /> Preview
            </Button>
            <Typography sx={{ fontWeight: "700" }}>
              Quote ID: #{quoteDetails?.quote?.quote_id}
            </Typography>
          </Box> */}
          <Box
            sx={{
              boxShadow: "0px 0px 5px #cecece",
              padding: "1.5rem 1rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    value={selectedStudent}
                    onChange={handleChangeStudent}
                    disablePortal
                    id="combo-box-demo"
                    options={students || []}
                    disabled={!students?.length}
                    getOptionLabel={(option) => option.student_name} // 'name' özelliğini label olarak kullan
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        placeholder="Select Student"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Expiry Date"
                    name="issue_date"
                    value={
                      formValues?.issue_date
                        ? dayjs(formValues.issue_date)
                        : dayjs()
                    }
                    onChange={(newValue) =>
                      handleChange(newValue, "issue_date")
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Expiry Date"
                    name="expiry_date"
                    value={
                      formValues?.expiry_date
                        ? dayjs(formValues.expiry_date)
                        : dayjs()
                    }
                    onChange={(newValue) =>
                      handleChange(newValue, "expiry_date")
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Accepted At"
                    value={dayjs(quoteDetails?.quote?.accepted_at)}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={quoteDetails?.quote?.quote_status
                    ?.split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  id="outlined-basic"
                  label="Quote Status"
                  name="quote_status"
                  variant="outlined"
                  type="text"
                />
              </Grid> */}
            </Grid>
            <Button
              sx={{
                marginTop: "1rem",
              }}
              disabled={!formValues?.expiry_date || !formValues?.student_id}
              variant="contained"
              color="primary"
              onClick={() =>
                formValues?.expiry_date &&
                formValues?.student_id &&
                handleAddQuote()
              }
            >
              Save
            </Button>
          </Box>
          <>
            {quoteDetails?.options?.map((option) => {
              return (
                <Box
                  sx={{
                    marginTop: "2rem",
                    boxShadow: "0px 0px 5px #cecece",
                    padding: "1.5rem 1rem",
                    // background:
                    //   quoteDetails?.quote?.selected_option_id ===
                    //   option?.option_id
                    //     ? lightMode
                    //       ? "#44b8001c"
                    //       : "#112d00"
                    //     : lightMode
                    //     ? "#ffffff"
                    //     : "#292929",
                    background: lightMode ? "#ffffff" : "#292929",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #efefef",
                      paddingBottom: "1rem",
                    }}
                  >
                    <FormatListBulletedIcon sx={{ marginRight: ".5rem" }} />
                    <Typography sx={{ fontWeight: "700" }}>
                      {option?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        width: { xs: "100%", md: "300px", lg: "400px" },
                        marginRight: { xs: "0", md: "2rem" },
                        marginBottom: { xs: "1.5rem", md: "0" },
                      }}
                    >
                      {/* <Box
                        sx={{
                          backgroundImage: option?.courses[0]?.course?.images
                            ?.length
                            ? `url(${option?.courses[0]?.course?.images[0]})`
                            : "unset",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left",
                          width: { xs: "100%", md: "400px" },
                          height: { xs: "300px", md: "100%" },
                          maxHeight: "400px",
                          filter: "blur(8px)",
                        }}
                      ></Box> */}
                      {/* <img
                        src={
                          option?.courses[0]?.course?.images?.length
                            ? option?.courses[0]?.course?.images[0]
                            : "javascript:;"
                        }
                        style={{
                          position: "absolute",
                          inset: "0",
                          width: "90%",
                          left: "5%",
                          borderRadius: "1rem",
                          height: "100%",
                          maxHeight: "400px",
                          objectFit: "contain",
                        }}
                      /> */}
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          md: "calc(100% - 332px)",
                          lg: "calc(100% - 432px)",
                        },
                      }}
                    >
                      {/* COURSES START */}
                      {option?.accommodations[0]?.courses?.map(
                        (course, idx) => {
                          return option?.accommodations[0]?.courses?.length >
                            1 && idx >= 1 ? (
                            coursesControl ? (
                              <>
                                <Box
                                  sx={{
                                    padding: "1rem 0",
                                    boxShadow: "0px 0px 3px #cfcfcf",
                                    padding: "1rem",
                                    marginBottom: "1.5rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderBottom: "1px solid #efefef",
                                      paddingBottom: "1rem",
                                    }}
                                  >
                                    <Typography color="primary" variant="h6">
                                      <strong>
                                        {course?.option_course_partner}
                                      </strong>{" "}
                                      - {course?.option_course_campus}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <LocationOnIcon
                                        sx={{
                                          fontSize: "14px",
                                          color: "#bf0000",
                                        }}
                                      />
                                      <Typography variant="body2">
                                        {course?.campus_address}
                                      </Typography>
                                    </Box>
                                    {/* <Box sx={{ display: "flex" }}>
                                    <Typography
                                      color="primary"
                                      variant="body2"
                                      sx={{ marginRight: ".5rem" }}
                                    >
                                      Academic Year:
                                    </Typography>
                                    <Typography variant="body2">
                                      {option?.academic_year}
                                    </Typography>
                                  </Box> */}
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "1rem",
                                      // borderBottom: "1px solid #efefef",
                                      // paddingBottom: "1rem",
                                    }}
                                  >
                                    <Typography color="primary" variant="h6">
                                      Accommodation/s
                                    </Typography>
                                    <Typography variant="body2">
                                      {course?.option_course_name}
                                    </Typography>
                                    <Typography variant="body2">
                                      {option?.duration}
                                    </Typography>

                                    <Typography variant="body2">
                                      {paraFormat(course?.price)} / Week
                                    </Typography>
                                    <Typography sx={{ fontWeight: "700" }}>
                                      {paraFormat(
                                        course?.option_course_total_price
                                      )}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "1rem",
                                        borderBottom: "1px solid #efefef",
                                        paddingBottom: "1rem",
                                      }}
                                    >
                                      {/* <Typography variant="body1">
                                        Subtotal
                                      </Typography> */}
                                      <Typography variant="h6">
                                        $
                                        {
                                          course?.option_course_gross_subtotal_price
                                        }
                                      </Typography>
                                    </Box>
                                    {course?.promotion?.map((promotion) => {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: {
                                              xs: "column",
                                              sm: "row",
                                            },
                                            justifyContent: "space-between",
                                            background: "#44b8001c",
                                            padding: "1rem",
                                            marginTop: "1rem",
                                            borderRadius: ".25rem",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              maxWidth: {
                                                xs: "100%",
                                                sm: "50%",
                                              },
                                              marginRight: {
                                                xs: "1rem",
                                                sm: "0",
                                              },
                                              marginBottom: {
                                                xs: "1rem",
                                                sm: "0",
                                              },
                                            }}
                                          >
                                            {promotion?.promotion_name}
                                          </Typography>
                                          <Typography>
                                            -{" "}
                                            {paraFormat(
                                              promotion?.promotion_amount
                                            )}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                </Box>
                              </>
                            ) : null
                          ) : (
                            <>
                              <Box
                                sx={{
                                  boxShadow: "0px 0px 3px #cfcfcf",
                                  padding: "1rem",
                                  marginBottom: "1.5rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderBottom: "1px solid #efefef",
                                    paddingBottom: "1rem",
                                  }}
                                >
                                  <Typography color="primary" variant="h6">
                                    <strong>
                                      {course?.option_course_partner}
                                    </strong>{" "}
                                    - {course?.option_course_campus}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LocationOnIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: "#bf0000",
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {course?.campus_address}
                                    </Typography>
                                  </Box>
                                  {/* <Box sx={{ display: "flex" }}>
                                  <Typography
                                    color="primary"
                                    variant="body2"
                                    sx={{ marginRight: ".5rem" }}
                                  >
                                    Academic Year:
                                  </Typography>
                                  <Typography variant="body2">
                                    {option?.academic_year}
                                  </Typography>
                                </Box> */}
                                  <Typography variant="body2">
                                    {course?.option_course_start_date} -{" "}
                                    {course?.option_course_end_date} (
                                    {course?.option_course_duration}{" "}
                                    {course?.option_course_duration > 1
                                      ? `day` + "s"
                                      : `day`}{" "}
                                    )
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: "1rem",
                                    // paddingBottom: "1rem",
                                  }}
                                >
                                  <Typography color="primary" variant="h6">
                                    Accommodation/s
                                  </Typography>
                                  <Typography variant="body2">
                                    {course?.option_course_name}
                                  </Typography>
                                  <Typography variant="body2">
                                    {option?.duration}
                                  </Typography>
                                  <Typography variant="body2">
                                    {paraFormat(course?.price)} / Week
                                  </Typography>
                                  <Typography sx={{ fontWeight: "700" }}>
                                    {paraFormat(
                                      course?.option_course_total_price
                                    )}
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "1rem",
                                      // borderBottom: "1px solid #efefef",
                                      // paddingBottom: "1rem",
                                    }}
                                  >
                                    {/* <Typography variant="body1">
                                      Subtotal
                                    </Typography> */}
                                    <Typography variant="h6">
                                      $
                                      {
                                        option?.accommodations[0]?.courses[0]
                                          ?.option_course_gross_subtotal_price
                                      }
                                    </Typography>
                                  </Box>
                                  {course?.promotion?.map((promotion) => {
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: {
                                            xs: "column",
                                            sm: "row",
                                          },
                                          justifyContent: "space-between",
                                          background: "#44b8001c",
                                          padding: "1rem",
                                          marginTop: "1rem",
                                          borderRadius: ".25rem",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            maxWidth: {
                                              xs: "100%",
                                              sm: "50%",
                                            },
                                            marginRight: {
                                              xs: "1rem",
                                              sm: "0",
                                            },
                                            marginBottom: {
                                              xs: "1rem",
                                              sm: "0",
                                            },
                                          }}
                                        >
                                          {promotion?.promotion_name}
                                        </Typography>
                                        <Typography>
                                          -{" "}
                                          {paraFormat(
                                            promotion?.promotion_amount
                                          )}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </>
                          );
                        }
                      )}
                      {option?.courses?.length > 1 ? (
                        coursesControl ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleChangeCourseControl()}
                          >
                            Hide
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleChangeCourseControl()}
                          >
                            See All Courses
                          </Button>
                        )
                      ) : null}

                      {/* COURSES END */}

                      {option?.services?.map((service) => {
                        return (
                          <Box
                            sx={{
                              marginTop: "1rem",
                              borderBottom: "1px solid #efefef",
                              paddingBottom: "1rem",
                            }}
                          >
                            <Typography color="primary" variant="h6">
                              Fee/s
                            </Typography>
                            {option?.services?.map((service, idx) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography variant="body2">
                                    {service?.option_service_name}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ marginRight: ".5rem" }}
                                    >
                                      +VAT (
                                      {paraFormat(service?.option_service_vat)})
                                    </Typography>
                                    <Typography variant="body2">
                                      {paraFormat(
                                        service?.option_service_price
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid  #efefef",
                          paddingBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h6">Sub Total:</Typography>
                        <Typography variant="h6">
                          ${option?.subtotal}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h5" color="primary">
                          Total:
                        </Typography>
                        <Typography variant="h5" color="primary">
                          ${option?.total}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </>
        </Box>
      </Box>
    </>
  );
}
