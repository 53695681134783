// AirportServicesContext.js

import React, { createContext, useState } from "react";

const BranchesListContext = createContext();

export const BranchesListProvider = ({ children }) => {
  const [branches, setBranches] = useState([]);

  // Context'e sağlanacak değerler
  const contextValue = {
    branches,
    setBranches,
  };

  return (
    <BranchesListContext.Provider value={contextValue}>
      {children}
    </BranchesListContext.Provider>
  );
};

export default BranchesListContext;
