import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";

export default function QuotesAccordion({ quoteDetails }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{
              width: "33%",
              flexShrink: 0,
            }}
          >
            Quotes
          </Typography>
        </AccordionSummary>
        {quoteDetails?.map((quoteDetail) => {
          return (
            <AccordionDetails
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #efefef",
                padding: "1rem",
                borderTop: "1px solid #efefef",
              }}
            >
              <Typography>#{quoteDetail?.pk}</Typography>
              <Button
                href={quoteDetail?.url}
                variant="contained"
                color="primary"
              >
                Preview
              </Button>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </div>
  );
}
