// src/components/Notification.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectNotification } from "../redux/selectors/notificationSelector";
import { hideNotification } from "../redux/actions/notificationAction";
import { Alert, Stack } from "@mui/material";

const Notification = () => {
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();

  if (!notification.show) {
    return null;
  }

  const handleClose = () => {
    dispatch(hideNotification());
  };

  setTimeout(handleClose, 5000);

  return (
    <Stack
      sx={{
        width: "fit-content",
        position: "fixed",
        right: "0",
        bottom: "20px",
      }}
      spacing={2}
    >
      <Alert sx={{textTransform: 'uppercase'}} severity="error" onClose={handleClose}>
        {notification.message}
      </Alert>
    </Stack>
    // <div className="notification">
    //   {notification.message}
    //   <button onClick={handleClose}>Kapat</button>
    // </div>
  );
};

export default Notification;
