import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import axiosInstance from "../../../axiosInstance";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";
import { pieArcLabelClasses } from "@mui/x-charts";
import { light } from "@mui/material/styles/createPalette";

export default function ApexChart2() {
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);
  const [chartDataType, setChartDataType] = useState("daily");
  const [loading, setLoading] = useState(true);
  const [completedData, setCompletedData] = useState();

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const theme = useTheme();
  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));

  const getSalesData = async () => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/top_booked?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            Accounts: selectedBranchesString,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      const data = response.data.most_booked_residences;
      const processedData = Object.entries(data).map(([label, value]) => ({
        label,
        value,
      }));

      let labels;
      let series;

      if (processedData.length) {
        labels = processedData.map((item) => item.label);
        series = processedData.map((item) => item.value);
      } else {
        labels = ["No Data Available!"];
        series = [0];
      }

      setCompletedData(processedData);
      setChartData((current) => ({
        ...current,
        series: series,
        options: {
          ...current.options,
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#111"],
            },
            background: {
              enabled: lightMode ? true : false,
              foreColor: "#fff",
              borderWidth: 0,
            },
          },
          labels: labels,
          title: {
            text: `${dayjs(startDate).format("YYYY-MM-DD")} / ${dayjs(
              endDate
            ).format("YYYY-MM-DD")}`,
            align: "center",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              color: lightMode ? "#444444" : "#ffffff",
            },
          },
        },
      }));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (selectedBranchesString?.length) {
    //   getSalesData();
    // }
    getSalesData();
  }, [startDate, endDate, selectedBranchesString]);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      labels: [],
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#111"],
        },
        background: {
          enabled: lightMode ? true : false,
          foreColor: "#fff",
          borderWidth: 0,
        },
      },
      legend: {
        position: "bottom", // Varsayılan konum
        horizontalAlign: "center", // Varsayılan hizalama
        labels: {
          colors: lightMode ? ["#444444"] : ["#ffffff"],
          useSeriesColors: false,
        },
      },
      responsive: [
        {
          breakpoint: 480, // Mobil cihazlar için breakpoint
          options: {
            legend: {
              position: "bottom",
              offsetY: 0,
            },
          },
        },
      ],
      chart: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        type: "pie",
      },
      colors: [
        "#bf0000",
        "#d32f2f",
        "#f44336",
        "#e57373",
        "#ef9a9a",
        "#ffcdd2",
        "#ffebee",
      ],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      title: {
        text: `${dayjs(startDate).format("YYYY-MM-DD")} / ${dayjs(
          endDate
        ).format("YYYY-MM-DD")}`,
        align: "center",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          color: lightMode ? "#444444" : "#ffffff",
        },
      },
    },
  });

  useEffect(() => {
    // Mobil cihazlar için legend konfigürasyonunu güncelle
    if (completedData) {
      const colors = new Array(completedData.length).fill(
        lightMode ? "#444444" : "#ffffff"
      );

      setChartData((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          title: {
            text: `${dayjs(startDate).format("YYYY-MM-DD")} / ${dayjs(
              endDate
            ).format("YYYY-MM-DD")}`,
            align: "center",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              color: lightMode ? "#444444" : "#ffffff",
            },
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: colors,
            },
            background: {
              enabled: lightMode ? true : false,
              foreColor: "#fff",
              borderWidth: 0,
            },
          },
          legend: {
            ...prev.options.legend,
            position: isMobileSm ? "bottom" : "right",
            horizontalAlign: isMobileSm ? "center" : "right",
            labels: {
              colors: colors,
              useSeriesColors: false,
            },
          },
        },
      }));
    }
  }, [isMobileSm, completedData]);

  useEffect(() => {
    if (completedData) {
      const colors = new Array(completedData.length).fill(
        lightMode ? "#444444" : "#ffffff"
      );

      // Mobil cihazlar için legend konfigürasyonunu güncelle
      setChartData((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          title: {
            text: `${dayjs(startDate).format("YYYY-MM-DD")} / ${dayjs(
              endDate
            ).format("YYYY-MM-DD")}`,
            align: "center",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              color: lightMode ? "#444444" : "#ffffff",
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: colors,
            },
            background: {
              enabled: lightMode ? true : false,
              foreColor: "#fff",
              borderWidth: 0,
            },
          },
          legend: {
            ...prev.options.legend,
            position: isMobileSm ? "bottom" : "right",
            horizontalAlign: isMobileSm ? "center" : "right",
            labels: {
              colors: colors,
              useSeriesColors: false,
            },
          },
        },
      }));
    }
  }, [lightMode, completedData]);

  const handleChange = (event) => {
    setChartDataType(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h6" color="primary" sx={{ mb: 1.5 }}>
          Your Top Booked Residences
        </Typography>
        {/* <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
                maxDate={endDate}
              />
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginLeft: { xs: "0", md: "1rem" },
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) => <TextField {...params} />}
                minDate={startDate}
              />
            </LocalizationProvider>
          </Box>
        </Box> */}
      </Box>
      {loading ? (
        <CircularProgress sx={{ margin: "auto" }} />
      ) : (
        <Box id="chart" sx={{ mt: "auto", mb: "auto" }}>
          <ReactApexChart
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
              },
            }}
            options={chartData.options}
            series={chartData.series}
            type="pie"
            height={350}
          />
        </Box>
      )}
    </Box>
  );
}
