import React, { useEffect, useState } from "react";
import { locationDatas } from "./LocationDatas";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../axiosInstance";
import { Box, Grid } from "@mui/material";

export default function Locations({ moveToLocation, locationDatas }) {
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleChange = (event, location) => {
    setSelectedLocation(location?.campus_name);
    moveToLocation(
      location?.campus_coordinates?.lng,
      location?.campus_coordinates?.lat
    );
  };

  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Grid
        container
        spacing={2}
        sx={{
          overflow: "auto",
          maxHeight: "200px",
        }}
      >
        {locationDatas?.map((location, idx) => (
          <Grid item xs={12} sm={6} lg={3} key={idx}>
            <FormControl
              component="fieldset"
              sx={{
                paddingTop: "1rem",
                width: "100%",
              }}
            >
              <FormControlLabel
                key={idx}
                value={location.name}
                control={
                  <Radio
                    checked={selectedLocation === location?.campus_name}
                    onChange={(e) => handleChange(e, location)}
                    name="location-radio"
                    color="primary"
                  />
                }
                label={location?.campus_name}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
