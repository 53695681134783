import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import RadioButtons from "./RadioButtons";
import SelectMenus from "./SelectMenus";
import AirportServicesContext, {
  AirportServicesProvider,
} from "../../../../../../contexts/AirportServices/AirportServices";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../../../../axiosInstance";
import { useSelector } from "react-redux";

export default function SelectArea() {
  const {
    airports,
    setAirports,
    residences,
    setResidences,
    selectedAirport,
    setSelectedAirport,
    selectedResidence,
    setSelectedResidence,
    travelType,
    setTravelType,
  } = useContext(AirportServicesContext);

  const [loading, setLoading] = useState(false);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const customRedPalette = {
    light: "#ff0000", // En açık kırmızı tonu
    main: lightMode ? "#bf0000" : "#ffffff", // Ana kırmızı tonu
    dark: "#800000", // Koyu kırmızı tonu
    extraDark: "#600000", // Koyu kırmızı tonu
    hardDark: "#400000", // Bu kırmızı tonları ile kontrast oluşturacak metin rengi
  };

  const theme = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
      primary: {
        main: customRedPalette.main, // Özelleştirilmiş ana renk
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: "lightgrey",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            color: "#ffffff",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            color: "#ffffff",
          },
          endAdornment: {
            ".MuiSvgIcon-root": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff !important",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused ": {
              color: "#ffffff !important",
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            ".MuiSvgIcon-root": {
              color: "#bf0000 !important",
            },
          },
        },
      },
      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       "&.MuiSelect-icon": {
      //         color: "#ffffff !important",
      //       },
      //       color: "#bf0000",
      //     },
      //   },
      // },
      MuiFormControl: {
        styleOverrides: {
          root: {
            ".MuiFormLabel-root": {
              color: "#ffffff",
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            "&.residence .MuiOutlinedInput-notchedOutline span": {
              paddingRight: "25px",
            },
            "&.airport .MuiOutlinedInput-notchedOutline span": {
              paddingRight: "40px",
            },
            "&.person .MuiOutlinedInput-notchedOutline span": {
              paddingRight: "50px",
            },
          },
          notchedOutline: {
            borderColor: "#ffffff",
            "legend span": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            ".MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium": {
              color: "#ffffff !important",
            },
          },
          colorPrimary: {
            ".MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium": {
              color: "#ffffff !important",
            },
          },
        },
      },
    },
  });

  const getAirports = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}services?category_id=11`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    if (!airports?.length) {
      try {
        const response = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        });

        setAirports(response.data.results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  const getResidences = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}booking/residences`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    if (!residences?.length) {
      try {
        const response = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        });

        setResidences(response.data.residences);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };
  useEffect(() => {
    getAirports();
    getResidences();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <RadioButtons />
        <SelectMenus />
      </Box>
    </ThemeProvider>
  );
}
