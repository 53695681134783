import React, { useContext } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AirportServicesContext from "../../../../../../contexts/AirportServices/AirportServices";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

export default function RadioButtons() {
  const { setTravelType, travelType } = useContext(AirportServicesContext);

  const handleChange = (event) => {
    setTravelType(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="travel-type-radio-buttons-group-label"
        value={travelType}
        onChange={handleChange}
        name="travel-type-radio-buttons-group"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel
          sx={{ color: "#ffffff" }}
          value="Arrival"
          control={
            <Radio
              sx={{
                color: "#ffffff", // Default state color
                "&.Mui-checked": {
                  color: "#ffffff", // Color when radio button is checked
                },
              }}
            />
          }
          label={
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems="flex-start"
            >
              <FlightLandIcon />
              <span style={{ fontSize: "12px" }}>Arrival</span>
            </Box>
          }
        />
        <FormControlLabel
          sx={{ color: "#ffffff" }}
          value="Departure"
          control={
            <Radio
              sx={{
                color: "#ffffff", // Default state color
                "&.Mui-checked": {
                  color: "#ffffff", // Color when radio button is checked
                },
              }}
            />
          }
          label={
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems="flex-start"
            >
              <FlightTakeoffIcon />
              <span style={{ fontSize: "12px" }}>Departure</span>
            </Box>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
