import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import AuthForm from "../components/Authform";
import loginImage from "../assets/images/login.jpg";
import axiosInstance from "../axiosInstance";

//axios.defaults.withCredentials = true

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp({}) {
  const navigate = useNavigate();
  const [verification, setVerification] = React.useState(
    localStorage.getItem("verification") ? true : false
  );
  const [formTitle, setFormTitle] = React.useState("Sign Up");
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [handleError, setHandleError] = React.useState(false);
  const [handleErrorText, setHandleErrorText] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const sampleData = {
        username: data.get("email"),
        password: data.get("password"),
      };

      const response = await axiosInstance.post("/auth/signup", sampleData, {
        withCredentials: true,
        credentials: true,
      });
      setVerification(true);
      setUserName(data.get("email"));
      setPassword(data.get("password"));
    } catch (error) {
      // console.error("Error sending data:", error.response);
      // setHandleError(true);
      // setHandleErrorText(error.response.data.message);
      // setTimeout(() => {
      //   setHandleError(false);
      //   setHandleErrorText("");
      // }, 3000);
    }
  };

  const [responseMessage, setResponseMessage] = React.useState();

  const handleVerification = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const sampleData = {
        username: userName,
        password: password,
        otp: data.get("verification"),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/verify-email`,
        sampleData,
        {
          withCredentials: true,
          credentials: true,
        }
      );
      localStorage.removeItem("verification");
      navigate("/login");
      setResponseMessage(response.data.message);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // React.useEffect(() => {
  //   console.log(responseMessage, "response message!");
  // }, [responseMessage]);

  // const handleVerification = async (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);

  //     console.log(data.get('verification'));

  //     axios({
  //         method: 'post', //you can set what request you want to be
  //         url: 'http://172.16.95.55:8002/auth/verify-email',
  //         data: {
  //             username: 'ahmet.yilmaz.ustaalioglu@gmail.com',
  //             password: 'test123456',
  //             otp: Number(data.get('verification')),
  //         },
  //         headers: {
  //             withCredentials: true,
  //             'Content-Type': 'application/json'
  //         }
  //     }).then(function (response) {
  //         console.log(response);
  //         localStorage.removeItem("verification")
  //         navigate("/login")
  //         loginControl.setLoginControl(true);
  //     })
  //         .catch(function (error) {
  //             console.log(error);
  //         });

  //     // await axios.post('http://172.16.95.55:8002/auth/verify-email', {
  //     //     username: 'ahmet.yilmaz.ustaalioglu@gmail.com',
  //     //     password: 'test123456',
  //     //     otp: Number(data.get('verification')),
  //     // },
  //     //     // {
  //     //     //     headers: {
  //     //     //         withCredentials: true
  //     //     //     }
  //     //     // }
  //     // )

  // };

  React.useEffect(() => {
    if (verification) {
      setFormTitle("Verification");
      localStorage.setItem("verification", true);
    }
  }, [verification]);

  return (
    <AuthForm
      title={verification ? "Verification" : "Sign Up"}
      onSubmit={verification ? handleVerification : handleSubmit}
      imageSrc={loginImage}
      handleError={handleError}
      handleErrorText={handleErrorText}
    >
      {verification ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="text"
              id="verification"
              label="Verify your email"
              name="verification"
              autoComplete="Verify your email"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginBottom: "1rem" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{
                  marginBottom: "1rem",
                  backgroundColor: "#bf0000 !important",
                }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign Up
              </Button>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2" color="#bf0000">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </AuthForm>
  );
}
