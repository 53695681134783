import { OPEN_MODAL, CLOSE_MODAL } from "../actions/actionTypes";

const initialState = {
  modalContent: null,
  contentType: null,
  isOpen: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalContent: action.payload.content,
        contentType: action.payload.contentType,
        isOpen: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalContent: null,
        contentType: null,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
