import { Box, Typography } from "@mui/material";
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "../../styles/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setDecrementQuantity,
  setIncrementQuantity,
  setRemoveFromCart,
} from "../../redux/actions/cartActions";

export default function CartItems({ item, idx, serviceType }) {
  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <Box
      key={idx}
      sx={{
        backgroundColor: lightMode ? "#efefef" : "#242424",
        boxShadow: "0 3px 6px #00000029",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "2rem 1.25rem",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          background: "#ffffff",
          minWidth: "fit-content",
          maxHeight: "100px",
          position: "relative",
          overflow: "hidden",
          marginRight: "1.5rem",
          borderRadius: "1rem",
          filter: "drop-shadow(2px 2px 2px #707070)",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100px",
            height: "100px",
            backgroundImage: `url(${item.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            filter: "blur(10px)",
            zIndex: 0,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100px",
            height: "100px",
            background: "rgba(0, 0, 0, .1)",
            zIndex: 0,
          },
        }}
      >
        <img
          style={{
            maxWidth: "100px",
            height: "100px",
            objectFit: "cover",
            objectPosition: "center",
            position: "relative",
            zIndex: 1,
            boxShadow: "0px 0px 15px rgba(0 0 0)",
          }}
          width={185}
          src={item.image}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: "700" }}
          color={getTheme().palette.secondary.main}
        >
          {item?.service_name}{" "}
          {item?.selectedSize ? `- ` + item?.selectedSize : ``}
        </Typography>
        <Typography
          sx={{ fontSize: "18px", color: lightMode ? "#000000" : "#ffffff" }}
        >
          Price: {item.price}£
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#efefef",
            filter: lightMode && "drop-shadow(2px 2px 2px #707070)",
            width: "fit-content",
            marginTop: ".5rem",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <Box
            onClick={() =>
              // dispatch(decrementQuantity(item.service_id))
              dispatch(
                setDecrementQuantity(
                  item.service_id,
                  serviceType === "products" ? "products" : "services"
                )
              )
            }
            sx={{
              padding: "1rem",
              backgroundColor: "#bf0000",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {item?.quantity == 1 ? (
              <DeleteIcon
                sx={{ color: "#ffffff" }}
                onClick={() =>
                  // dispatch(removeFromCart(item.service_id))
                  dispatch(
                    setRemoveFromCart(
                      item.service_id,
                      serviceType === "products" ? "products" : "services"
                    )
                  )
                }
              />
            ) : (
              `-`
            )}
          </Box>
          <Box
            sx={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#000000",
            }}
          >
            {item?.quantity}
          </Box>
          <Box
            onClick={() =>
              dispatch(
                setIncrementQuantity(
                  item.service_id,
                  serviceType === "products" ? "products" : "services"
                )
              )
            }
            sx={{
              padding: "1rem",
              backgroundColor: "#bf0000",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            +
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
