import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/actions/actions";
import { useState } from "react";
import axiosInstance from "../../../../axiosInstance";
import Swal from "sweetalert2";

export default function RemoveModal({ type, item, getUsers }) {
  const [loading, setLoading] = useState(false);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const removeUser = async (item) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}auth/users/${item?.user_id}`;

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      setLoading(false);
      getUsers();
      handleClose();
      Swal.fire({
        icon: "success",
        title: "User removed successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      setLoading(false);
      handleClose();
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };
  return (
    <>
      {loading ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50% - 20px",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
          opacity: loading ? ".4" : "1",
        }}
      >
        <Typography>
          Are you sure to remove{" "}
          {type === "user" ? "" : "'" + item?.agent_name + "'"}{" "}
          {type === "user" ? "user" : "branch"}?
        </Typography>
        <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Button
            onClick={() => removeUser(item)}
            variant="contained"
            color="error"
            sx={{ marginRight: "1rem" }}
          >
            Remove
          </Button>
          <Button onClick={handleClose} variant="contained" color="inherit">
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}
