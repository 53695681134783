import * as React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import DateRangeContext from "../../../../contexts/Dashboard/DateRange.js";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../axiosInstance.js";

const valueFormatter = (value) => `${value}`;

const chartSetting = {
  borderRadius: 30,
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

export default function TotalCommissions() {
  const [dataSet, setDataSet] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { startDate, setStartDate, endDate, setEndDate } =
    React.useContext(DateRangeContext);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const getTotalCommissions = async () => {
    setLoading(true);
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      let url = `${process.env.REACT_APP_BASE_URL}dashboard/commission_status?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${token}`,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json",
        },
      });
      setDataSet(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response) {
        console.log("Error response:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else {
        console.log("Error message:", error.message);
      }
    }
  };

  React.useEffect(() => {
    getTotalCommissions();
  }, [startDate, endDate, selectedBranchesString]);

  const transformedData = Object.entries(dataSet).map((data, idx) => ({
    category:
      data[0] === "total_commissions"
        ? "Total Commissions"
        : data[0] === "total_fully_paid_invoice_commissions"
        ? "Total Fully Paid Invoice Commissions"
        : "Total Paid Commissions",
    value: data[1],
    //   data[0] === "total_commissions"
    //     ? data[1]
    //     : data[0] === "total_fully_paid_invoice_commissions"
    //     ? 400
    //     : 300,
  }));

  console.log(transformedData, "transformedData");

  const labels = [
    "Total Commissions",
    "Fully Paid Invoice Commissions",
    "Paid Commissions",
  ];

  return !loading ? (
    transformedData?.length ? (
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ width: "100%", textAlign: "start", marginBottom: "1.5rem" }}
            variant="h6"
            color="primary"
          >
            Commissions
          </Typography>
        </Box>{" "}
        <svg width="0" height="0">
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#800000", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#000000", stopOpacity: 1 }}
              />
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#bf0000", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#000000", stopOpacity: 1 }}
              />
            </linearGradient>
            <linearGradient id="gradient3" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#ff0000", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#000000", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>
        <BarChart
          slotProps={{
            bar: {
              rx: 8,
              ry: 8,
            },
          }}
          dataset={transformedData}
          series={[
            {
              dataKey: "value",
              label: "Commissions",
              valueFormatter,
              color: "url(#gradient3)",
            },
          ]}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "category",
            },
          ]}
          {...chartSetting}
        />
      </Box>
    ) : (
      <Typography variant="h5">No Commission Found!</Typography>
    )
  ) : (
    <CircularProgress />
  );
}
