import { Box, Button, Typography } from "@mui/material";
import Search from "./Search";
import FilterModal2 from "../../Modal/FilterModal";
import { openModal, updateWindowWidth } from "../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "../../../styles/theme";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function FilterSearchArea({
  filterMethods,
  buttons,
  downloadExcell,
}) {
  const selectedFilters = useSelector((state) => state.filters.selectedFilters); // Arama terimini al
  const mobileDevice = useSelector((state) => state.windowWidth.width); // Filtrelenmiş ürünleri al

  const dispatch = useDispatch();
  const handleOpenModal = () => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(openModal(<FilterModal2 filterMethods={filterMethods} />));
  };
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const pageType = useSelector((state) => state.filters.pageType);

  const handleOpenModalInstant = () => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    // dispatch(openModal(<ComingSoonModal />));
    Swal.fire({
      icon: "info",
      title: "Instant Booking is Temporary Unavailable!",
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: mobileDevice > 768 ? "center" : "unset",
        flexDirection: mobileDevice < 768 ? `column` : `row`,
      }}
    >
      <Search />
      {pageType === "quotes" ? (
        <Box
          sx={{
            order: mobileDevice < 768 ? "2" : "3",
            marginTop: mobileDevice < 768 ? "1rem" : "0",
          }}
        >
          <Button
            href="/room-finder"
            fullWidth={mobileDevice < 768 ? true : false}
            variant="contained"
            color="primary"
            sx={{
              height: "52px",
              marginRight: mobileDevice > 768 ? "1rem" : "0",
            }}
          >
            New Quote
          </Button>
        </Box>
      ) : null}
      <Box
        sx={{
          order: mobileDevice < 768 ? "1" : "3",
          display: "flex",
          alignItems: "center",
          flexDirection: mobileDevice < 768 ? "column" : "row",
        }}
      >
        <Box
          onClick={handleOpenModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: mobileDevice ? "space-between" : "unset",
            width: "fit-content",
            marginLeft: mobileDevice ? "0" : "auto",
            cursor: "pointer",
            margin: mobileDevice < 768 ? "auto" : "unset",
            marginBottom: mobileDevice < 768 ? "1rem" : "unset",
            marginRight: mobileDevice < 768 ? "unser" : "1rem",
          }}
        >
          <Box
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
          >
            <FilterListIcon
              color="primary"
              sx={{
                fontSize: mobileDevice ? "28px" : "fit-content",
              }}
            />
            {Object.keys(selectedFilters).length != 0 ? (
              <span
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  borderRadius: "100%",
                  fontSize: "12px",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: lightMode ? "#ffffff" : "#000000",
                  backgroundColor: lightMode ? "#800000" : "#ffffff",
                }}
              >
                {Object.keys(selectedFilters).length}
              </span>
            ) : null}
          </Box>
          <Typography
            sx={{ marginLeft: ".75rem", fontWeight: "700" }}
            variant={mobileDevice ? "body2" : "inherit"}
          >
            FILTERS
          </Typography>
        </Box>
        {buttons ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginBottom: mobileDevice < 768 ? "2rem" : "unset",
            }}
          >
            {buttons?.map((button, idx) => {
              return (
                <Button
                  fullWidth={mobileDevice < 768}
                  key={idx}
                  variant={"contained"}
                  href={button.url}
                  color="primary"
                  onClick={() => (
                    // button.name === "Instant Booking" &&
                    //   handleOpenModalInstant(),
                    button.name === "Start Request" &&
                      navigate("/room-finder"),
                    button.name === "Export Report" && downloadExcell()
                  )}
                  sx={{
                    // ...(button.name === "Instant Booking" && {
                    //   display: "block",
                    //   textAlign: "center",
                    //   minHeight: "40px",
                    //   "&:hover": {
                    //     color: "#b0b0b0",
                    //     background: lightMode ? "#d0d0d0" : "#3b3a3a",
                    //   },
                    //   color: "#b0b0b0",
                    //   background: lightMode ? "#d0d0d0" : "#404040",
                    //   cursor: "pointer",
                    //   pointerEvents: "auto",
                    // }),
                    marginRight:
                      buttons.length !== 1 && idx === 0 ? "1rem" : "unset",
                  }}
                >
                  {button.name}
                </Button>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
