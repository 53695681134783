// StyledTitleDot.js
import { styled } from '@mui/material/styles';

const StyledTitleDot = styled('span')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  marginLeft: '0.75rem',
  '&::before': {
    content: '""',
    width: '10px',
    height: '10px',
    bottom: '0',
    backgroundColor: theme.palette.customRed.main, // Temadaki özel kırmızıyı kullanıyor
    position: 'absolute',
  },
}));

export default StyledTitleDot;
