import {
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Alert,
  createTheme,
  Box,
} from "@mui/material";
import { getTheme } from "../styles/theme";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@emotion/react";

import LoginImage from "../assets/images/login.jpg";

const formStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${LoginImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100vw",
  height: { xs: "auto", md: "100vh" },
  position: "relative",
  "&:before": {
    background: "rgba(0, 0, 0, .4)",
    content: "''",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    zIndex: "0",
  },
};

export default function AuthForm({
  title,
  onSubmit,
  children,
  imageSrc,
  handleError,
  handleErrorText,
}) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const theme = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#bf0000",
            "&.Mui-focused": {
              color: "#bf0000",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#444444",
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#bf0000", // Burada istediğiniz renge ayarlayabilirsiniz
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#bf0000",
            },
          },
          input: {
            color: "#444444",
          },
        },
      },
    },
  });

  return (
    <Box sx={formStyles}>
      {/* {handleError && (
        <Alert
          sx={{
            position: "absolute",
            right: "0",
            top: "calc(100vh - 150px)",
            borderTopLeftRadius: "3rem",
            borderBottomLeftRadius: "3rem",
          }}
          severity="error"
        >
          {handleErrorText}
        </Alert>
      )} */}

      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            zIndex: "1",
          }}
        >
          <CssBaseline />
          <Paper
            sx={{
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                height: { xs: "auto", md: "auto" },
                // "&:before": {
                //   xs: {
                //     content: "''",
                //     position: "absolute",
                //     height: "90%",
                //     left: "5%",
                //     width: "90%",
                //     top: "5%",
                //     background: "#7070706e",
                //   },
                //   md: {
                //     display: "none",
                //   },
                // },
              }}
            >
              {/* <Grid item xs={12} md={6}>
                <img
                  width={"100%"}
                  height={"100%"}
                  src={imageSrc}
                  style={{ objectFit: "cover" }}
                />
              </Grid> */}
              <Box
                sx={{
                  maxWidth: { xs: "100%", md: "50%", lg: "660px" },
                  marginRight: { xs: "0", md: "2rem", lg: "0" },
                }}
              >
                <Typography
                  color="#ffffff"
                  sx={{
                    fontWeight: "700",
                    lineHeight: { xs: "2.5rem", md: "3.5rem" },
                    fontSize: { xs: "2rem", md: "3rem" },
                    marginBottom: "1rem",
                  }}
                >
                  Embark on an effortless Partner Portal journey with us
                </Typography>
                <Typography variant="h6" color="#ffffff">
                  Explore the streamlined process to request bookings, book
                  additional services, and make instant bookings with ease.
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: { xs: "100%", md: "50%", lg: "400px" },
                  marginTop: { xs: "2rem", md: "0", lg: "0" },
                }}
              >
                <Box
                  sx={{
                    padding: 4,
                    borderRadius: ".25rem",
                    boxShadow: "2px 2px 5px #0000005c",
                    background: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    variant="h4"
                    sx={{
                      color: "#bf0000",
                      marginBottom: "1rem",
                    }}
                  >
                    {title}
                  </Typography>
                  <form style={{ position: "relative" }} onSubmit={onSubmit}>
                    {children}
                  </form>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
