// AirportServicesContext.js

import React, { createContext, useState } from "react";

const AirportServicesContext = createContext();

export const AirportServicesProvider = ({ children }) => {
  const initialBookingDetails = {
    flight_number: "",
    name: "",
    phone: "",
    surname: "",
    email: "",
    billing_information: "",
    transportation_date_time: "",
    car_id: null,
    people_count: null,
    residence_id: null,
    travel_type: "",
    service_id: null,
  };
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [airports, setAirports] = useState();
  const [residences, setResidences] = useState();
  const [selectedResidence, setSelectedResidence] = useState(null);
  const [selectedCar, setSelectedCar] = useState();
  const [selectedPerson, setSelectedPerson] = useState(1);
  const [travelType, setTravelType] = useState("Arrival");
  const [bookingDetails, setBookingDetails] = useState(initialBookingDetails);
  const [carListItems, setCarListItems] = useState();
  const [activeStep, setActiveStep] = useState(0);

  // Context'e sağlanacak değerler
  const contextValue = {
    airports,
    setAirports,
    residences,
    setResidences,
    selectedAirport,
    setSelectedAirport,
    selectedResidence,
    setSelectedResidence,
    selectedCar,
    setSelectedCar,
    selectedPerson,
    setSelectedPerson,
    travelType,
    setTravelType,
    bookingDetails,
    setBookingDetails,
    activeStep,
    setActiveStep,
    carListItems,
    setCarListItems,
  };

  return (
    <AirportServicesContext.Provider value={contextValue}>
      {children}
    </AirportServicesContext.Provider>
  );
};

export default AirportServicesContext;
