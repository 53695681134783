import { useContext } from "react";
import AirportServicesContext from "../../../../../contexts/AirportServices/AirportServices";
import FormArea from "./FormArea/FormArea";

export default function StepThird() {
    const { bookingDetails } = useContext(
        AirportServicesContext
      );

    return (
        <FormArea/>
    )
}