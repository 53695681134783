import { Box, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./style.css";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Toys } from "@mui/icons-material";

const ImageSingle = ({
  openImageSingle,
  setOpenImageSingle,
  data,
  typeOfModal,
  selectedImage,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handleCloseImageSingle = () => (
    setOpenImageSingle(false), setLoading(true), setThumbsSwiper(null)
  );

  const [style, setStyle] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(typeOfModal, "type of modal");
  const initialStyle = {
    overflow: "auto !important",
    position: "absolute",
    outline: "none",
    border: "none",
    top: "150%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    height: "100vh",
    boxShadow: 24,
    p: { xs: "1", md: "4" },
    transition: "all .4s",
    display: "flex",
    flexDirection: "column",
    background: "#191919",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    if (openImageSingle) {
      setStyle((prev) => ({
        ...prev,
        top: "50%",
      }));
    } else {
      setStyle(initialStyle);
    }
  }, [openImageSingle]);

  console.log(data, "daha here 616161");
  console.log(selectedImage, "selected daha here 616161");

  const selectedIndex =
    typeOfModal === "images"
      ? data?.images?.findIndex((item) => item === selectedImage)
      : data?.campus_videos?.findIndex((item) => item === selectedImage);
  return (
    <Modal
      open={openImageSingle}
      onClose={handleCloseImageSingle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CancelIcon
          onClick={handleCloseImageSingle}
          sx={{
            filter: "drop-shadow(0 0 3px #800000)",
            fontSize: "36px",
            color: "#ffffff",
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
            transition: "all .4s",
            "&:hover": {
              color: "#efefef",
              transition: "all .4s",
            },
          }}
        />

        <Swiper
          navigation={true}
          className="big-swiper"
          initialSlide={selectedIndex}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          style={{ height: "60%", width: "100%" }}
        >
          {data && data?.images?.length
            ? typeOfModal === "images" &&
              data?.images?.map((item, idx) => {
                return (
                  <SwiperSlide
                    style={{
                      borderRadius: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: { xs: "1.75rem", md: "3rem" },
                        background: "#191919",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          objectPosition: "center",
                          width: "auto",
                          height: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "2rem",
                          left: "50%",
                          transform: "translateX(-50%)",
                          position: "relative",
                        }}
                        src={item?.image_file || item}
                        alt=""
                      />
                    </Box>
                  </SwiperSlide>
                );
              })
            : null}
          {data && data?.campus_videos?.length
            ? typeOfModal === "videos" &&
              data?.campus_videos?.map((item, idx) => {
                return (
                  <SwiperSlide>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: { xs: "1.75rem", md: "3rem" },
                        background: "#191919",
                        borderRadius: "2rem",
                      }}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="100%"
                          style={{
                            background: "#c4c4c4",
                            borderRadius: "2rem",
                          }}
                        />
                      )}
                      <Box
                        className="player-wrapper"
                        sx={{
                          height: "100%",
                          width: "100%",
                          display: loading ? "none" : "block",
                        }}
                      >
                        <ReactPlayer
                          className="react-player"
                          url={item}
                          width="100%"
                          height="100%"
                          controls={true}
                          onReady={() => setLoading(false)}
                        />
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>

        <Box
          sx={{
            maxWidth: { xs: "calc(100vw - 60px)", md: "calc(100vw - 100px)" },
            width: "100%",
            overflow: "hidden",
            height: "20%",
            boxSizing: "border-box",
          }}
        >
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={32}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            initialSlide={selectedIndex}
            className="small-swiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
            }}
            style={{
              height: "100%",
              boxSizing: "border-box",
            }}
          >
            {data && data?.images?.length
              ? typeOfModal === "images" &&
                data?.images?.map((item, idx) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: "25%",
                        height: "100%",
                        opacity: "0.4",
                        borderRadius: "2rem",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "2rem",
                        }}
                        src={item?.image_file || item}
                        alt=""
                      />
                    </SwiperSlide>
                  );
                })
              : null}
            {data && data?.campus_videos?.length
              ? typeOfModal === "videos" &&
                data?.campus_videos?.map((item, idx) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: "25%",
                        height: "100%",
                        opacity: "0.4",
                        borderRadius: "2rem",
                      }}
                    >
                      <Box sx={{ width: "100%", height: "100%" }}>
                        {loading && (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                            style={{
                              background: "#c4c4c4",
                              borderRadius: "2rem",
                            }}
                          />
                        )}
                        <Box
                          className="player-wrapper"
                          sx={{
                            position: "absolute",
                            zIndex: -1,
                            width: "100%",
                            top: "0",
                            left: "0",
                            height: "100%",
                            display: loading ? "none" : "block",
                          }}
                        >
                          <ReactPlayer
                            className="react-player"
                            url={item}
                            width="100%"
                            height="100%"
                            controls={true}
                            onReady={() => setLoading(false)}
                          />
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </Box>

        {/* {typeOfModal === "images" && (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={data?.image_file || data}
              alt=""
            />
          )}
          {typeOfModal === "videos" && (
            <Box sx={{ width: "100%", height: "100%" }}>
              {loading && (
                <Skeleton variant="rectangular" width="100%" height="100%" />
              )}
              <Box
                className="player-wrapper"
                sx={{
                  height: "100%",
                  width: "100%",
                  display: loading ? "none" : "block",
                }}
              >
                <ReactPlayer
                  className="react-player"
                  url={data}
                  width="100%"
                  height="100%"
                  controls={true}
                  onReady={() => setLoading(false)}
                />
              </Box>
            </Box>
          )} */}
      </Box>
    </Modal>
  );
};

export default ImageSingle;
