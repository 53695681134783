import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMode } from "../../../redux/actions/actions";

export default function DarkLightMode() {
  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.mode.lightMode);

  useEffect(() => {
    localStorage.setItem("lightMode", lightMode);
  }, [lightMode]);
  return (
    <Box
      onClick={() => dispatch(toggleMode())}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: { xs: "56px", md: "56px" },
        height: { xs: "28px", md: "32px" },
        padding: "3px",
        borderRadius: "2rem",
        marginLeft: "auto",
        boxShadow:
          "1px 1px 1px 0 rgba(0, 0, 0, 0.2),3px 3px 3px 0 rgba(0, 0, 0, 0.19)",
        background: lightMode ? "#f0efef" : "#0000005c",
      }}
    >
      <Box
        sx={{
          boxShadow:
            "1px 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "32px",
          width: { xs: "24px", md: "25px" },
          height: { xs: "24px", md: "25px" },
          backgroundColor: lightMode ? "#ffffff" : "#000000",
          borderRadius: "100%",
          position: "relative",
          left: !lightMode ? { xs: "27px", md: "24px" } : "0",
          transition: "left .4s",
        }}
      >
        {!lightMode ? (
          <DarkModeIcon
            sx={{ fontSize: { xs: "16px", md: "24px" }, color: "#ffffff" }}
          />
        ) : (
          <LightModeIcon
            sx={{ fontSize: { xs: "16px", md: "24px" }, color: "#790807" }}
          />
        )}
      </Box>
      {/* <Box
        onClick={() => setLightMode(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "32px",
          height: "32px",
          backgroundColor: "#707070",
          borderRadius: "100%",
        }}
      >
        <DarkModeIcon sx={{ color: "#000000" }} />
      </Box> */}
    </Box>
  );
}
