import { Box, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonsMobile from "../../../components/RoomFinder/RequestOperations/ButtonsMobile";
import ButtonsDesktop from "../../../components/RoomFinder/RequestOperations/ButtonsDesktop";
import { useSelector } from "react-redux";

export default function RequestOperations({
  selectedRooms,
  setSelectedRooms,
  bookingData,
  setBookingData,
  setLoaderStart
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 999);

  // Resize event listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 999);
    };

    // Event listener'ı ekleyin
    window.addEventListener("resize", handleResize);

    // Cleanup function olarak event listener'ı kaldırın
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Bu useEffect'i sadece bileşen mount olduğunda çalıştırmak için boş bir bağımlılık dizisi verin.
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return isMobile ? (
    <ButtonsMobile
      selectedRooms={selectedRooms}
      setSelectedRooms={setSelectedRooms}
      bookingData={bookingData}
      setBookingData={setBookingData}
      setLoaderStart={setLoaderStart}
    />
  ) : (
    <Box
      sx={{
        position: "fixed",
        bottom: "0",
        width: "100vw",
        height: "90px",
        left: "0",
        zIndex: 99999,
        //boxShadow: "2px -1px 5px #000000",
        filter: "drop-shadow(0px -3px 6px #00000029)",
        background: lightMode ? "#EDEDED" : "#292929",
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <ButtonsDesktop
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          bookingData={bookingData}
          setBookingData={setBookingData}
          setLoaderStart={setLoaderStart}
        />
      </Container>
    </Box>
  );
}
