import { Box, Typography } from "@mui/material";
import { light } from "@mui/material/styles/createPalette";
import React, { useEffect, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";

const TopBookedProgressBars = ({
  locationDatas,
  hoveredLocation,
  setHoveredLocation,
  selectedLocation,
  setSelectedLocation,
  bookingDetails,
  setBookingDetails,
}) => {
  const [updatedLocationDatas, setUpdatedLocationDatas] = useState([]);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const progressBarRefs = useRef({});

  // useEffect(() => {
  //   if (locationDatas.length) {
  //     const updatedData = locationDatas.map((item) => ({
  //       ...item,
  //       percentage: Math.floor(Math.random() * 101),
  //     }));
  //     setUpdatedLocationDatas(updatedData);
  //   }
  // }, [locationDatas]);

  useEffect(() => {
    if (selectedLocation) {
      const selectedRef =
        progressBarRefs.current[selectedLocation.nationality_name];
      if (selectedRef) {
        selectedRef.scrollIntoView({ behavior: "smooth", block: "center" });
        // setBookingDetails({
        //   display: true,
        //   details: selectedLocation,
        // });
      } else {
        // setBookingDetails({
        //   display: false,
        //   details: null,
        // });
      }
    }
  }, [selectedLocation]);
  return (
    <Box
      sx={{
        width: "100%",
        height: locationDatas?.length ? "auto" : "100%",
        maxHeight: "calc(500px - 64px)",
        overflow: "auto",
        padding: ".5rem",
      }}
    >
      {locationDatas?.length ? (
        locationDatas.map((item, idx) => {
          return item?.percentage > 0 ? (
            <Box
              key={item.nationality_name}
              onClick={() => setSelectedLocation(item)}
              onMouseEnter={() => setHoveredLocation(item)}
              onMouseLeave={() => setHoveredLocation(null)}
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "1rem",
                background: lightMode ? "#fefefe" : "#242424",
                marginBottom: locationDatas.length !== idx + 1 ? ".5rem" : "0",
                "&:hover": {
                  boxShadow: `0px 0px 5px  ${
                    item.percentage < 15
                      ? lightMode
                        ? `#800000`
                        : `#ff0000`
                      : item.percentage < 50
                      ? `#ea7f3d`
                      : `#44b4e0`
                  }`,
                },
                boxShadow:
                  selectedLocation?.nationality_name === item?.nationality_name
                    ? `0px 0px 5px ${
                        item.percentage < 15
                          ? lightMode
                            ? `#800000`
                            : `#ff0000`
                          : item.percentage < 50
                          ? `#ea7f3d`
                          : `#44b4e0`
                      }`
                    : hoveredLocation?.nationality_name ===
                      item?.nationality_name
                    ? `0px 0px 5px  ${
                        item.percentage < 15
                          ? lightMode
                            ? `#800000`
                            : `#ff0000`
                          : item.percentage < 50
                          ? `#ea7f3d`
                          : `#44b4e0`
                      }`
                    : `0px 0px 5px ${lightMode ? `#cfcfcf` : `#4d4d4d`}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: ".5rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ReactCountryFlag
                    countryCode={item?.nationality_code}
                    svg
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    title={item?.nationality_name}
                  />
                  {/* <Typography>{item?.nationality_code}</Typography> */}
                </Box>
                <Typography sx={{ marginRight: "auto", marginLeft: ".5rem" }}>
                  {item?.nationality_name}
                </Typography>
                <Typography
                  sx={{
                    color:
                      item.percentage < 15
                        ? lightMode
                          ? `#800000`
                          : `#ff0000`
                        : item.percentage < 50
                        ? `#ea7f3d`
                        : `#44b4e0`,
                  }}
                >
                  {item?.percentage}%
                </Typography>
              </Box>
              <Box
                ref={(el) =>
                  (progressBarRefs.current[item.nationality_name] = el)
                }
                sx={{
                  width: "100%",
                  backgroundColor: lightMode ? "#ffffff" : "#4d4d4d",
                  position: "relative",
                  height: "15px",
                  overflow: "hidden",
                  borderRadius: "2rem",
                  boxShadow: `1px 1px 5px 1px ${
                    lightMode ? `#dfdfdf` : `#000000`
                  }  inset`,
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    backgroundColor:
                      item.percentage < 15
                        ? lightMode
                          ? `#800000`
                          : `#ff0000`
                        : item.percentage < 50
                        ? "#ea7f3d"
                        : "#44b4e0",
                    width: `${item.percentage}%`,
                    zIndex: 9,
                    height: "15px",
                  },
                }}
              >
                {/* <Box
                  sx={{
                    width: "140px",
                    height: "15px",
                    zIndex: 10,
                    position: "absolute",
                    left: `calc(${item.percentage / 2}% - 70px)`,
                    top: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                  }}
                >
                  {item.percentage}%
                </Box> */}
              </Box>
            </Box>
          ) : null;
        })
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">No Top Booked Residence Found!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TopBookedProgressBars;
