import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { closeModal } from "../../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../../../../axiosInstance";
import Swal from "sweetalert2";
import PhoneCountryInput from "../../../PhoneCountryInput";

export default function CreateNewStudentModal({
  setSelectedStudent,
  setStudents,
  setNewStudentControl,
}) {
  const initialState = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone: "",
    student_gender: "",
    form_message: "",
  };

  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const lettersRegex = /^[A-Za-z]+$/;

    if (name === "branch") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues?.email_address)) {
      errors.email_address = "Email is not valid";
    }

    if (!formValues?.phoneIsValid) {
      errors.phone = "Phone is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };
  useEffect(() => {
    if (formValues?.email_address) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  const handleCreateStudent = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}partner_requests`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    const formattedMessage = formValues.form_message.replace(/\n/g, "<br><br>");
    const htmlMessage = `<p style="color: #9b9ea2">${formattedMessage}</p>`;

    const appendForm = {
      student_firstname: formValues?.first_name,
      student_lastname: formValues?.last_name,
      student_email: formValues?.email_address,
      phone: formValues?.phone,
      student_gender: formValues?.student_gender,
      form_message: htmlMessage,
    };

    setLoader(true);
    try {
      // Axios ile POST isteği yapılıyor
      const response = await axiosInstance.post(url, appendForm, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setLoader(false);
      setNewStudentControl(true);

      setSelectedStudent({
        student_id: response?.data?.result?.student_id,
        student_name: `${response?.data?.result?.student_firstname} ${response?.data?.result?.student_lastname}`,
      });
      Swal.fire({
        icon: "success",
        title: "User Created Successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      handleClose();
    } catch (error) {
      // Hata durumunda hata mesajını göster
      setLoader(false);
      handleClose();
      Swal.fire({
        icon: "error",
        title: "An error occurred while sending email!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      // dispatch(
      //   openModal(<ErrorModal data={error.response.data} />, "selectStudent")
      // );
      console.error("Error during the request:", error.message);
    }
  };
  return (
    <>
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null}
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "relative", opacity: loader ? ".4" : 1 }}>
          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.first_name || ""}
            onChange={handleChange}
            name="first_name"
            id="outlined-basic"
            label="Student First Name"
            variant="outlined"
            type="text"
            required
            error={!!formErrors.first_name}
            helperText={formErrors.first_name || ""}
          />
          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.last_name || ""}
            onChange={handleChange}
            name="last_name"
            id="outlined-basic"
            label="Student Last Name"
            variant="outlined"
            type="text"
            required
            error={!!formErrors.last_name}
            helperText={formErrors.last_name || ""}
          />

          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.email_address || ""}
            onChange={handleChange}
            name="email_address"
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            type="email"
            required
            error={!!formErrors.email_address}
            helperText={formErrors.email_address || ""}
          />

          <Box
            sx={{
              marginBottom: "1rem",
            }}
          >
            <PhoneCountryInput
              formValues={formValues}
              setFormValues={setFormValues}
              error={!!formErrors.phone}
              helperText={formErrors.phone || ""}
            />
          </Box>

          <FormControl
            fullWidth
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues?.student_gender}
              name="student_gender"
              label="Gender"
              onChange={handleChange}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
            </Select>
          </FormControl>

          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.form_message}
            onChange={handleChange}
            name="form_message"
            id="outlined-basic"
            label="Message"
            variant="outlined"
            type="text"
            multiline
            rows={3}
            required
          />
          <Button
            sx={{ marginTop: "1rem", height: "56px" }}
            onClick={handleCreateStudent}
            disabled={
              !formValues?.first_name ||
              !formValues?.last_name ||
              !formValues?.email_address ||
              !formValues?.phone ||
              !formValues?.student_gender ||
              !formValues?.form_message ||
              formErrors.email_address ||
              formErrors?.phone
            }
            fullWidth
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </Box>
      </Box>
    </>
  );
}
