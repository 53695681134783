import { Box } from "@mui/material";
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const ResidenceSlider = ({ data }) => {
  return (
    <Box>
      <Swiper
        className="residence-swiper"
        style={{
          width: "100%",
          height: "400px",
          borderRadius: "2rem",
          boxShadow: "0 0 3px #191919",
        }}
        navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Navigation]}
      >
        {data?.images?.length
          ? data?.images?.map((image, idx) => {
              return (
                <SwiperSlide
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                    }}
                    src={image?.image_file}
                    alt=""
                  />
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
    </Box>
  );
};

export default ResidenceSlider;
