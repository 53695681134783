import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Typography } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadButton({ file, setFile }) {
  const [hoverEffect, setHoverEffect] = React.useState(false);
  const handleFileChange = (e) => {
    const fileTemp = e.target.files[0]; // Seçilen dosyayı al
    if (fileTemp) {
      setFile(fileTemp); // Dosya ismini state'e kaydet
    }
  };

  return (
    <Box
      sx={{
        marginTop: "2rem",
        marginBottom: "2rem",
        overflow: "hidden",
        height: "40px",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
          alignItems: { xs: "flex-start", md: "center" },
        },
      }}
    >
      <Button
        sx={{ minWidth: "max-content", height: "100%" }}
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
      {file && (
        <Box
          onMouseLeave={(e) => setHoverEffect(false)}
          onMouseEnter={(e) => setHoverEffect(true)}
        >
          <Typography
            sx={{
              marginLeft: "10px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              lineHeight: "40px",
            }}
          >
            {file.name}
          </Typography>
          {hoverEffect ? (
            <Box
              sx={{
                position: "absolute",
                top: "calc(40% - 48px)",
                padding: ".5rem",
                borderRadius: ".5rem",
                right: "5%",
                width: "90%",
                color: "#ffffff",
                overflow: "hidden",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "#bf0000",
                  opacity: ".9",
                },
              }}
            >
              <Typography
                sx={{
                  lineHeight: " 1rem",
                  position: "relative",
                  color: "#ffffff",
                }}
              >
                {file.name}
              </Typography>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}
