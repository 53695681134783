import { Box, Grid, Typography } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate } from "react-router-dom";

export default function SettingsComponent() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Box
          onClick={() => navigate("/add-user")}
          sx={{
            cursor: "pointer",
            boxShadow: "1px 1px 3px #afafaf",
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                boxShadow: "1px 1px 3px #afafaf",
                width: "120px",
                height: "120px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SupervisedUserCircleIcon
                sx={{ fontSize: "76px", color: "#bf0000" }}
              />
            </Box>
            <Typography variant="h5" color="primary" sx={{ marginTop: "1rem" }}>
              Add User
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box
          onClick={() => navigate("/add-branch")}
          sx={{
            cursor: "pointer",
            boxShadow: "1px 1px 3px #afafaf",
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                boxShadow: "1px 1px 3px #afafaf",
                width: "120px",
                height: "120px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ApartmentIcon sx={{ fontSize: "76px", color: "#bf0000" }} />
            </Box>
            <Typography variant="h5" color="primary" sx={{ marginTop: "1rem" }}>
              Add Branch
            </Typography>
          </Box>
        </Box>{" "}
      </Grid>
    </Grid>
  );
}
