// StepFirst.jsx
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { getTheme } from "../../styles/theme";
import {
  BackButton,
  NextButton,
} from "../../pages/InstantBooking/InstantBooking";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const StepSecond = ({
  activeStep,
  steps,
  handleNext,
  handleBack,
  formValues,
  setFormValues,
  handleChange,
  instantBooking,
}) => {
  useEffect(() => {
    if (
      instantBooking &&
      instantBooking.room_start &&
      instantBooking.room_end
    ) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        checkIn: dayjs(instantBooking.checkIn),
        checkOut: dayjs(instantBooking.checkOut),
      }));
    }
  }, [instantBooking, setFormValues]);

  const [checkIn, setCheckIn] = [
    formValues.checkIn,
    (date) => handleChange({ target: { name: "checkIn", value: date } }),
  ];

  const [checkOut, setCheckOut] = [
    formValues.checkOut,
    (date) => handleChange({ target: { name: "checkOut", value: date } }),
  ];

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <Container
      maxWidth={false}
      sx={{
        width: { xs: "100%", md: "70vw", lg: "54vw" },
        padding: "0 !important",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: "27px",
          fontWeight: "700",
          marginTop: "3rem",
          textAlign: "center",
        }}
        color={lightMode ? "#bf0000" : "#ffffff"}
      >
        Confirm Your reservation{" "}
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
            
              disabled
              sx={{
                width: "100%",
              }}
              label="Check-in *"
              value={checkIn}
              onChange={(newValue) => setCheckIn(newValue)}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled
              sx={{
                width: "100%",
              }}
              label="Check-out *"
              value={checkOut}
              onChange={(newValue) => setCheckOut(newValue)}
            />
          </LocalizationProvider>
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ maxWidth: "calc(100% - 40px)" }} id="duration">
              Duration
            </InputLabel>
            <Select
              name="duration"
              labelId="duration"
              id="demo-simple-select"
              value={formValues.duration}
              label="Duration"
              onChange={handleChange}
            >
              <MenuItem value={"duraion-1"}>Duraion 1</MenuItem>
              <MenuItem value={"duraion-2"}>Duraion 2</MenuItem>
              <MenuItem value={"duraion-3"}>Duraion 3</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            multiline
            rows={4}
            sx={{
              width: "100%",
            }}
            value={formValues.question}
            onChange={handleChange}
            name="question"
            id="question"
            label="Your Question"
            variant="outlined"
            type="text"
            required
          />
        </Grid>

        <Grid item xs={6}>
          <BackButton activeStep={activeStep} handleBack={handleBack} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NextButton
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            formValues={formValues}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepSecond;
