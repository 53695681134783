import { CircularProgress, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useLocation, useParams } from "react-router-dom";
import QuoteDetilsComp from "../../components/Quotes/QuoteDetails/QuoteDetailsComp";
import StartQuoteComp from "../../components/Quotes/StartQuote/StartQuoteComp";

export default function StartQuote() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const { quote } = location.state || {};


  return (
    <>
      <Typography className="title" variant="h4">
        Quote Details
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Quotes", `Quote Details`]} />
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : (
        <StartQuoteComp quoteDetails={quote} loader={loader} />
      )}
    </>
  );
}
