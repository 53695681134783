import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { closeModal } from "../../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../../../../axiosInstance";
import UsersListContext from "../../../../contexts/UsersList/UsersList";
import { setSelectedBranchesString } from "../../../../redux/actions/branchActions";
import Swal from "sweetalert2";

export default function UpdateUserModal({
  user,
  setUsers,
  searchedTerm,
  setSearchedTerm,
}) {
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const branchesArray = useSelector((state) => state?.branches?.branches);
  const profile = useSelector((state) => state.profile.profile);

  const [loading, setLoading] = useState();
  const [formValues, setFormValues] = useState(user);
  const [formErrors, setFormErrors] = useState({});
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const lettersRegex = /^[A-Za-z]+$/;

    if (name === "branch") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues?.email)) {
      errors.email = "Email is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };
  useEffect(() => {
    if (formValues?.email) {
      const errors = validateForm();

      setFormErrors(errors);

      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır

      if (Object.keys(errors).length > 0) {
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  useEffect(() => {
    console.log(formErrors, "form errrors");
  }, [formErrors]);
  const togglePasswordVisibility = (type) => {
    if (type === "confirm") {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const handleUpdateUser = async () => {
    // const appendForm = { ...formValues, agent_id: selectedBranchesString };
    const appendForm = {
      agent_id: formValues?.agent_id,
      agent_name: formValues?.agent_name,
      email: formValues?.email,
      user_id: formValues?.user_id,
    };

    // changePassword true ise ve password değeri var ise, bu değeri objeye ekliyoruz
    if (changePassword && formValues?.new_password) {
      appendForm.current_password = formValues?.current_password;
      appendForm.new_password = formValues?.new_password;
    }
    console.log(appendForm, "form values");

    updateUser(appendForm);
  };

  const updateUser = async (appendForm) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}auth/users`;

    if (searchedTerm) {
      url = `${process.env.REACT_APP_BASE_URL}auth/users?agent_name=${formValues?.agent_name}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}auth/users`;
    }

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.put(url, appendForm, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      if (searchedTerm) {
        setSearchedTerm(formValues?.agent_name);
      }

      if (
        !selectedBranchesString?.includes(formValues?.agent_id) &&
        formValues?.agent_id !== profile?.agent_id
      ) {
        dispatch(
          setSelectedBranchesString([profile?.agent_id, formValues?.agent_id])
        );
      } else if (formValues?.agent_id === profile?.agent_id) {
        dispatch(setSelectedBranchesString([profile?.agent_id]));
      }

      setUsers(response?.data?.users);
      setLoading(false);
      handleClose();
      Swal.fire({
        icon: "success",
        title: "User updated successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log(error.response.data.error_message, "error response");
      if (error.response.data.error_message === "password_exists") {
        setFormErrors((prev) => ({
          ...prev,
          password_exists: error.response.data.message,
        }));
      } else if (error.response.data.error_message === "wrong_exists") {
        setFormErrors((prev) => ({
          ...prev,
          wrong_exists: error.response.data.message,
        }));
      }

      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };

  useEffect(() => {
    console.log(formValues, "form values here");
  }, [formValues]);

  useEffect(() => {
    if (!changePassword) {
      setFormValues((prev) => {
        const newState = { ...prev };
        delete newState.password; // 'password' özelliğini kaldır
        return newState;
      });
    }
  }, [changePassword]);
  return (
    <Box>
      <>
        {branchesArray?.length ? (
          <FormControl
            fullWidth
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
            <Select
              className="select-branch"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues?.branch}
              defaultValue={formValues?.agent_id}
              name="agent_id"
              label="Branch"
              onChange={handleChange}
            >
              {branchesArray[0].agent_id !== profile?.agent_id ? (
                <MenuItem value={profile?.agent_id}>
                  {profile?.agent_name}
                </MenuItem>
              ) : null}
              {branchesArray?.map((branch, idx) => {
                return (
                  <MenuItem key={idx} value={branch?.agent_id}>
                    {branch?.agent_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        <TextField
          sx={{
            width: "100%",
            marginBottom: "1rem",
          }}
          value={formValues?.agent_name || ""}
          onChange={handleChange}
          name="agent_name"
          id="outlined-basic"
          label="User Name"
          variant="outlined"
          type="text"
          required
        />

        <TextField
          sx={{
            width: "100%",
            marginBottom: "1rem",
          }}
          value={formValues?.email || ""}
          onChange={handleChange}
          name="email"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          required
          error={!!formErrors.email}
          helperText={formErrors.email || ""}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ fontWeight: "700" }}>
            Change Password
          </Typography>
          <Box
            onClick={() => setChangePassword(!changePassword)}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              width: { xs: "56px", md: "56px" },
              height: { xs: "28px", md: "32px" },
              padding: "3px",
              borderRadius: "2rem",
              marginLeft: "auto",
              boxShadow:
                "1px 1px 1px 0 rgba(0, 0, 0, 0.2),3px 3px 3px 0 rgba(0, 0, 0, 0.19)",
              background: !changePassword ? "#f0efef" : "#0000005c",
            }}
          >
            <Box
              sx={{
                boxShadow:
                  "1px 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "32px",
                width: { xs: "24px", md: "25px" },
                height: { xs: "24px", md: "25px" },
                backgroundColor: !changePassword ? "#ffffff" : "#000000",
                borderRadius: "100%",
                position: "relative",
                left: changePassword ? { xs: "27px", md: "24px" } : "0",
                transition: "left .4s",
              }}
            >
              {!changePassword ? (
                <Box
                  sx={{
                    fontSize: { xs: "16px", md: "24px" },
                    color: "#ffffff",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    fontSize: { xs: "16px", md: "24px" },
                    color: "#790807",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        {changePassword ? (
          <>
            <Box
              component="form"
              // onSubmit={(e) => (
              //   e.preventDefault(), handleSearchUser(searchTerm)
              // )}
              autoComplete="off"
              sx={{ position: "relative" }}
            >
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
                required
                fullWidth
                name="current_password"
                label="Current Password"
                type={!showConfirmPassword ? "text" : "password"}
                id="current_password"
                autoComplete="current_password"
                value={formValues?.current_password || ""}
                onChange={handleChange}
                error={!!formErrors.wrong_exists}
                helperText={formErrors.wrong_exists || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => togglePasswordVisibility("confirm")}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
                required
                fullWidth
                disabled={!formValues?.current_password}
                name="new_password"
                label="New Password"
                type={!showPassword ? "text" : "password"}
                id="new_password"
                autoComplete="current-password"
                value={formValues?.new_password || ""}
                onChange={handleChange}
                error={!!formErrors.password_exists}
                helperText={formErrors.password_exists || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => togglePasswordVisibility("normal")}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        ) : null}

        <Button
          sx={{ marginTop: "1rem", height: "56px" }}
          onClick={handleUpdateUser}
          disabled={
            !formValues?.agent_name ||
            !formValues?.email ||
            formErrors.email ||
            !formValues?.agent_id ||
            (changePassword && !formValues?.new_password)
          }
          fullWidth
          variant="contained"
          color="primary"
        >
          Update User
        </Button>
      </>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "#ffffff94",
            zIndex: "9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );
}
