// StepFirst.jsx
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { getTheme } from "../../styles/theme";
import { NextButton } from "../../pages/InstantBooking/InstantBooking";
import { useSelector } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import PhoneCountryInput from "../PhoneCountryInput";
import axiosInstance from "../../axiosInstance";

const StepFirst = ({
  activeStep,
  steps,
  handleNext,
  formValues,
  setFormValues,
  handleChange,
  countries,
  setCountries,
  formErrors,
  error,
  helperText,
}) => {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const getCountries = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      let url = `${process.env.REACT_APP_BASE_URL}booking/countries`;
      const response = await axiosInstance.get(
        url,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCountries(response.data.countries);
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log("Error response:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else {
        console.log("Error message:", error.message);
      }
    }
  };
  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: { xs: "100%", md: "70vw", lg: "54vw" },
        padding: "0 !important",
      }}
    >
      <Typography
        variant="h3"
        color={lightMode ? "#bf0000" : "#ffffff"}
        sx={{
          fontSize: "27px",
          fontWeight: "700",
          marginTop: "3rem",
          textAlign: "center",
        }}
      >
        Place Your Request
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.firstname}
            onChange={handleChange}
            name="firstname"
            id="outlined-basic"
            label="Passport First Name"
            variant="outlined"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.lastname}
            onChange={handleChange}
            name="lastname"
            id="outlined-basic"
            label="Passport Last Name"
            variant="outlined"
            type="text"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.email}
            onChange={handleChange}
            name="email"
            id="outlined-basic"
            label="E-Mail"
            variant="outlined"
            error={!!formErrors.email}
            helperText={formErrors.email || ""}
            type="email"
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.phone}
            onChange={handleChange}
            name="phone"
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            type="text"
          /> */}
          <PhoneCountryInput
            formValues={formValues}
            setFormValues={setFormValues}
            error={error}
            helperText={helperText}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel
              sx={{ maxWidth: "calc(100% - 40px)" }}
              id="demo-simple-select-label"
            >
              Occupancy
            </InputLabel>
            <Select
              name="occupancy"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues.occupancy}
              label="Occupancy"
              onChange={handleChange}
            >
              <MenuItem value={"occupancy-1"}>Occupancy 1</MenuItem>
              <MenuItem value={"occupancy-2"}>Occupancy 2</MenuItem>
              <MenuItem value={"occupancy-3"}>Occupancy 3</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel
            required
              sx={{ maxWidth: "calc(100% - 40px)" }}
              id="demo-simple-select-label"
            >
              Home Country
            </InputLabel>
            <Select
              name="nationality"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues.home_country}
              label="Home Country"
              onChange={handleChange}
            >
              {countries?.map((country, idx) => {
                return (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.destinationUniversity}
            onChange={handleChange}
            name="destinationUniversity"
            id="outlined-basic"
            label="Destination University"
            variant="outlined"
            type="text"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel
              sx={{ maxWidth: "calc(100% - 40px)" }}
              id="demo-simple-select-label"
            >
              What is your budget?
            </InputLabel>
            <Select
              name="budget"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues.budget}
              label="What is your budget?"
              onChange={handleChange}
            >
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"20"}>20</MenuItem>
              <MenuItem value={"30"}>30</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.budget}
            onChange={handleChange}
            name="budget"
            id="outlined-basic"
            label="What is your Budget"
            variant="outlined"
            type="text"
          />
        </Grid> */}
        {/* 
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            value={formValues.numberOfStudents}
            onChange={handleChange}
            name="numberOfStudents"
            id="outlined-basic"
            label="Number of Students"
            variant="outlined"
            type="number"
          />
        </Grid> */}

        <Grid item xs={6}>
          <NextButton
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            formValues={formValues}
            formErrors={formErrors}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepFirst;
