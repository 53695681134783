// cartActions.js

import {
  ADD_TO_CART,
  CLEAR_CART,
  DECREMENT_QUANTITY,
  INCREMENT_QUANTITY,
  REMOVE_FROM_CART,
  SET_PROFILE,
  UPDATE_CART,
} from "./actionTypes";

// "cartType" parametresi eklendi
export const setAddToCart = (item, serviceDate, selectedSize, cartType) => {
  return {
    type: ADD_TO_CART,
    payload: { ...item, serviceDate, selectedSize, cartType },
  };
};

export const setUpdateCart = (item, serviceDate, selectedSize, cartType) => {
  return {
    type: UPDATE_CART,
    payload: { ...item, serviceDate, selectedSize, cartType },
  };
};

export const setIncrementQuantity = (itemId, cartType) => {
  return {
    type: INCREMENT_QUANTITY,
    payload: { itemId, cartType }, // "products" veya "services"
  };
};

export const setDecrementQuantity = (itemId, cartType) => {
  return {
    type: DECREMENT_QUANTITY,
    payload: { itemId, cartType }, // "products" veya "services"
  };
};

export const setRemoveFromCart = (itemId, cartType) => {
  return {
    type: REMOVE_FROM_CART,
    payload: { itemId, cartType }, // "products" veya "services"
  };
};

// Sepeti temizlerken, her iki sepet türü için de localStorage'dan kaldırmak gerekebilir
export const clearCart = (cartType) => {
  if (cartType === "products") {
    localStorage.removeItem("products-cart"); // "products" sepeti için
  } else if (cartType === "services") {
    localStorage.removeItem("services-cart"); // "services" sepeti için
  }
  return { type: CLEAR_CART, payload: { cartType } };
};

export const setProfile = (data) => {
  return {
    type: SET_PROFILE,
    payload: data,
  };
};
