import { Box, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useEffect, useState } from "react";
export default function NoDataFound() {
  const pageType = useSelector((state) => state.filters.pageType);
  const [value, setValue] = useState();

  useEffect(() => {
    if (pageType) {
      if (pageType === "commissions") {
        setValue("Commission");
      } else if (pageType === "partner_requests") {
        setValue("Request");
      } else if (pageType === "bookings") {
        setValue("Bookings");
      } else if (pageType === "quotes") {
        setValue("Quotes");
      } else if (pageType === "invoices") {
        setValue("Invoices");
      } else if (pageType === "additional_services") {
        setValue("Services");
      } else if (pageType === "room_finder") {
        setValue("Accommodation");
      } else if (pageType === "add-user") {
        setValue("User");
      } else if (pageType === "add-branch") {
        setValue("Branch");
      }
    }
  }, [pageType]);
  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          height: "400px",
          filter: "drop-shadow(0px 0px 5px #707070)",
          borderRadius: "6px",
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <FolderOffIcon
              sx={{
                fontSize: { xs: "108px", md: "144px" },
                color: "#707070",
                filter: "drop-shadow(5px 5px 5px #707070)",
              }}
            />
          </Box>
          <Typography
            sx={{
              color: "#707070",
              fontSize: { xs: "20px", md: "28px" },
              marginTop: "1rem",
            }}
          >
            {/* {pageType}  */}
            No {value} Found!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
