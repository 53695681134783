import { createTheme } from "@mui/material/styles";

export const getTheme = (lightMode) => {
  const customRedPalette = {
    light: "#ff0000", // En açık kırmızı tonu
    main: lightMode ? "#bf0000" : "#ffffff", // Ana kırmızı tonu
    dark: "#800000", // Koyu kırmızı tonu
    extraDark: "#600000", // Koyu kırmızı tonu
    hardDark: "#400000", // Bu kırmızı tonları ile kontrast oluşturacak metin rengi
  };

  // İlk aşama: Temel tema ayarları
  const baseTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000, // Burası değiştirildi!
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      h1: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h2: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h3: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h4: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h5: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h6: {
        color: lightMode ? "#000000" : "#ffffff",
      },
      h1: {
        color: "#000000",
        fontSize: "38px",
      },
      h2: {
        fontSize: "24px",
      },
    },
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın

      customRed: customRedPalette, // Özel kırmızı renk paletinizi tema içine eklediniz

      primary: {
        main: customRedPalette.main, // Özelleştirilmiş ana renk
      },
      secondary: {
        main: "#bf0000", // Özelleştirilmiş ikincil renk
      },
      error: {
        main: "#bf0000", // Hata mesajları için renk
      },
    },
  });

  return createTheme({
    ...baseTheme,
    components: {
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            MuiSvgIcon: {
              styleOverrides: {
                root: {
                  color: lightMode ? "#ffffff" : "#ffffff",
                },
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: lightMode ? "#000000" : "#ffffff",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: "1rem",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: "1rem",
            maxHeight: "200px", // Açılır menünün maksimum yüksekliği
          },
          // list: {
          //   borderRadius: "1rem !important",
          // },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // Butonların genel stil özellikleri
            borderRadius: "1rem",
            textTransform: "none",
          },
          containedPrimary: {
            // "primary" varyantı için özel stiller
            backgroundColor: lightMode
              ? baseTheme.palette.primary.main
              : "#bf0000",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#bf0000",
            },
          },
          containedSecondary: {
            // "secondary" varyantı için özel stiller
            backgroundColor: "#2267C9",
            color: "#ffffff",
            borderRadius: 0,
            "&:hover": {
              backgroundColor: "#2062BF ",
            },
          },
          containedSuccess: {
            backgroundColor: "#69C05C",
            "&:hover": {
              backgroundColor: "#58b94a",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: "1rem !important",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#ffffff",
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderRadius: "1rem !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            filter: "drop-shadow(0px 3px 6px #00000029)", // Bu satırı ekleyin,
            boxShadow: "none",
            backgroundColor: lightMode ? "#ffffff" : "#292929",
            borderRadius: 0,
            // "&.MuiPopover-paper-MuiMenu-paper": {
            //   borderRadius: "1rem",
            // },
          },
          // rounded: {
          //   borderRadius: "1rem",
          // },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {},
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            filter: "none",
            boxShadow: lightMode ? "0px 0px 5px #c8c8c8" : "none",
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            "&.Mui-focused.MuiOutlinedInput-notchedOutline": {
              borderColor: lightMode
                ? `${baseTheme.palette.primary.main}`
                : "#ffffff !important", // Normal durumda label rengi
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: lightMode
                ? `${baseTheme.palette.primary.main}`
                : "#ffffff", // Normal durumda label rengi
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            // backgroundColor: "#f5f5f5",
            border: "none",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
          },
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            borderRadius: "1rem",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            // Tüm TableCell'lar için genel stil özellikleri
          },
          head: {
            // Sadece başlık hücreleri için özel stil
            fontWeight: "bold",
            textTransform: "uppercase",
            color: lightMode ? baseTheme.palette.primary.main : "#ffffff",
            height: "70px",
            lineHeight: { xs: "40px" },
            background: lightMode ? "#efeeee" : "#292929",

            borderBottom: "2px solid red",
          },
          body: {
            height: "70px",
            background: lightMode ? "#f7f7f7" : "#303030",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            // TableRow'lar için genel stil özellikleri
            // "&:hover": {
            //   backgroundColor: "#fafafa !important",
            // },
            // "&:not(:last-child)": {
            //   ".MuiTableCell-root": {
            //     borderBottom: "1px solid #e0e0e0",
            //   },
            // },
            "&:last-child": {
              ".MuiTableCell-root": {
                borderBottom: `1px solid  ${lightMode ? `#e0e0e0` : `#515151`}`,
              },
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            // Tüm PaginationItem'lar için genel stil özellikleri
          },
          page: {
            '&[aria-current="true"]': {
              backgroundColor: baseTheme.palette.primary.main,
              borderColor: baseTheme.palette.primary.main,
              color: lightMode ? "#ffffff" : "#000000",
              "&:hover": {
                backgroundColor: baseTheme.palette.primary.main,
                opacity: 0.9,
              },
            },
          },
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: {
            zIndex: "999999999999 !important",
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
            zIndex: "999999999999 !important",
            outline: "none",
            border: "none",
          },
        },
      },

      // MuiSelect: {
      //   styleOverrides: {
      //     root: {
      //       maxWidth: "250px",
      //     },
      //   },
      // },
      // MuiFormControl: {
      //   styleOverrides: {
      //     root: {
      //       maxWidth: "250px",
      //       width: "100%", // Genişliği yüzde 100 yaparak, ebeveyninin genişliğine göre sığmasını sağlayabiliriz, ancak maksimum 250px olacaktır.
      //     },
      //   },
      // },
    },
  });
};
