import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/actions/actions";
import TableDetailsModal from "../Modal/TableDetailsModal";

import NoDataFound from "../NoDataFound";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BadgeIcon from "@mui/icons-material/Badge";
import KeyIcon from "@mui/icons-material/Key";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import FlagIcon from "@mui/icons-material/Flag";
import LanguageIcon from "@mui/icons-material/Language";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import FeedIcon from "@mui/icons-material/Feed";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { getTheme } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { bookingDatas } from "../../pages/MyBookings/data";
import { light } from "@mui/material/styles/createPalette";

export default function PaginationTable({
  tableHeader,
  allTableHeader,
  isMobileSm,
  isMobileMd,
}) {
  const filteredDatas = useSelector((state) => state.filters.filteredListItems); // Filtrelenmiş ürünleri al

  const rawData = filteredDatas;

  const [filteredDatass, setFilteredDatass] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageType = useSelector((state) => state.filters.pageType);
  const loading = useSelector((state) => state.listItems?.loading); // Redux store'dan loading durumunu al

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // function flattenObject(obj) {
  //   let result = {};

  //   for (const key in obj) {
  //     if (typeof obj[key] === "object" && obj[key] !== null) {
  //       Object.assign(result, flattenObject(obj[key])); // Sadece iç içe nesnenin anahtarlarını al
  //     } else {
  //       result[key] = obj[key]; // Üst seviye anahtarları doğrudan ekle
  //     }
  //   }

  //   return result;
  // }

  // Kullanımı:
  // const flattenedData = filteredDatas.map((data) => flattenObject(data));
  // let tableHeaders =
  //   flattenedData.length > 0 ? Object.keys(flattenedData[0]) : [];

  // let allTableHeader = tableHeaders

  const handleOpenModal = (row) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(
      openModal(<TableDetailsModal data={row} columns={allTableHeader} />)
    );
  };

  useEffect(() => {
    // Filtreleme sonrası toplam sayfa sayısını hesapla
    const totalPageCount = Math.ceil(filteredDatas.length / rowsPerPage);

    // Eğer mevcut sayfa numarası hesaplanan toplam sayfa sayısından büyükse,
    // veya filtrelenmiş veri sayısı sıfırsa (yani hiç veri kalmamışsa),
    // sayfa numarasını otomatik olarak en uygun değere ayarla.
    if (page >= totalPageCount || filteredDatas.length === 0) {
      setPage(totalPageCount > 0 ? totalPageCount - 1 : 0); // Sayfalar 0'dan başladığı için -1 yapıyoruz.
    }
  }, [filteredDatas.length, rowsPerPage, page]);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const theme = getTheme(lightMode); // lightMode değerine göre temayı alın

  const navigate = useNavigate();

  useEffect(() => {
    if (pageType !== "bookings") {
      // Eğer pageType "bookings" değilse, herhangi bir işlem yapma
      setFilteredDatass(filteredDatas);
      return;
    }

    const uniqueInvoices = rawData.reduce((acc, current) => {
      const x = acc.find((item) => item.invoice_id === current.invoice_id);
      if (!x) {
        return acc.concat([current]);
      } else {
        x.details.check_in += `, ${current.details.check_in}`;
        x.details.check_out += `, ${current.details.check_out}`;
        return acc;
      }
    }, []);
    setFilteredDatass(uniqueInvoices);
  }, [rawData]); // filteredDatas değiştiğinde bu useEffect tetiklenir.

  // useEffect(() => {
  //   console.log(filteredDatass[15]?.details?.check_out, "fil datas");
  // }, [filteredDatas]);

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const themeTable = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            border: `1px solid ${lightMode ? "#e0e0e0" : "#515151"}`,
            borderRadius: "1rem",
            overflow: "hidden",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            background: "unset",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            background: lightMode ? "#ffffff !important" : "#1e1e1e !important",
          },

          root: {
            height: "70px",
            "&:nth-child(odd)": {
              background: lightMode ? "#f5f5f5" : "#303030",
            },

            "&:last-child .MuiTableCell-root": {
              borderBottom: "unset",
            },
            "&.MuiTableRow-hover:hover": {
              backgroundColor: lightMode ? "#efefef" : "#232323 !important",
            },
          },
          hover: {
            "&:hover": {
              backgroundColor: "red !important",
            },
          },
        },
      },
    },
  });
  return (
    <>
      {!loading ? (
        <Box sx={{ width: "100%", marginTop: { xs: "1.5rem", md: "3rem" } }}>
          {!filteredDatas?.length ? (
            <NoDataFound />
          ) : (
            <Box
              sx={{
                overflow: "hidden",
                padding: { xs: "0", md: "2rem" },
                borderRadius: "1rem",
                boxShadow: `0 0 5px ${lightMode ? "#00000050" : "#000000"}`,
              }}
            >
              <ThemeProvider theme={themeTable}>
                <TableContainer
                  sx={{
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                      height: "0.4em",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "5rem",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {tableHeader?.map((column, idx) => (
                          <TableCell
                            key={idx}
                            align={"center"}
                            sx={{
                              minWidth: { xs: "130px", md: "fit-content" },
                              fontSize: { xs: "14px", md: "14px" },
                              padding: ".5rem",
                            }}
                          >
                            {column.id.includes("phone") ? (
                              <LocalPhoneIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("school") ? (
                              <SchoolIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("commission_type") ? (
                              <CalendarTodayIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("commission_paid_status") ? (
                              <HelpIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("date") ||
                              column.id.includes("created") ||
                              column.id.includes("selected") ||
                              column.id.includes("accepted_at") ||
                              column.id.includes("check") ? (
                              <CalendarMonthIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("name") ? (
                              <BadgeIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("id") ? (
                              <KeyIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("price") ? (
                              <CreditCardIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("amount") ? (
                              <CreditCardIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("rate") ? (
                              <RateReviewIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("occupancy") ? (
                              <LockIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("status") ? (
                              <HelpIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("country") ? (
                              <FlagIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("language") ? (
                              <LanguageIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("duration") ? (
                              <TimelapseIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : column.id.includes("overdue") ? (
                              <HourglassBottomIcon
                                sx={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "28px",
                                  marginRight: { xs: "auto", xl: ".5rem" },
                                  marginLeft: { xs: "auto", xl: "0" },
                                  marginBottom: { xs: ".5rem", xl: "0" },
                                  display: { xs: "block", xl: "inline-block" },
                                }}
                              />
                            ) : (
                              ``
                            )}{" "}
                            {column.id === "duration"
                              ? "Duration / Week"
                              : column.label}
                          </TableCell>
                        ))}
                        {/* {pageType === "partner_requests" && !isMobileSm ? (
                          <TableCell
                            align={"center"}
                            sx={{
                              minWidth: { xs: "25%", md: "fit-content" },
                              padding: ".5rem",
                            }}
                          >
                            <FeedIcon
                              sx={{
                                position: "relative",
                                top: "5px",
                                fontSize: "28px",
                                marginRight: { xs: "auto", xl: ".5rem" },
                                marginLeft: { xs: "auto", xl: "0" },
                                marginBottom: { xs: ".5rem", xl: "0" },
                                display: { xs: "block", xl: "inline-block" },
                              }}
                            />
                            Details
                          </TableCell>
                        ) : pageType === "quotes" && !isMobileSm ? (
                          <TableCell
                            align={"center"}
                            sx={{
                              minWidth: { xs: "25%", md: "fit-content" },
                              padding: ".5rem",
                            }}
                          >
                            <FeedIcon
                              sx={{
                                position: "relative",
                                top: "5px",
                                fontSize: "28px",
                                marginRight: { xs: "auto", xl: ".5rem" },
                                marginLeft: { xs: "auto", xl: "0" },
                                marginBottom: { xs: ".5rem", xl: "0" },
                                display: { xs: "block", xl: "inline-block" },
                              }}
                            />
                            Details
                          </TableCell>
                        ) : null} */}
                        {isMobileSm || isMobileMd ? (
                          <TableCell
                            align={"center"}
                            sx={{
                              minWidth: { xs: "25%", md: "fit-content" },
                              padding: ".5rem",
                            }}
                          ></TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredDatass?.length && tableHeader?.length
                        ? filteredDatass
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow hover tabIndex={-1} key={row.code}>
                                  {tableHeader?.map((column) => {
                                    let value = row[column.id];
                                    if (
                                      typeof value === "boolean" &&
                                      column.id !== "commission_paid_status"
                                    ) {
                                      value = value ? "Yes" : "No";
                                    } else if (column?.id === "agent_name") {
                                      if (row?.created_by_agent_name) {
                                        value = row?.created_by_agent_name;
                                      } else {
                                        // value = "AGENT";
                                        value = "";
                                      }
                                    }
                                    return (
                                      <TableCell
                                        sx={{
                                          padding: ".6rem",
                                          fontWeight:
                                            column.id === "status" ||
                                            column.id ===
                                              "commission_paid_status"
                                              ? "700"
                                              : "400",
                                          color:
                                            value === "fullypaid" ||
                                            (column.id ===
                                              "commission_paid_status" &&
                                              value)
                                              ? "green"
                                              : value === "partiallypaid"
                                              ? "orange"
                                              : value === "unpaid" ||
                                                (column.id ===
                                                  "commission_paid_status" &&
                                                  !value)
                                              ? "#bf0000"
                                              : value === "overpaid"
                                              ? "blue"
                                              : value === "depositpaid"
                                              ? "orange"
                                              : lightMode
                                              ? "black"
                                              : "#ffffff",
                                        }}
                                        key={column.id}
                                        align={"center"}
                                      >
                                        {column.id === "check_out" ||
                                        column.id === "check_in"
                                          ? row?.details[column.id]
                                              .split(", ") // String'i tarih dizisine dönüştür
                                              .reduce(
                                                (acc, date, index, array) => {
                                                  acc.push(date); // Tarihi accumulator'a ekle
                                                  if (
                                                    index + 1 !==
                                                    array.length
                                                  ) {
                                                    // Son tarih değilse
                                                    acc.push(", "); // Tarihler arasına virgül ve boşluk ekle
                                                    if ((index + 1) % 2 === 0) {
                                                      acc.push(
                                                        <br key={index} />
                                                      ); // Her iki tarih arasına <br /> (alt satır) ekle
                                                    }
                                                  }
                                                  return acc;
                                                },
                                                []
                                              )
                                          : column?.format &&
                                            typeof value === "number"
                                          ? column?.format(value)
                                          : column.id === "check_in"
                                          ? row?.details?.check_in
                                          : column.id === "commission_type"
                                          ? row?.commission_type
                                              ?.split("_")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")
                                          : column.id === "quote_status"
                                          ? row?.quote_status
                                              ?.split("_")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")
                                          : column.id === "student_name"
                                          ? row?.student_name
                                              ?.split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1).toLowerCase()
                                              )
                                              .join(" ")
                                          : column.id === "student_school"
                                          ? row?.student_school
                                              ?.split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1).toLowerCase()
                                              )
                                              .join(" ")
                                          : column.id ===
                                              "commission_paid_status" && value
                                          ? "Paid"
                                          : column.id ===
                                              "commission_paid_status" && !value
                                          ? "Unpaid"
                                          : column.id === "check_out"
                                          ? row?.details?.check_out
                                          : column.id === "gross_price" ||
                                            column.id === "commission_amount" ||
                                            column.id === "invoice_course_price"
                                          ? paraFormat(value)
                                          : value === "fullypaid"
                                          ? "Fully Paid"
                                          : value === "partiallypaid"
                                          ? "Partially Paid"
                                          : value === "depositpaid"
                                          ? "Deposit Paid"
                                          : value === "overpaid"
                                          ? "Over Paid"
                                          : value === "unpaid"
                                          ? "Unpaid"
                                          : value}
                                      </TableCell>
                                    );
                                  })}
                                  {pageType === "quotes" && !isMobileSm ? (
                                    <TableCell
                                      sx={{ padding: ".6rem" }}
                                      align="center"
                                    >
                                      <Button
                                        onClick={() => {
                                          navigate(
                                            `/quotes/${row?.quote_id}/details`,
                                            {
                                              state: {
                                                quote: row,
                                              },
                                            }
                                          );
                                        }}
                                        variant="contained"
                                        sx={{
                                          textTransform: "none",
                                          padding: ".25rem",
                                          borderRadius: "1rem",
                                          backgroundColor: "#bf0000 !important",
                                          color: "#ffffff",
                                        }} // Düğmenin metninin büyük harf olmasını engelliyoruz
                                      >
                                        Details
                                      </Button>
                                    </TableCell>
                                  ) : pageType === "partner_requests" &&
                                    !isMobileSm ? (
                                    <TableCell
                                      sx={{ padding: ".6rem" }}
                                      align="center"
                                    >
                                      <Button
                                        onClick={() => {
                                          navigate(
                                            `/myrequests/${row?.id}/details`,
                                            {
                                              state: {
                                                request: row,
                                              },
                                            }
                                          );
                                        }}
                                        variant="contained"
                                        sx={{
                                          textTransform: "none",
                                          borderRadius: "1rem",
                                          padding: ".25rem",
                                          backgroundColor: "#bf0000 !important",
                                          color: "#ffffff",
                                        }} // Düğmenin metninin büyük harf olmasını engelliyoruz
                                      >
                                        Details
                                      </Button>
                                    </TableCell>
                                  ) : null}
                                  {isMobileSm || isMobileMd ? (
                                    <TableCell
                                      sx={{ padding: ".6rem" }}
                                      align="center"
                                    >
                                      <Button
                                        onClick={() => {
                                          handleOpenModal(row);
                                        }}
                                        variant="contained"
                                        sx={{
                                          textTransform: "none",
                                          borderRadius: "1rem",
                                          padding: ".25rem",
                                          backgroundColor: "#bf0000 !important",
                                        }} // Düğmenin metninin büyük harf olmasını engelliyoruz
                                      >
                                        Details
                                      </Button>
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              );
                            })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ThemeProvider>
            </Box>
          )}
          {filteredDatass?.length && tableHeader.length ? (
            <Pagination
              count={Math.ceil(filteredDatass?.length / rowsPerPage)}
              page={page + 1} // MUI'nin Pagination bileşeni 1 tabanlıdır, bu nedenle +1 eklenir.
              onChange={(event, newPage) =>
                handleChangePage(event, newPage - 1)
              } // Sayfa değişikliğini işleyin, -1 eklemeyi unutmayın!
              variant="outlined"
              shape="rounded"
              sx={{ mt: 10, display: "flex", justifyContent: "center" }} // Üstten biraz boşluk eklemek için
            />
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            maxWidth: "fit-content",
            position: "relative",
            left: "calc(50% - 20px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
