import FilterPagesGeneral from "../../components/FilterPagesGeneral";

export default function MyBookings() {
  const filterMethods = ["booking_type", "range_types", "status", "duration"];
  const buttons = ["export_report", "instant_booking"];
  return (
    <FilterPagesGeneral
      pageName="bookings"
      filterMethods={filterMethods}
      buttonTypes={buttons}
    />
  );
}
