import { Box, Button, CircularProgress, Typography } from "@mui/material";
import UploadButton from "./UploadButton";
import { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function DocumentsRequiredModal({ data }) {
  const [file, setFile] = useState(); // Dosya ismini saklamak için state
  const [count, setCount] = useState(5); // Dosya ismini saklamak için state
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirecting) {
      const interval = setInterval(() => {
        if (count >= 1) {
          setCount(count - 1);
        }
      }, 1000); //Clearing the interval
      return () => clearInterval(interval);
    }
  }, [redirecting, count]);
  useEffect(() => {
    if (count === 0) {
      dispatch(closeModal());
      navigate("/myinvoices");
    }
  }, [count]);

  const handleUploadFile = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoader(true);
    try {
      let url;

      url = `${process.env.REACT_APP_BASE_URL}auth/upload_file`;
      const formData = new FormData();
      formData.append("student_id", data?.detail?.student_id);
      formData.append("file", file); // 'file' burada bir File objesi olmalı
      formData.append("file_note", "");
      formData.append("doc_type", "copy_of_passport");
      // Axios ile GET isteği yapılıyor
      const response = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token
          XRefreshToken: `${token}`, // XRefreshToken
        },
      });
      if (response.data.success) {
        setLoader(false);
        setRedirecting(true);
      }
    } catch (error) {
      // Hata durumunda hata mesajını konsola yazdır
      setLoader(false);
      setError(error.response.data.message);
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      {redirecting ? (
        <Typography variant="h6">
          File uploaded successfully! You are redirecting in{" "}
          <strong>{count}</strong> seconds.
        </Typography>
      ) : error ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <ErrorIcon sx={{ fontSize: "72px", color: "#bf0000" }} />
            </Box>{" "}
            <Typography variant="h6">
              <strong>{error}</strong>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#bf0000",
                cursor: "pointer",
              }}
              onClick={() => setError()}
            >
              <ArrowBackIosIcon
                sx={{ lineHeight: "16px", color: "#bf0000", fontSize: "16px" }}
              />
              <Typography>Back</Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <Box sx={{ opacity: loader ? ".4" : "1" }}>
            <Typography sx={{ borderBottom: "1px solid #525252" }} variant="h6">
              {data.message}
            </Typography>
            <UploadButton file={file} setFile={setFile} />
            <Button
              disabled={!file}
              onClick={handleUploadFile}
              fullWidth
              sx={{ display: "block" }}
              variant="contained"
            >
              Upload
            </Button>
          </Box>
          {loader ? (
            <Box>
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "calc(50% - 20px)",
                  left: "calc(50% - 20px)",
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
}
