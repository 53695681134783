import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import { getTheme } from "../../styles/theme";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneCountryInput from "../../components/PhoneCountryInput";
import { openModal } from "../../redux/actions/actions";
import { useDispatch } from "react-redux";
import PaymentSuccessModal from "../../components/Modal/PaymentSuccessModal/PaymentSuccessModal";
import axiosInstance from "../../axiosInstance";
import Swal from "sweetalert2";

export default function StartRequest() {
  const initialState = {
    // student_firstname: "John",
    // student_lastname: "Doe",
    // student_email: "john.doe@example.com",
    // phone: "+905342700189",
    // student_school_start_date: "2000-09-01",
    // student_school_end_date: "2004-06-15",
    // student_school: "Example School",
    // student_gender: "male",
    // form_message: "",
    // custom_agent_email: "agent@example.com",
    // form_source: "Web Form",
    // native_language_id: 1,
    // nationality_id: 1,
    // agent_id: 1,
    student_firstname: "",
    student_lastname: "",
    student_email: "",
    phone: "",
    student_school_start_date: "",
    student_school_end_date: "",
    student_school: "",
    student_gender: "",
    form_message: "",
    custom_agent_email: "agent@example.com",
    form_source: "Web Form",
    native_language_id: 1,
    nationality_id: 1,
    agent_id: 4,
  };

  const [formValues, setFormValues] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const { startRequest: rawStartRequest } = location.state || {};
  const startRequest = Array.isArray(rawStartRequest)
    ? rawStartRequest
    : [rawStartRequest].filter(Boolean);

  const dispatch = useDispatch();
  const handleOpenModal = (status, message, type) => {
    dispatch(
      openModal(
        <PaymentSuccessModal status={status} message={message} type={type} />,
        "startRequest"
      )
    );
  };

  // useEffect(() => {
  //   console.log(startRequest, "start request data at start request page");
  // }, [startRequest]);

  const [formErrors, setFormErrors] = useState({});

  // useEffect(() => {
  //   // Eğer "email" için bir hata mesajı varsa, 5 saniye sonra sıfırla
  //   if (formErrors.email) {
  //     const timer = setTimeout(() => {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         email: "", // Hata mesajını sıfırla
  //       }));
  //     }, 5000); // 5000 milisaniye = 5 saniye

  //     // Komponent unmount olduğunda zamanlayıcıyı temizle
  //     return () => clearTimeout(timer);
  //   }
  // }, [formErrors.email]); // formErrors.email değiştiğinde useEffect tetiklenir

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues.student_email)) {
      errors.email = "Email is not valid";
    }

    if (!formValues?.phoneIsValid) {
      errors.phone = "Phone is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // const lettersRegex = /^[A-Za-z]+$/;
    const lettersRegex = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/;

    if (name === "student_firstname" || name === "student_lastname") {
      // firstname veya lastname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (formValues.phone && formValues.student_email) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        console.log("Form errors:", errors);
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  const handleDateChange = (name, value) => {
    let newDate = value ? dayjs(value) : null;

    if (name === "student_school_start_date") {
      if (
        newDate &&
        formValues.student_school_end_date &&
        newDate.isAfter(dayjs(formValues.student_school_end_date))
      ) {
        // Eğer başlangıç tarihi bitiş tarihinden sonra ise, başlangıç tarihini bitiş tarihine ayarla
        newDate = dayjs(formValues.student_school_end_date);
      }
    } else if (name === "student_school_end_date") {
      if (
        newDate &&
        formValues.student_school_start_date &&
        newDate.isBefore(dayjs(formValues.student_school_start_date))
      ) {
        // Eğer bitiş tarihi başlangıç tarihinden önce ise, bitiş tarihini başlangıç tarihine ayarla
        newDate = dayjs(formValues.student_school_start_date);
      }
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: newDate ? newDate.format("YYYY-MM-DD") : "",
    }));
  };

  const handleSubmit = async () => {
    // const errors = validateForm();
    // setFormErrors(errors);
    // // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
    // if (Object.keys(errors).length > 0) {
    //   console.log("Form errors:", errors);
    //   // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
    //   // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
    //   return; // Hatalar varsa, burada fonksiyonu sonlandır
    // }

    let url = `${process.env.REACT_APP_BASE_URL}partner_requests`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    const startRequestArray = Array.isArray(startRequest)
      ? startRequest
      : [startRequest];

    let campusNames;
    let courseNames;
    // campus_name ve course_name değerlerini array içinde gezinerek alıyoruz.
    if (startRequestArray[0]?.rooms) {
      campusNames = startRequestArray[0]?.rooms
        .map((item) => item.campus_name)
        .join(", ");

      courseNames = startRequestArray[0]?.rooms
        .map((item) => item.course_name)
        .join(", ");
    } else {
      campusNames = startRequest[0]?.campus_name;

      courseNames = startRequest[0]?.course_name;
    }

    const appendForm = {
      ...formValues,
      campus_name: campusNames,
      course_name: courseNames,
      currency:
        startRequest[0]?.currency ||
        startRequestArray[0]?.formDetails?.currency,
      numberOfStudents:
        startRequest[0]?.numberOfStudents ||
        startRequestArray[0]?.formDetails?.numberOfStudents,
      occupancy:
        startRequest[0]?.occupancy ||
        startRequestArray[0]?.formDetails?.occupancy,
      additionalNote:
        startRequest[0]?.additionalNote ||
        startRequestArray[0]?.formDetails?.additionalNote,

      predicted_checkin:
        startRequest[0]?.predicted_checkin ||
        startRequestArray[0]?.formDetails?.predicted_checkin,

      predicted_checkout:
        startRequest[0]?.predicted_checkout ||
        startRequestArray[0]?.formDetails?.predicted_checkout,
    };
    setLoader(true);
    try {
      // Axios ile POST isteği yapılıyor
      const response = await axiosInstance.post(url, appendForm, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setLoader(false);
      // handleOpenModal(
      //   response.data.success,
      //   response.data.message,
      //   "notPayment"
      // );
      Swal.fire({
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        showCloseButton: true,
      }).then((result) => {
        // Modal kapandığında burası çalışır
        // Örneğin kullanıcıyı ana sayfaya yönlendir
        navigate("/myrequests");
      });
    } catch (error) {
      // Hata durumunda hata mesajını göster
      setLoader(false);
      console.error("Error during the request:", error.message);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!startRequest.length) {
      navigate("/room-finder");
    }
  }, [startRequest]);
  if (!startRequest.length) {
    return null;
  }
  return (
    <>
      <Typography className="title" variant="h4">
        Start Request
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["My Requests", " Start Request"]} />
      <Container
        maxWidth={false}
        sx={{
          position: "relative",
          width: { xs: "100%", md: "70vw", lg: "54vw" },
          padding: "0 !important",
        }}
      >
        <Box
          sx={{
            opacity: loader ? ".4" : "1",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "27px",
              fontWeight: "700",
              marginTop: "3rem",
              textAlign: "center",
            }}
            color={getTheme().palette.secondary.main}
          >
            Place Your Request
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: ".5rem" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.student_firstname}
                onChange={handleChange}
                name="student_firstname"
                id="outlined-basic"
                label="Student First Name"
                variant="outlined"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.student_lastname}
                onChange={handleChange}
                name="student_lastname"
                id="outlined-basic"
                label="Student Last Name"
                variant="outlined"
                type="text"
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
              sx={{
                width: "100%",
              }}
              value={formValues.phone}
              onChange={handleChange}
              name="phone"
              id="outlined-basic"
              label="Student Phone"
              variant="outlined"
              type="text"
            /> */}
              <PhoneCountryInput
                formValues={formValues}
                setFormValues={setFormValues}
                error={!!formErrors.phone}
                helperText={formErrors.phone || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.student_email}
                onChange={handleChange}
                name="student_email"
                id="outlined-basic"
                label="E-Mail"
                variant="outlined"
                type="email"
                required
                error={!!formErrors.email}
                helperText={formErrors.email || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: "100%",
                  }}
                  label={"Student School Start Date"}
                  value={dayjs(formValues.student_school_start_date)}
                  onChange={(newValue) =>
                    handleDateChange("student_school_start_date", newValue)
                  }
                  maxDate={dayjs(formValues?.student_school_end_date) || null}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: "100%",
                  }}
                  label={"Student School End Date"}
                  value={dayjs(formValues.student_school_end_date)}
                  onChange={(newValue) =>
                    handleDateChange("student_school_end_date", newValue)
                  }
                  minDate={dayjs(formValues?.student_school_start_date) || null}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.student_school}
                onChange={handleChange}
                name="student_school"
                id="outlined-basic"
                label="Destination University"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  required
                  sx={{ maxWidth: "calc(100% - 40px)" }}
                  id="student_gender"
                >
                  Student Gender
                </InputLabel>
                <Select
                  name="student_gender"
                  labelId="student_gender"
                  id="student_gender"
                  value={formValues.student_gender}
                  label="Student Gender"
                  onChange={handleChange}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  width: "100%",
                }}
                value={formValues.form_message}
                onChange={handleChange}
                name="form_message"
                id="form_message"
                label="Your Message"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={
                  !formValues?.phone ||
                  !formValues?.student_email ||
                  formErrors?.phone ||
                  formErrors?.email ||
                  !formValues?.student_firstname ||
                  !formValues?.student_lastname ||
                  !formValues?.student_gender
                }
                onClick={() =>
                  formValues.phone && formValues.student_email && handleSubmit()
                }
                variant="contained"
                color="primary"
                sx={{
                  height: "100%",
                  display: "block",
                  minHeight: "56px",
                  width: "100%",
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loader ? (
          <CircularProgress
            sx={{ position: "absolute", top: "calc(50%)", left: "calc(50%)" }}
          />
        ) : null}
      </Container>
    </>
  );
}
