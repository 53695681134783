import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { hideDialog } from "../../redux/actions/dialogActions";
import { useNavigate } from "react-router-dom";

const DialogComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { open, message, severity, timer, pageType } = useSelector(
    (state) => state.dialog
  );

  const [countdown, setCountdown] = useState(); // 5 saniyelik bir geri sayım
  useEffect(() => {
    if (timer) {
      setCountdown(timer);
    }
  }, [timer]);
  useEffect(() => {
    let timerId;
    if (countdown) {

      if (open && countdown > 1) {
        // Dialog açıkken ve geri sayım 0'dan büyükken her saniye geri sayımı azalt
        timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
      } else {
        // Geri sayım 0 olduğunda dialog'u kapat ve yönlendirme yap
        dispatch(hideDialog());
        navigate("/");
        // Burada yönlendirme işlemi yapılacak, ancak bu component'te `navigate` hook'u kullanılamaz
        // Bu nedenle, yönlendirme işlemini başlatan action veya bir üst component üzerinden yönlendirme yapılmalı
      }
    }

    return () => clearTimeout(timerId); // Component unmount edildiğinde timer'ı temizle
  }, [open, countdown, pageType, dispatch]);

  const handleClose = () => {
    dispatch(hideDialog());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Dialog-dialog-title"
      aria-describedby="Dialog-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="Dialog-dialog-description">
          {message}
        </DialogContentText>
        <DialogContentText id="Dialog-dialog-description">
          You are redirecting to {pageType} in -{" "}
          <Typography sx={{ fontWeight: "700", display: 'inline' }}>{countdown}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DialogComp;
