export const tableItemsSm = [
  {
    id: "user-id",
    name: "User ID",
  },
  {
    id: "user-name",
    name: "User Name",
  },
];

export const tableItemsMd = [
  {
    id: "user-id",
    name: "User ID",
  },
  {
    id: "user-name",
    name: "User Name",
  },
  {
    id: "email",
    name: "Email",
  },
];

export const tableItemsLg = [
  {
    id: "user-id",
    name: "User ID",
  },
  {
    id: "user-name",
    name: "User Name",
  },
  {
    id: "email",
    name: "Email",
  },
  // {
  //   id: "agent-id",
  //   name: "Agent ID",
  // },
  {
    id: "branch-name",
    name: "Branch Name",
  },
];
