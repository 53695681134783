import { Box, Button, Typography } from "@mui/material";
import CartItems from "../CartHeader/CartItems";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/actions";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useState } from "react";

export default function ShoppingCartMobileModal({ navigate, location }) {
  const dispatch = useDispatch();
  const [selectedCartType, setSelectedCartType] = useState("products");

  const cart = useSelector((state) =>
    selectedCartType === "products"
      ? state.cart.products?.cart
      : state.cart.services?.cart
  );

  const cartProducts = useSelector((state) => state.cart.products?.cart);
  const cartServices = useSelector((state) => state.cart.services?.cart);

  const mobileDevice = useSelector((state) => state.windowWidth.width);
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handlerMobile = () => {
    if (mobileDevice < 768) {
      handleClose();
    } else {
    }
  };
  return (
    <Box
      sx={{
        height: "calc(100% - 49px)",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#e5e5e5",
            filter: "drop-shadow(5px 5px 15px #dedede)",
            overflow: "auto",
            paddingBottom: "20px",
            borderRadius: "6px",
            width: "100%",
            height: "calc(100% - 36px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "1rem",
            }}
          >
            <Box
              onClick={() => setSelectedCartType("services")}
              sx={{
                width: "50%",
                textAlign: "center",
                padding: ".5rem",
                cursor: "pointer",
                boxShadow:
                  selectedCartType === "services"
                    ? "rgb(1 1 1 / 10%) 0px 2px 5px 2px"
                    : "unset",
              }}
            >
              <Typography variant="h6">
                Services ({cartServices?.length})
              </Typography>
            </Box>
            <Box
              onClick={() => setSelectedCartType("products")}
              sx={{
                width: "50%",
                textAlign: "center",
                padding: ".5rem",
                cursor: "pointer",
                boxShadow:
                  selectedCartType === "products"
                    ? "rgb(1 1 1 / 10%) 0px 2px 5px 2px"
                    : "unset",
              }}
            >
              <Typography variant="h6">
                Products ({cartProducts?.length})
              </Typography>
            </Box>
          </Box>
          {selectedCartType === "products" ? (
            cartProducts.length ? (
              cartProducts?.map((item, idx) => {
                return (
                  <CartItems
                    item={item}
                    idx={idx}
                    serviceType={
                      selectedCartType === "products" ? "products" : "services"
                    }
                  />
                );
              })
            ) : (
              <Box
                sx={{
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "1rem",
                    position: "relative",
                  }}
                >
                  <ShoppingBasketIcon
                    sx={{
                      fontSize: "72px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  Basket is Empty!
                </Typography>
              </Box>
            )
          ) : cartServices.length ? (
            cartServices?.map((item, idx) => {
              return (
                <CartItems
                  item={item}
                  idx={idx}
                  serviceType={
                    selectedCartType === "products" ? "products" : "services"
                  }
                />
              );
            })
          ) : (
            <Box
              sx={{
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  marginBottom: "1rem",
                  position: "relative",
                }}
              >
                <ShoppingBasketIcon
                  sx={{
                    fontSize: "72px",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "24px",
                }}
              >
                Basket is Empty!
              </Typography>
            </Box>
          )}
        </Box>

        {selectedCartType === "products" ? (
          cartProducts.length ? (
            location.pathname === "/payment/shop" ? (
              <Button
                fullWidth
                sx={{
                  marginTop: "auto",
                  position: "fixed",
                  bottom: "16px",
                  left: "16px",
                  width: "calc(100% - 32px)",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/shop");
                  handlerMobile();
                }}
              >
                Continue to Shopping
              </Button>
            ) : (
              <Button
                fullWidth
                sx={{
                  marginTop: "auto",
                  position: "fixed",
                  bottom: "16px",
                  left: "16px",
                  width: "calc(100% - 32px)",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("payment/shop");

                  handlerMobile();
                }}
              >
                Continue to Payment
              </Button>
            )
          ) : (
            <Button
              fullWidth
              sx={{
                marginTop: "auto",
                position: "fixed",
                bottom: "16px",
                left: "16px",
                width: "calc(100% - 32px)",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/shop");

                handlerMobile();
              }}
            >
              Continue to Shopping
            </Button>
          )
        ) : cartServices.length ? (
          location.pathname === "/payment/services" ? (
            <Button
              fullWidth
              sx={{
                marginTop: "auto",
                position: "fixed",
                bottom: "16px",
                left: "16px",
                width: "calc(100% - 32px)",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/additional-services/cleaning-services");
                handlerMobile();
              }}
            >
              Continue to Shopping
            </Button>
          ) : (
            <Button
              fullWidth
              sx={{
                marginTop: "auto",
                position: "fixed",
                bottom: "16px",
                left: "16px",
                width: "calc(100% - 32px)",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("payment/services");

                handlerMobile();
              }}
            >
              Continue to Payment
            </Button>
          )
        ) : (
          <Button
            fullWidth
            sx={{
              marginTop: "auto",
              position: "fixed",
              bottom: "16px",
              left: "16px",
              width: "calc(100% - 32px)",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("additional-services/cleaning-services");

              handlerMobile();
            }}
          >
            Continue to Shopping
          </Button>
        )}

        {/* {cart.length ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              marginTop: "auto",
              position: "fixed",
              bottom: "16px",
              left: "16px",
              width: "calc(100% - 32px)",
            }}
            onClick={() => {
              if (selectedCartType === "products") {
                if (
                  location.pathname === "/payment/shop" ||
                  location.pathname === "/payment/services"
                ) {
                  navigate("shop");
                } else {
                  navigate("payment/shop");
                }
              } else if (selectedCartType === "services") {
                if (
                  location.pathname === "/payment/services" ||
                  location.pathname === "/payment/shop"
                ) {
                  navigate("additional-services/cleaning-services");
                } else {
                  navigate("payment/services");
                }
              }

              handlerMobile();
            }}
          >
            {location.pathname === "/payment/shop" ||
            location.pathname === "/payment/services"
              ? `Continue to Shopping`
              : `Continue to Payment`}
          </Button>
        ) : (
          <Button
            fullWidth
            sx={{
              marginTop: "auto",
              position: "fixed",
              bottom: "16px",
              left: "16px",
              width: "calc(100% - 32px)",
            }}
            onClick={() => (navigate("shop"), handlerMobile())}
            variant="contained"
            color="primary"
          >
            Continue to Shopping
          </Button>
        )} */}
      </Box>
    </Box>
  );
}
