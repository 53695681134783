import { Box, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import BreadCrumbs from "../../components/Breadcrumbs";
import TicketsSingleItems from "../../components/Tickets/TicketsSingle/TicketsSingleItems";

const TicketsSingle = () => {
  const { id } = useParams();
  return (
    <>
      <Typography className="title" variant="h4">
        Tickets
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Tickets", "London Attractions"]} />

      {/* <Box>{id}</Box> */}
      <TicketsSingleItems />
    </>
  );
};

export default TicketsSingle;
