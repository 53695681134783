import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QuotesAccordion from "./QuotesAccordion";
import { useSelector } from "react-redux";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function RequestDetailsComp({
  request,
  loader,
  requestDetails,
}) {
  const [formValues, setFormValues] = useState(requestDetails?.request_details);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const handleChange = ({ target: { name, value, type } }) => {
    let isText = ["tax_office", "agent_name", "tax_name"].includes(name);
    let updatedValue = value;

    // For text fields, ensure that the input does not contain numbers
    if (type === "text" && isText) {
      // This regex checks for the presence of any digit
      if (/\d/.test(value)) return; // If there's a digit, don't update the value
    } else if (name === "predicted_checkin" || name === "predicted_checkout") {
      // Date fields require special formatting
      updatedValue = dayjs(value).format("YYYY-MM-DD");
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  // useEffect(() => {
  //   console.log(formValues, "form values");
  // }, [formValues]);

  // const accommodations = [
  //   {
  //     provider_name: "Chapter",
  //     room_type: "Bronze Studio Mid Level",
  //     provider_address: " East London, London, UK",
  //     provider_img:
  //       "https://crm.londonist.co.uk/uploads/1/course/25/o_1dqhdpng17ppb7mkng1d3e1ceu53.jpeg",
  //     campus_name: "Chapter Lewisham Residence",
  //     type: "Studio",
  //     checkIn: "2024-03-08",
  //     duration: "0.43 day/s",
  //     weekly_price: "465.00 GBP/Week",
  //     total_price: "5,194.27 GBP",
  //   },
  //   {
  //     provider_name: "Chapter",
  //     room_type: "Bronze Studio Mid Level",
  //     provider_address: " East London, London, UK",
  //     provider_img:
  //       "https://crm.londonist.co.uk/uploads/1/course/25/o_1dqhdpng17ppb7mkng1d3e1ceu53.jpeg",
  //     campus_name: "Chapter Lewisham Residence",
  //     type: "Studio",
  //     checkIn: "2024-03-08",
  //     duration: "0.43 day/s",
  //     weekly_price: "465.00 GBP/Week",
  //     total_price: "5,194.27 GBP",
  //   },
  //   {
  //     provider_name: "Chapter",
  //     room_type: "Bronze Studio Mid Level",
  //     provider_address: " East London, London, UK",
  //     provider_img:
  //       "https://crm.londonist.co.uk/uploads/1/course/25/o_1dqhdpng17ppb7mkng1d3e1ceu53.jpeg",
  //     campus_name: "Chapter Lewisham Residence",
  //     type: "Studio",
  //     checkIn: "2024-03-08",
  //     duration: "0.43 day/s",
  //     weekly_price: "465.00 GBP/Week",
  //     total_price: "5,194.27 GBP",
  //   },
  // ];

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  return (
    <>
      <Box sx={{ marginTop: { xs: "2rem" } }}>
        <Box>
          <Box
            sx={{
              marginBottom: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>
              Request ID: #{request?.id}
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "1rem",
              boxShadow: "0px 0px 5px #cecece",
              padding: "1.5rem 1rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.student_id}
                  id="outlined-basic"
                  label="Student ID"
                  name="student_id"
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.student_name}
                  id="outlined-basic"
                  label="Student Name"
                  name="student_name"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.student_email}
                  id="outlined-basic"
                  label="Student Email"
                  name="student_email"
                  variant="outlined"
                  type="email"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.student_gender}
                  id="outlined-basic"
                  label="Gender"
                  name="student_gender"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.student_phone}
                  id="outlined-basic"
                  label="Phone"
                  name="student_phone"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Check In Date"
                    value={dayjs(formValues?.predicted_checkin) || "03-27-2024"}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Check Out Date"
                    value={
                      dayjs(formValues?.predicted_checkout) || "09-27-2024"
                    }
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.partner || "Chapter"}
                  id="outlined-basic"
                  label="Provider"
                  name="provider"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={
                    formValues?.campus_name || "Chapter Lewisham Residence"
                  }
                  id="outlined-basic"
                  label="Campus"
                  name="campus"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.course_name || "Bronze Studio Mid Level"}
                  id="outlined-basic"
                  label="Room Type"
                  name="room_type"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.duration || 1}
                  id="outlined-basic"
                  label="Duration"
                  name="duration"
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.academic_year || ""}
                  id="outlined-basic"
                  label="Academic Year"
                  name="academic-year"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Student School Start Date"
                    value={dayjs(formValues?.school_start_date) || "03-27-2024"}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Student School End Date"
                    value={dayjs(formValues?.school_end_date) || "03-27-2028"}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={formValues?.form_message || "Test Message"}
                  id="outlined-basic"
                  label="Your Message"
                  name="form_message"
                  variant="outlined"
                  type="text"
                  multiline
                  rows={1}
                />
              </Grid>
            </Grid>
          </Box>
          <>
            {requestDetails?.rooms_detail?.length ? (
              <>
                <Box
                  sx={{
                    marginTop: "3rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    Accommodations Requests
                  </Typography>
                </Box>
                {/* {accommodations?.map((accommodation, idx) => {
              return (
                <Box
                  sx={{
                    boxShadow: "0px 0px 5px #cecece",
                    padding: "1.5rem 1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        width: { xs: "100%", md: "300px", lg: "400px" },
                        marginRight: { xs: "0", md: "2rem" },
                        marginBottom: { xs: "1.5rem", md: "0" },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${accommodation?.provider_img})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left",
                          width: { xs: "100%", md: "400px" },
                          height: { xs: "300px", md: "100%" },
                          filter: "blur(8px)",
                        }}
                      ></Box>
                      <img
                        src={accommodation?.provider_img}
                        style={{
                          position: "absolute",
                          inset: "0",
                          width: "90%",
                          left: "5%",
                          borderRadius: "1rem",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          md: "calc(100% - 332px)",
                          lg: "calc(100% - 432px)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid #efefef",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Typography color="primary" variant="h6">
                          {accommodation?.room_type}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2">
                            {accommodation?.provider_name}
                          </Typography>
                          <Typography
                            sx={{ marginLeft: "1rem", marginRight: "1rem" }}
                            variant="body2"
                          >
                            {accommodation?.campus_name}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <LocationOnIcon
                              sx={{ fontSize: "14px", color: "#bf0000" }}
                            />
                            <Typography variant="body2">
                              {accommodation?.provider_address}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color="primary"
                            variant="body2"
                            sx={{ marginRight: ".5rem" }}
                          >
                            Type:
                          </Typography>
                          <Typography variant="body2">
                            {accommodation?.type}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color="primary"
                            variant="body2"
                            sx={{ marginRight: ".5rem" }}
                          >
                            Check/in:
                          </Typography>
                          <Typography variant="body2">
                            {accommodation?.checkIn}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color="primary"
                            variant="body2"
                            sx={{ marginRight: ".5rem" }}
                          >
                            Duration:
                          </Typography>
                          <Typography variant="body2">
                            {accommodation?.duration}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color="primary"
                            variant="body2"
                            sx={{ marginRight: ".5rem" }}
                          >
                            Weekly:
                          </Typography>
                          <Typography variant="body2">
                            {accommodation?.weekly_price}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h6" color="primary">
                          Amount:
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {accommodation?.total_price}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })} */}

                {requestDetails?.rooms_detail?.map((roomDetail) => {
                  return (
                    <Box
                      sx={{
                        background: lightMode
                          ? roomDetail?.promotion_name
                            ? "#0080001c"
                            : "#f7f7f7"
                          : roomDetail?.promotion_name
                          ? "#0080001c"
                          : "#292929",
                        marginTop: "1.5rem",
                        boxShadow: "0px 0px 5px #cecece",
                        padding: "1.5rem 1rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            overflow: "hidden",
                            width: { xs: "100%", md: "300px", lg: "400px" },
                            marginRight: { xs: "0", md: "2rem" },
                            marginBottom: { xs: "1.5rem", md: "0" },
                          }}
                        >
                          <Box
                            sx={{
                              backgroundImage: roomDetail?.images
                                ? `url(${roomDetail?.images[0]})`
                                : ``,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "left",
                              width: { xs: "100%", md: "400px" },
                              height: { xs: "300px", md: "100%" },
                              filter: "blur(8px)",
                            }}
                          ></Box>
                          <img
                            src={
                              roomDetail?.images ? roomDetail?.images[0] : ""
                            }
                            style={{
                              position: "absolute",
                              inset: "0",
                              width: "90%",
                              left: "5%",
                              borderRadius: "1rem",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: {
                              xs: "100%",
                              md: "calc(100% - 332px)",
                              lg: "calc(100% - 432px)",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              borderBottom: "1px solid #efefef",
                              paddingBottom: "1rem",
                            }}
                          >
                            <Typography color="primary" variant="h6">
                              {roomDetail?.course_name}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="body2">
                                {roomDetail?.partner}
                              </Typography>
                              <Typography
                                sx={{ marginLeft: "1rem", marginRight: "1rem" }}
                                variant="body2"
                              >
                                {roomDetail?.campus_name}
                              </Typography>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LocationOnIcon
                                  sx={{ fontSize: "14px", color: "#bf0000" }}
                                />
                                <Typography variant="body2">
                                  {roomDetail?.address}
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex" }}>
                              <Typography
                                color="primary"
                                variant="body2"
                                sx={{ marginRight: ".5rem" }}
                              >
                                Type:
                              </Typography>
                              <Typography variant="body2">
                                {roomDetail?.type}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                color="primary"
                                variant="body2"
                                sx={{ marginRight: ".5rem" }}
                              >
                                Check/in:
                              </Typography>
                              <Typography variant="body2">
                                {roomDetail?.predicted_checkin}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                color="primary"
                                variant="body2"
                                sx={{ marginRight: ".5rem" }}
                              >
                                Check/out:
                              </Typography>
                              <Typography variant="body2">
                                {roomDetail?.predicted_checkout}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                color="primary"
                                variant="body2"
                                sx={{ marginRight: ".5rem" }}
                              >
                                Duration:
                              </Typography>
                              <Typography variant="body2">
                                {roomDetail?.duration}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                color="primary"
                                variant="body2"
                                sx={{ marginRight: ".5rem" }}
                              >
                                Weekly:
                              </Typography>
                              <Typography variant="body2">
                                {paraFormat(roomDetail?.weekly)} / Week
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1rem",
                            }}
                          >
                            <Typography variant="h6" color="primary">
                              Amount:
                            </Typography>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              {roomDetail?.promotion_amount ? (
                                <Typography
                                  sx={{
                                    marginLeft: "auto",
                                    marginTop: "auto",
                                    width: "max-content",
                                    color: "green",
                                  }}
                                  variant="h6"
                                >
                                  {roomDetail?.promotion_type === "fixed"
                                    ? `${(roomDetail?.discounted_total).toFixed(
                                        2
                                      )} GBP`
                                    : `${(roomDetail?.discounted_total).toFixed(
                                        2
                                      )} GBP`}
                                  {/* {((roomDetail?.duration / 7) * roomDetail?.discounted_weekly).toFixed(2)} GBP */}
                                </Typography>
                              ) : null}

                              <Typography
                                sx={{
                                  marginLeft: "auto",
                                  marginTop: "auto",
                                  width: "max-content",
                                  textDecoration: roomDetail?.promotion_amount
                                    ? "line-through"
                                    : "none",
                                }}
                                variant="h6"
                              >
                                {roomDetail?.promotion_type === "fixed"
                                  ? `${(roomDetail?.total).toFixed(2)} GBP`
                                  : `${(roomDetail?.total).toFixed(2)} GBP`}

                                {/* {((roomDetail?.duration / 7) * roomDetail?.weekly).toFixed(2)} GBP */}
                              </Typography>
                              {roomDetail?.promotion_name ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "auto",
                                    width: {
                                      xs: "fit-content",
                                      xl: "max-content",
                                    },
                                    background: "#008b0087",
                                    padding: ".25rem .75rem",
                                    borderRadius: "2rem",
                                  }}
                                >
                                  <LocalOfferIcon
                                    sx={{
                                      position: "relative",
                                      top: "3px",
                                      color: "#ffffff",
                                      marginRight: ".25rem",
                                      fontSize: "16px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: "#ffffff",
                                    }}
                                    variant="body1"
                                  >
                                    {roomDetail?.promotion_type === "fixed"
                                      ? `${roomDetail?.promotion_name} (${roomDetail?.promotion_amount} GBP Discount)`
                                      : `${roomDetail?.promotion_name} (%${roomDetail?.promotion_amount} Discount)`}

                                    {/* {data?.promotion_name} (%{data?.promotion_amount} Discount) */}
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>

                            {/* <Typography variant="h6" color="primary">
                              {paraFormat(roomDetail?.total)}
                            </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box
                sx={{
                  marginTop: "2rem",
                  boxShadow: "0px 0px 5px #cecece",
                  padding: "1rem 1rem",
                }}
              >
                <Typography variant="body1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: requestDetails?.room_details_text,
                    }}
                  />
                </Typography>
              </Box>
            )}
            {requestDetails?.quotes?.length ? (
              <>
                <Box
                  sx={{
                    marginTop: "3rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    Quotes
                  </Typography>
                </Box>
                <Box>
                  <QuotesAccordion quoteDetails={requestDetails?.quotes} />
                </Box>
              </>
            ) : null}
          </>
        </Box>
      </Box>
    </>
  );
}
