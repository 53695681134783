import { Box, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import TicketItems from "../../components/Tickets/TicketItems";

export default function Tickets() {
  return (
    <>
      <Typography className="title" variant="h4">
        Tickets
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Tickets"]} />

      <Box>
        <TicketItems />
      </Box>
    </>
  );
}
