import { useParams } from "react-router-dom";

function FramePage() {
  const { url } = useParams();
  const decodedUrl = decodeURIComponent(url);

  console.log(url, "url here");
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src={decodedUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="Ticket Frame"
      ></iframe>
    </div>
  );
}

export default FramePage;
