// GeneralModal.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setStudent } from "../../redux/actions/actions";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export default function GeneralModalTwo({ modalType }) {
  const dispatch = useDispatch();
  const filteredDatas = useSelector((state) => state.filters.filteredListItems); // Filtrelenmiş ürünleri al
  const { isOpen, modalContent, contentType } = useSelector(
    (state) => state.modal
  );

  const navigate = useNavigate();

  const handleClose = () => {
    if (contentType === "documentsRequired") {
      dispatch(closeModal());
      navigate("/myinvoices");
    } else if (contentType === "success") {
      if (filteredDatas.length) {
        dispatch(closeModal());
        navigate("/myinvoices");
      }
    } else if (contentType === "failed") {
      dispatch(closeModal());
      navigate("/myinvoices");
    } else if (contentType === "startRequest") {
      dispatch(closeModal());
      navigate("/myrequests");
    } else if (contentType === "updateProfile") {
      dispatch(closeModal());
      navigate("/profile");
    } else if (contentType === "selectStudent") {
      dispatch(closeModal());
      dispatch(setStudent({}));
    } else {
      dispatch(closeModal());
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    width: "100%",
    padding: "16px",
    overflow: "auto",
    border: "1px solid #cecece",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, .3)",
    borderRadius: "1rem",
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!isOpen) return null;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ zIndex: "999999" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          maxWidth: isMobile
            ? "90%"
            : modalType == "addToCart"
            ? "1400px"
            : modalType == "cancelRequest"
            ? "550px"
            : "400px",
          maxHeight: isMobile ? "90%" : "90%",
          height: isMobile ? "100%" : "auto",
          padding: isMobile
            ? modalType === "cancelRequest"
              ? "1rem"
              : "1rem"
            : modalType == "addToCart"
            ? "0"
            : modalType == "cancelRequest"
            ? "3rem"
            : "1rem",
          position: "relative",
          borderRadius: "1rem",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            textAlign: "right",
            top: 0,
            right: 0,
            cursor: "pointer",
            zIndex: 9,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingBottom: ".5rem",
            marginBottom: "1rem",
            borderBottom: "1px solid #919191",
          }}
        >
          <CloseIcon sx={{ color: "#919191" }} onClick={handleClose} />
        </Box>
        {modalContent}
      </Box>
    </Modal>
  );
}
