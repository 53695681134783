import { CircularProgress, Grid } from "@mui/material";
import CarList from "./CarList/CarList";
import SideBar from "./SideBar";
import axiosInstance from "../../../../../axiosInstance";
import { useContext, useEffect, useState } from "react";
import AirportServicesContext from "../../../../../contexts/AirportServices/AirportServices";

export default function StepSecond() {
  const [loading, setLoading] = useState(true);
  const { bookingDetails, setBookingDetails, carListItems, setCarListItems } =
    useContext(AirportServicesContext);

  const getCarList = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}airport_cars?service_id=${bookingDetails.service_id}`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      setCarListItems(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };
  useEffect(() => {
    getCarList();
  }, []);
  return (
    <Grid container spacing={3} sx={{ marginTop: "2rem" }}>
      <Grid item xs={12} md={8}>
        {loading ? (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 64px)",
            }}
          />
        ) : (
          <CarList />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <SideBar />
      </Grid>
    </Grid>
  );
}
