// const initialState = {
//   branches: [],
//   branchesString: '',
// };

const initialState = {
  branches: localStorage.getItem("branches")
    ? JSON.parse(localStorage.getItem("branches"))
    : [],
  branchesString: localStorage.getItem("branches-string")
    ? JSON.parse(localStorage.getItem("branches-string"))
    : "",
  agency: localStorage.getItem("agency")
    ? JSON.parse(localStorage.getItem("agency"))
    : [],
};

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BRANCHES":
      return {
        ...state,
        branches: action.payload.branches,
      };

    case "SET_AGENCY":
      return {
        ...state,
        agency: action.payload.agency,
      };

    case "SET_BRANCHES_STRING":
      return {
        ...state,
        branchesString: action.payload.branches,
      };

    default:
      return state;
  }
};

export default branchesReducer;
