// AirportServicesContext.js

import React, { createContext, useState } from "react";

const UsersListContext = createContext();

export const UsersListProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  // Context'e sağlanacak değerler
  const contextValue = {
    users,
    setUsers,
  };

  return (
    <UsersListContext.Provider value={contextValue}>
      {children}
    </UsersListContext.Provider>
  );
};

export default UsersListContext;
