import { Box, Container, Typography } from "@mui/material";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import BreadCrumbs from "../../components/Breadcrumbs";
import SettingsComponent from "../../components/Settings/Settings";

export default function Settings() {
  return (
    <>
      <Typography className="title" variant="h4">
        Settings
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Settings"]} />

      <Box sx={{ marginTop: "2rem" }}>
        <SettingsComponent />
      </Box>
    </>
  );
}
