import { Box, Typography } from "@mui/material";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import BreadCrumbs from "../../components/Breadcrumbs";
import AddUserComponent from "../../components/Settings/AddUser";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import UsersListContext, {
  UsersListProvider,
} from "../../contexts/UsersList/UsersList";

export default function AddUser() {
  return (
    <UsersListProvider>
      <Typography className="title" variant="h4">
        Add User
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Add User"]} />

      <Box sx={{ marginTop: "2rem" }}>
        <AddUserComponent />
      </Box>
    </UsersListProvider>
  );
}
