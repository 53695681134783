import { CLEAR_BRANCHES, SET_AGENCY, SET_BRANCHES, SET_BRANCHES_STRING } from "./actionTypes";

export const setBranchesArray = (branches) => ({
  type: SET_BRANCHES,
  payload: { branches },
});

export const setAgencyArray = (agency) => ({
  type: SET_AGENCY,
  payload: { agency },
});

export const setSelectedBranchesString = (branches) => ({
    type: SET_BRANCHES_STRING,
    payload: { branches },
  });

export const clearBranches = (branchType) => ({
  type: CLEAR_BRANCHES,
  payload: branchType,
});
