// src/redux/reducers/tokenReducer.js

import { REMOVE_TOKENS, SET_TOKENS } from "../actions/actionTypes";

const initialState = {
  accessToken: null,
  accessToken: localStorage.getItem("accessToken") || null, // localStorage boşsa null kullan
  refreshToken: localStorage.getItem("loginUser") || null, // localStorage boşsa null kullan
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case REMOVE_TOKENS:
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};

export default tokenReducer;
