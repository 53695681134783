import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/Breadcrumbs";
import CommonForm from "./CommonForm";
import Rooms from "../../components/RoomFinder/RoomFinderSearch/Rooms";
import RequestOperations from "../../components/RoomFinder/RequestOperations/RequestOperations";
import FilterSearchArea from "../../components/MyRequests/FilterSearchArea";
import InstantBookingComponent from "../../components/RoomFinder/InstantBookingButton";
import NoDataFound from "../../components/NoDataFound";
import { setPageType } from "../../redux/actions/actions";
import { useDispatch } from "react-redux";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { useRoomFinder } from "../../contexts/RoomFinderContext";

export default function RoomFinder() {
  const initialState = {
    provider: "",
    campus: "",
    occupancy: "",
    course: "",
    currency: "",
    numberOfStudents: "",
    additionalNote: "",
    checkIn: null,
    checkOut: null,
    refreshControl: true,
  };

  const [formValues, setFormValues] = React.useState(initialState);
  // const [availableRooms, setAvailableRooms] = React.useState(null);
  // const [selectedRooms, setSelectedRooms] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [loaderStart, setLoaderStart] = React.useState(false);
  const [bookingData, setBookingData] = React.useState({});
  const [showNoData, setShowNoData] = React.useState(false); // NoDataFound'u 1 saniye sonra göstermek için yeni state
  const [roomControl, setRoomControl] = React.useState(true);

  const {
    selectedRooms,
    setSelectedRooms,
    availableRooms,
    setAvailableRooms,
    allRooms,
    setAllRooms,
  } = useRoomFinder(); // Context'ten state ve setState fonksiyonlarını alıyoruz

  const availableRoomsRef = React.useRef(null); // Ref'i oluştur

  console.log(selectedRooms, "selected rooms");
  // React.useEffect(() => {
  //   console.log(bookingData, "booking data");
  // }, [bookingData]);

  React.useEffect(() => {
    console.log(formValues, "form values here 6161");
  }, [formValues]);

  const handleChange = (event) => {
    const { name, value, key } = event.target;
    setFormValues((prev) => ({
      ...prev,
      refreshControl: false,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageType("room_finder"));
  }, []);

  React.useEffect(() => {
    if (availableRoomsRef.current) {
      const yOffset = -100; // 100px yukarıdan boşluk bırakmak için
      const y =
        availableRoomsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [availableRooms]);

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   console.log(selectedRooms, "selected rooms");
  // }, [selectedRooms]);
  //   const handleFindRooms = () => {
  //     //if (checkIn && checkOut) {
  //     const checkInDate = checkIn.format("YYYY-MM-DD");
  //     const checkOutDate = checkOut.format("YYYY-MM-DD");
  //     navigate(
  //       `/room-finder-results?checkin=${checkInDate}&checkout=${checkOutDate}`
  //     );
  //     //}
  //   };

  const [allAvailableRooms, setAllAvailableRooms] = React.useState([]);
  const [allTemporaryAvailableRooms, setAllTemporaryAvailableRooms] =
    React.useState([]);
  const [orderType, setOrderType] = React.useState("low-to-high");

  React.useEffect(() => {
    console.log("deneme 1");
    console.log(availableRooms, "available rooms deneme 1");
    console.log(allRooms, "all rooms deneme 1");
    if (availableRooms?.length && !allRooms?.length) {
      console.log("deneme 2");
      Ordering("low-to-high");
    }
  }, [availableRooms, allRooms]);

  function Ordering(order_type) {
    let allRoomsTemp = [];
    console.log(availableRooms, "MY AVAL");
    const fixedRooms = availableRooms?.filter(
      (room) =>
        room.course_promotion_discount_amount &&
        room.course_promotion_discount_type === "fixed"
    );

    const percentageRooms = availableRooms?.filter(
      (room) =>
        room.course_promotion_discount_amount &&
        room.course_promotion_discount_type === "percentage"
    );

    const nonPromotionRooms = availableRooms?.filter(
      (room) => !room.course_promotion_discount_amount
    );

    if (fixedRooms?.length) {
      fixedRooms.forEach((room) => {
        allRoomsTemp.push({
          ...room,
          ordered_price:
            ((room?.duration / 7) * room?.price).toFixed(2) -
            room?.course_promotion_discount_amount,
        });
      });
    }

    if (percentageRooms?.length) {
      percentageRooms.forEach((room) => {
        allRoomsTemp.push({
          ...room,
          ordered_price: (
            (room?.duration / 7) * room?.price -
            (room?.duration / 7) *
              room?.price *
              (room?.course_promotion_discount_amount / 100)
          ).toFixed(2),
        });
      });
    }

    if (nonPromotionRooms?.length) {
      nonPromotionRooms.forEach((room) => {
        allRoomsTemp.push({
          ...room,
          ordered_price: ((room?.duration / 7) * room?.price).toFixed(2),
        });
      });
    }

    // allRoomsTemp array'ini price değerine göre yüksekten düşüğe sıralayın
    if (order_type === "high-to-low") {
      allRoomsTemp.sort((a, b) => b.ordered_price - a.ordered_price);
    } else {
      allRoomsTemp.sort((a, b) => a.ordered_price - b.ordered_price);
    }

    // allRooms array'ini state'e kaydedin
    setAllRooms(allRoomsTemp);
  }

  const handleOrderChange = (e) => {
    setOrderType(e.target.value);
  };

  React.useEffect(() => {
    Ordering(orderType);
  }, [orderType]);

  React.useEffect(() => {
    if (allRooms?.length) {
      setAvailableRooms(allRooms);
    }
  }, [allRooms]);

  console.log(availableRooms, "available rooms");
  return (
    <>
      {loaderStart ? (
        <CircularProgress
          sx={{
            position: "fixed",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
            zIndex: "999999",
          }}
        />
      ) : null}
      <Typography className="title" variant="h4">
        Room Finder
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Room Finder"]} />
      <CommonForm
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        availableRooms={availableRooms}
        setAvailableRooms={setAvailableRooms}
        availableRoomsRef={availableRoomsRef}
        setLoader={setLoader}
        initialState={initialState}
        setSelectedRooms={setSelectedRooms}
        setOrderType={setOrderType}
        setAllRooms={setAllRooms}
        roomControl={roomControl}
        setRoomControl={setRoomControl}
      />
      <Box
        ref={availableRoomsRef}
        sx={{ display: "flex", flexDirection: "column", paddingBottom: "6rem" }}
      >
        {!loader ? (
          availableRooms?.length ? (
            <>
              <FormControl sx={{ marginLeft: "auto" }}>
                <InputLabel id="demo-simple-select-label">
                  Order by Price
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderType}
                  label="Order by Price"
                  onChange={handleOrderChange}
                >
                  <MenuItem value={"high-to-low"}>High to Low</MenuItem>
                  <MenuItem value={"low-to-high"}>Low to High</MenuItem>
                </Select>
              </FormControl>
              {availableRooms?.map((availableRoom, idx) => {
                return (
                  <Rooms
                    key={idx}
                    data={availableRoom}
                    formValues={formValues}
                    selectedRooms={selectedRooms}
                    setSelectedRooms={setSelectedRooms}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                  />
                );
              })}
            </>
          ) : // Eğer 1 saniye geçtiyse NoDataFound'u göster
          !roomControl ? (
            <NoDataFound />
          ) : null
        ) : (
          <CircularProgress />
        )}
      </Box>
      {selectedRooms.length ? (
        <RequestOperations
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          bookingData={bookingData}
          setBookingData={setBookingData}
          setLoaderStart={setLoaderStart}
        />
      ) : null}
    </>
  );
}
