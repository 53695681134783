import { Box, Typography } from "@mui/material";

export default function AddUserTableModal({ data, columns }) {
  return (
    <>
      {columns?.map((column, idx) => {
        return (
          <Typography>
            <strong>{column.name}:</strong>{" "}
            {column?.id === "user-id"
              ? data?.user_id
              : column?.id === "user-name"
              ? data?.agent_name
              : column?.id === "email"
              ? data?.email
              : column?.id === "agent-id"
              ? data?.agent_id
              : column?.id === "branch-name"
              ? data?.branch_name
              : ""}
          </Typography>
        );
      })}
    </>
  );
}
