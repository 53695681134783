import { Box, CircularProgress, Typography } from "@mui/material";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import BreadCrumbs from "../../components/Breadcrumbs";
import AddBranchComponent from "../../components/Settings/AddBranch";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import axios from "axios";
import { BranchesListProvider } from "../../contexts/BranchesList/BranchesList";

export default function AddBranch() {
  return (
    <>
      <BranchesListProvider>
        <Typography className="title" variant="h4">
          Add Branch
          <StyledTitleDot />
        </Typography>
        <BreadCrumbs routs={["Add Branch"]} />

        <Box sx={{ marginTop: "2rem" }}>
          <AddBranchComponent />
        </Box>
      </BranchesListProvider>
    </>
  );
}
