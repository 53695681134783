// filtersReducer.js

import axios from "axios";
import {
  FETCH_LISTITEMS_SUCCESS,
  SET_SELECTEDFILTERS,
  FILTER_FUNC,
  SET_PAGE_TYPE,
} from "../actions/actionTypes";

const initialState = {
  filters: {},
  filteredListItems: [],
  listItems: [],
  selectedFilters: {},
  pageType: "",
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LISTITEMS_SUCCESS:
      // URL'den filtreler yüklenmediyse, filtreleme işlemini yap
      return {
        ...state,
        filteredListItems: action.payload,
      };
    case SET_SELECTEDFILTERS:
      // URL'den filtreler yüklenmediyse, filtreleme işlemini yap
      return {
        ...state,
        selectedFilters: action.payload,
      };

    case FILTER_FUNC:
      return {
        ...state,
        filteredListItems: action.payload,
      };
    case SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.payload,
      };
    default:
      return state;
  }
};

export default filtersReducer;
