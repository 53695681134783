export const LondonData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.4991362, 51.6086461],
            [-0.5006002, 51.5986621],
            [-0.4947232, 51.5846132],
            [-0.4896241, 51.5817701],
            [-0.4847837, 51.566869],
            [-0.4766169, 51.5580292],
            [-0.4954877, 51.5383328],
            [-0.4905022, 51.5341243],
            [-0.4923559, 51.5171171],
            [-0.4831942, 51.5066463],
            [-0.4886839, 51.495984],
            [-0.498649, 51.48926],
            [-0.5067438, 51.4711839],
            [-0.494558, 51.4636329],
            [-0.4776896, 51.4614089],
            [-0.4588761, 51.4560385],
            [-0.4564884, 51.4381071],
            [-0.440023, 51.4306234],
            [-0.4191, 51.4323591],
            [-0.405383, 51.4224796],
            [-0.3866632, 51.4200805],
            [-0.389671, 51.4106934],
            [-0.3781187, 51.4078886],
            [-0.3600114, 51.4120393],
            [-0.3405298, 51.4030172],
            [-0.3278401, 51.3918381],
            [-0.3177202, 51.393668],
            [-0.310869, 51.3861876],
            [-0.3086951, 51.3754497],
            [-0.3175055, 51.3719705],
            [-0.3182854, 51.3635063],
            [-0.3305339, 51.3484212],
            [-0.3307565, 51.3288556],
            [-0.3193073, 51.3278115],
            [-0.3061319, 51.3350493],
            [-0.3039032, 51.3432527],
            [-0.2928199, 51.3525276],
            [-0.2854616, 51.3642508],
            [-0.2608406, 51.3795557],
            [-0.2450543, 51.3800352],
            [-0.2454293, 51.3668468],
            [-0.2268978, 51.362595],
            [-0.2172895, 51.3433883],
            [-0.2298477, 51.336524],
            [-0.2209677, 51.3298644],
            [-0.1976776, 51.3434865],
            [-0.1631021, 51.3302661],
            [-0.1619055, 51.3196278],
            [-0.1543415, 51.31032],
            [-0.155344, 51.3012765],
            [-0.1373404, 51.3007828],
            [-0.1243196, 51.2867601],
            [-0.0943519, 51.2993554],
            [-0.0848293, 51.3158683],
            [-0.0710433, 51.3212338],
            [-0.0641538, 51.3186264],
            [-0.0478548, 51.3265105],
            [-0.0502875, 51.3326401],
            [-0.0379184, 51.3387052],
            [-0.0251301, 51.3386089],
            [-0.0133483, 51.3315364],
            [0.002266, 51.3291383],
            [0.0149821, 51.2917873],
            [0.0328814, 51.3075214],
            [0.0457041, 51.2940054],
            [0.0584828, 51.289355],
            [0.0856654, 51.2930854],
            [0.0894569, 51.297741],
            [0.082788, 51.3056342],
            [0.0850007, 51.3160233],
            [0.1047428, 51.3273412],
            [0.1202182, 51.3321368],
            [0.1166538, 51.3413023],
            [0.1362526, 51.34555],
            [0.1425843, 51.3507312],
            [0.1450217, 51.3628506],
            [0.1532098, 51.3780363],
            [0.1498093, 51.3908725],
            [0.1598909, 51.3946484],
            [0.1529064, 51.408709],
            [0.1511328, 51.4204307],
            [0.1558489, 51.4308765],
            [0.1635211, 51.4288934],
            [0.172695, 51.4431714],
            [0.1841237, 51.4446429],
            [0.2033256, 51.4543278],
            [0.2105856, 51.4624809],
            [0.2088175, 51.4707626],
            [0.2163623, 51.4791303],
            [0.2103069, 51.485877],
            [0.2141281, 51.4960388],
            [0.2299369, 51.4993652],
            [0.226688, 51.5066115],
            [0.2418912, 51.5079594],
            [0.237148, 51.5193335],
            [0.2508241, 51.5288175],
            [0.2538063, 51.517885],
            [0.2636548, 51.5178689],
            [0.2653192, 51.5321491],
            [0.2977267, 51.5353173],
            [0.3006946, 51.5387547],
            [0.3311939, 51.5407614],
            [0.3251291, 51.5530566],
            [0.3151336, 51.5580845],
            [0.3130069, 51.5658163],
            [0.2902617, 51.5642976],
            [0.2824655, 51.578064],
            [0.270255, 51.5910952],
            [0.2698518, 51.5995725],
            [0.2576656, 51.61418],
            [0.2240601, 51.6317345],
            [0.2002839, 51.6249326],
            [0.1805847, 51.6257327],
            [0.1688737, 51.6214174],
            [0.1360791, 51.6235997],
            [0.1192801, 51.6153665],
            [0.0948086, 51.6107753],
            [0.0871183, 51.6044655],
            [0.0629721, 51.6069103],
            [0.0406886, 51.615878],
            [0.0217927, 51.6288314],
            [0.0257123, 51.634564],
            [0.0158065, 51.640433],
            [-0.0122861, 51.6462275],
            [-0.011092, 51.6808669],
            [-0.0663677, 51.6838429],
            [-0.079021, 51.6889118],
            [-0.1092827, 51.6917426],
            [-0.1376818, 51.688362],
            [-0.163632, 51.6823996],
            [-0.1724982, 51.673088],
            [-0.1909697, 51.6639824],
            [-0.2033777, 51.6701231],
            [-0.2078886, 51.6628579],
            [-0.2285136, 51.6599624],
            [-0.249881, 51.6546114],
            [-0.2573568, 51.6418287],
            [-0.2961511, 51.6354435],
            [-0.3166962, 51.6405318],
            [-0.3505027, 51.6265828],
            [-0.3775872, 51.6178035],
            [-0.4014409, 51.6131662],
            [-0.4381948, 51.6198924],
            [-0.4571519, 51.6122912],
            [-0.4972471, 51.631654],
            [-0.4954846, 51.6190669],
            [-0.4991362, 51.6086461],
          ],
        ],
      },
      properties: {
        "@id": "relation/175342",
        admin_level: "5",
        "alt_name:vls": "Grôot-Loendn;Grôot-Lonn;Grôot-Lond'n",
        boundary: "administrative",
        council_name: "Greater London Authority",
        name: "Greater London",
        "name:be": "Вялікі Лондан",
        "name:be-tarask": "Вялікі Лёндан",
        "name:de": "Groß-London",
        "name:en": "Greater London",
        "name:fr": "Grand Londres",
        "name:lt": "Didysis Londonas",
        "name:nl": "Groot-Londen",
        "name:pt": "Grande Londres",
        "name:ru": "Большой Лондон",
        "name:uk": "Великий Лондон",
        "name:ur": "لندن عظمیٰ",
        "name:vls": "Grôot-Londn",
        "name:zh-Hans": "大伦敦",
        "name:zh-Hant": "大倫敦",
        note: "This is the jurisdiction of the Greater London Authority",
        official_name: "Greater London",
        "ref:gss": "E61000001",
        "ref:nuts:1": "UKI",
        type: "boundary",
        wikidata: "Q23306",
        wikipedia: "en:Greater London",
      },
      id: "relation/175342",
    },
  ],
};
