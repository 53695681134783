import { Button } from "@mui/material";

export default function InstantBookingComponent() {
  return (
    <Button
      disabled
      href="/instant-booking"
      // href="javascript:;"
      sx={{
        marginLeft: "auto",
        width: { xs: "100%", md: "fit-content" },
        marginBottom: { xs: "2rem", md: "0" },
      }}
      variant="contained"
      color="success"
    >
      Instant Booking
    </Button>
  );
}
