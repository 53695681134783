import { Grid } from "@mui/material";
import CarSingleCart from "./CarSingleCart";
import AirportServicesContext from "../../../../../../contexts/AirportServices/AirportServices";
import { useContext } from "react";

export default function CarList() {
  const { carListItems, setCarListItems } = useContext(AirportServicesContext);
  return (
    <>
      {carListItems?.map((car, idx) => {
        return <CarSingleCart car={car} />;
      })}
    </>
  );
}
