import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const ResidenceDescription = ({
  data,
  openDescription,
  setOpenDescription,
}) => {
  const [style, setStyle] = useState(null);
  const handleCloseResidenceDescriptionModal = () => setOpenDescription(false);

  // const extractTextFromHtml = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // };

  // const extractTextFromHtml = (htmlString) => {
  //   return htmlString
  //     .replace(/<\/?strong>/g, "**") // <strong> etiketlerini ** ile değiştir
  //     .replace(/<\/?ul>/g, "\n") // <ul> etiketini yeni satır ile değiştir
  //     .replace(/<\/?li>/g, "- ") // <li> etiketini liste işareti ile değiştir
  //     .replace(/<\/?[^>]+(>|$)/g, ""); // Diğer tüm HTML etiketlerini kaldır
  // };

  const extractTextFromHtml = (description) => {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };

  const initialStyle = {
    overflow: "auto !important",
    position: "absolute",
    outline: "none",
    border: "none",
    top: "150%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    maxHeight: "90vh",
    height: "fit-content",
    maxWidth: "700px",
    borderRadius: "2rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    transition: "all .4s",
  };

  useEffect(() => {
    if (openDescription) {
      setStyle((prev) => ({
        ...prev,
        top: "50%",
      }));
    } else {
      setStyle(initialStyle);
    }
  }, [openDescription]);
  return (
    style && (
      <Modal
        open={openDescription}
        onClose={handleCloseResidenceDescriptionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleCloseResidenceDescriptionModal}
            sx={{
              fontSize: "36px",
              color: "#ffffff",
              filter: "drop-shadow(0 0 3px #800000)",
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer",
              transition: "all .4s",
              "&:hover": {
                color: "#efefef",
                transition: "all .4s",
              },
            }}
          />
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "700",
                borderBottom: "1px solid #efefef",
                paddingBottom: "1rem",
                marginBottom: "1rem",
              }}
            >
              About the Property
            </Typography>
            <Typography variant="body1">
              {extractTextFromHtml(data?.campus_description)}
            </Typography>
          </Box>
        </Box>
      </Modal>
    )
  );
};

export default ResidenceDescription;
