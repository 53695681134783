import { SET_STUDENT } from "../actions/actionTypes";

const initialState = {
  student: {
    // name: "Ahmet",
    // student_id: "123"
  },
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT:
      // URL'den filtreler yüklenmediyse, filtreleme işlemini yap
      return {
        ...state,
        student: action.payload,
      };
    default:
      return state;
  }
};

export default studentReducer;
