// windowWidthControlReducer.js

import { SET_WINDOW_WIDTH } from "../actions/actionTypes";

const initialState = {
  width: window.innerWidth
};

const windowWidthControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOW_WIDTH:
      return {
        ...state,
        width: action.payload
      };
    default:
      return state;
  }
};

export default windowWidthControlReducer;
